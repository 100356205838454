export const connector = (store) => (Element, map) =>
	class extends Element {
		connectedCallback() {
			const { selectors, actions } = map;

			if (selectors) {
				const update = () => Object.assign(this, selectors(store.getState()));
				this["_unsubscribe"] = store.subscribe(update);
				update();
			}

			if (actions) {
				const dispatchers = actions(store.dispatch);
				for (const type in dispatchers) {
					this["addEventListener"](type, (event) => {
						event.stopImmediatePropagation();
						dispatchers[type](event.detail);
					});
				}
			}

			if (super.connectedCallback) {
				super.connectedCallback();
			}
		}

		disconnectedCallback() {
			if (map.selectors) {
				this["_unsubscribe"]();
			}
			if (super.disconnectedCallback) {
				super.disconnectedCallback();
			}
		}
	};

export default connector;
