import * as Sentry from "@sentry/browser";
import { LitElement, html, css } from "lit";
import { get, post } from "../../api/client.js";
import { styles as sharedStyles } from "../../styles/shared.js";
import { styles as tableStyles } from "../../styles/tables.js";
import { styles as inputStyles } from "../../styles/input.js";
import { toast, sort } from "../../utils.js";
import { date } from "../../formatting/dateformats.js";
import { customElement, state } from "lit/decorators.js";
import type { ButtonElementV2 } from "../../components/fm-button-v2.js";
import type {
	Question,
	QuestionAnswer,
	QuestionEmptyAnswer,
} from "../../types/types.js";

declare global {
	interface HTMLElementTagNameMap {
		"customers-questions-report-view": CustomersQuestionsReportView;
	}
}

@customElement("customers-questions-report-view")
export default class CustomersQuestionsReportView extends LitElement {
	@state()
	questions: Question[] = [];

	@state()
	fromDate: string = date(new Date().addDays(-100));

	static styles = [
		sharedStyles,
		tableStyles,
		inputStyles,
		css`div.actions {
    display: flex;
    flex-grow: 0;
    flex-direction: row;
    margin-left: auto;
    align-items: flex-start;
  }

  div.card-header {
    align-items: flex-start;
  }

  fm-form {
    display: flex;
    align-items: flex-end;
    gap: 8px;
  }

  div.form-space {
    margin: 0px;
    min-width: initial;
  }

  div.form-space input[type="date"],
  div.form-space input[type="search"] {
    font-size: 12px;
    height: 28px;
  }`,
	];

	render() {
		return html`
      <div class="card">
        <div class="card-header">
          <div class="info">
            <h1>AML spørgsmål</h1>
          </div>
          <div class="actions">
            <fm-form
              id="parms"
              class="reportform no-print"
              method="get"
              @submit="${this.refresh}"
            >
              <div class="form-space">
                <label
                  >Spurgt fra
                  <input
                    type="date"
                    name="fromdate"
                    class="date-input"
                    id="fromdate"
                    value="${this.fromDate}"
              @input="${(e: InputEvent) => {
								this.fromDate = (e.target as HTMLInputElement).value;
							}}" 
                  />
                </label>
              </div>
              <div class="form-space">
                <label>Søg</label>
                <input
                  type="search"
                  class="search-input"
                  name="query"
                  id="query"
                  placeholder="Søg"
                />
              </div>
              <div class="form-space">
                <label style="white-space: nowrap;">
                  <input
                    type="checkbox"
                    name="unanswered"
                    id="unanswered"
                    style="display: initial;"
                  />
                  Kun ubesvarede
                </label>
              </div>
              <div class="form-space">
                <fm-button-v2
                  id="submit_button"
                  class="button submit"
                  type="submit"
                  ?disabled="${!this.fromDate}"
                  >Vis</fm-button-v2
                >
              </div>
            </fm-form>
          </div>
        </div>
        <table>
          <thead>
            <tr>
              <th data-key="ask_date" @click="${this.sort}">Dato</th>
              <th data-key="user_name" @click="${this.sort}">Engagement</th>
              <th data-key="question" @click="${this.sort}">Spørgsmål</th>
              <th data-key="asked_by" @click="${this.sort}">
                Modtager/Besvarer
              </th>
            </tr>
          </thead>
          <tbody>
            ${this.questions.map(
							(q) => html`
                <tr>
                  <td>${q.ask_date}</td>
                  <td>${q.user_name}</td>
                  <td>${q.question}</td>
                  <td>${q.asked_by}</td>
                </tr>
                ${this.renderAnswers(q.ask_id, q.answers, q.empty_answer)}
              `,
						)}
          </tbody>
        </table>
      </div>
    `;
	}

	renderAnswers(
		askId: number,
		answers: QuestionAnswer[],
		emptyAnswer: QuestionEmptyAnswer[],
	) {
		if (answers) {
			return html`${(answers || []).map((a) => this.renderAnswer(a))}`;
		}

		return html` <tr>
        <td></td>
        <td></td>
        <td>
          <fm-form
            url="/questions/${askId}"
            method="POST"
            @submit="${this.saveAnswer}"
          >
            <div style="display:table;width:100%;">
              <div style="display:table-cell;margin:auto;">
                ${(emptyAnswer || []).map((a) => this.renderEmptyAnswer(a))}
              </div>
              <div style="display:flex;flex-direction: column;justify-content: center;">
                <fm-button-v2 type="submit" style="max-width:100px;margin-left:auto;" class="button">Gem svaret</fm-button-v2>
              </div>
            </div>
          </fm-form>
        </td>
        <td></td>
      </tr>`;
	}

	renderEmptyAnswer(a: QuestionEmptyAnswer) {
		return html`
       <input type="radio" name="answer_id" value="${a.answer_id}">${
					a.answer
				} ${this.renderEmptyOther(a)}<br>
     </tr>
     `;
	}

	renderEmptyOther(a: QuestionEmptyAnswer) {
		if (a.other_yn === "Y") {
			return html`<input type="text" name="answer_other" />`;
		}

		return "";
	}

	renderAnswer(a: QuestionAnswer) {
		return html`
      <tr>
        <td>${a.answer_date}</td>
        <td></td>
        <td>${a.answer} ${a.answer_other}</td>
        <td>${a.answered_by}</td>
      </tr>
    `;
	}

	async search() {
		const query = (this.shadowRoot?.getElementById("query") as HTMLInputElement)
			.value;

		const unanswered = (
			this.shadowRoot?.getElementById("unanswered") as HTMLInputElement
		).checked;

		try {
			const questions = await get<{ data: Question[] }>(
				`/questions?from=${this.fromDate}&query=${query}&unanswered=${unanswered}`,
			);

			if (!questions.ok) {
				const error = await questions.error.json();

				if ("message" in error) {
					toast(error.message);
				} else {
					toast("Der er sket en fejl.");
				}
				return;
			}

			this.questions = questions.value.data;
		} catch (err) {
			Sentry.captureException(err);
			console.error(err);

			if (err instanceof Response) {
				try {
					const response = await err.json();
					toast(response.message || "Der er sket en fejl.");
				} catch (_) {}
			}
		}
	}

	sort(event: Event) {
		const key = (event.target as HTMLTableCellElement).getAttribute("data-key");

		if (!key) {
			return;
		}
		this.questions = sort(this.questions, key) as Question[];
	}

	async refresh() {
		const button = this.shadowRoot?.getElementById(
			"submit_button",
		) as ButtonElementV2;
		button.loading = true;
		await this.search();
		button.loading = false;
	}

	async saveAnswer(event: Event) {
		const target = event.target as HTMLFormElement;

		if (!target) {
			return;
		}

		const formvalue: { answer_id: string; answer_other?: string } =
			target["value"];
		const url: string = target["url"];
		event.preventDefault();
		if (formvalue.answer_id) {
			try {
				const save = await post<
					{ answer_id: string; answer_other?: string },
					{ data: { id: number } }
				>(url, formvalue);

				if (!save.ok) {
					const error = await save.error.json();

					if ("message" in error) {
						toast(error.message);
					} else {
						toast("Der er sket en fejl.");
					}
					return;
				}

				toast("Besvarelse gemt.");
				await this.search();
			} catch (err) {
				Sentry.captureException(err);
				toast("Der er sket en fejl.");
			}
		} else {
			toast("Vælg en svarmulighed.");
		}
	}
}
