import { LitElement, html, css, type PropertyValues } from "lit";
import { state, property, customElement } from "lit/decorators.js";

import { styles as sharedStyles } from "../../../styles/shared.js";
import { styles as tableStyles } from "../../../styles/tables.js";
import { styles as inputStyles } from "../../../styles/input.js";

import { money } from "../../../formatting/numberformats.js";
import "../../custodies/create-custody-view.js";
import type { Client, CustodiesCustody } from "../../../types/types.js";
import { get } from "../../../api/client.js";
import { classMap } from "lit/directives/class-map.js";

declare global {
	interface HTMLElementTagNameMap {
		"person-custodies-view": typeof PersonCustodiesView;
	}
}

@customElement("person-custodies-view")
export default class PersonCustodiesView extends LitElement {
	@property({ type: Number })
	accessor clientId: number | undefined;

	@property({ type: Number })
	accessor userId: number | undefined;

	@state()
	custodies: CustodiesCustody[] = [];

	@state()
	totalValue: number = 0;

	@state()
	openCreateCustodyDialog: boolean = false;

	@state()
	showDisabledCustodies: boolean = false;

	toggleShowDisabledCustodies() {
		this.showDisabledCustodies = !this.showDisabledCustodies;
	}

	visibleCustodies() {
		return this.custodies.filter(
			(custody) =>
				this.showDisabledCustodies || custody.custody_active_yn === "Y",
		);
	}

	static styles = [
		sharedStyles,
		tableStyles,
		inputStyles,
		css`#parms {
		display: flex;
		flex-direction: row;
		align-items: flex-end;
	  }

	  div.show-disabled-custodies
	  {
		display: flex;
		flex-direction: row;
		gap: 4px;
		margin: 4px 0px;
		align-items: center;
	  }
	  
	  #custodies tr td.disabled {
		color: #f00;
	  }
	  `,
	];

	render() {
		return html`
      <div class="card">
        <div class="card-header">
		  <div>
            <h1>Investeringskonti</h1>
		    <div class="show-disabled-custodies">
        	  <input
                  type="checkbox"
                  id="show-disabled-custodies"
                  name="show-disabled-custodies"
                  .checked="${this.showDisabledCustodies}"
                  @click="${this.toggleShowDisabledCustodies}"
                />
                <label for="show-disabled-custodies">Vis deaktiverede investeringskonti</label>
            </div>
		  </div>
          <div class="actions">
            <fm-button-v2
              class="button-small"
              type="button"
              @click="${this.createClick}"
              >Opret ny</fm-button-v2
            >
          </div>
        </div>
        <table id="custodies">
          <thead class="sticky">
            <tr>
              <th>Kontonummer</th>
			  ${this.showDisabledCustodies ? html`<th>Aktiv</th>` : null}
              <th>Type</th>
              <th>Kontonavn</th>
              <th>Modelportefølje</th>
              <th>Oprettet</th>
              <th class="numeric">Værdi</th>
            </tr>
          </thead>
          <tbody>
            ${
							this.visibleCustodies()?.length === 0
								? html`<tr>
                  <td colspan="6">Ingen konti</td>
                </tr>`
								: html`${this.visibleCustodies().map(
										(custody, index) => html`
                      <tr class="${index === 0 ? "first" : ""}">
                        <td style="white-space: nowrap;">
                          <router-link
                            to="/customers/${custody.client_id}/persons/${
															custody.owner_id
														}/custodies/${custody.custody_id}"
                          >
                            <a>${custody.custody_code}</a>
							${custody.client_id === this.clientId ? html`` : html`<br />(andet engagement)`}
                          </router-link>
                        </td>
						 ${
								this.showDisabledCustodies
									? html`<td class="${classMap({
											disabled: custody.custody_active_yn !== "Y",
										})}">${custody.custody_active_yn === "Y" ? "Ja" : "Nej"}</td>`
									: null
							}
                        <td>${custody.custodytype_code}</td>
                        <td>${custody.name}</td>
                        <td>${custody.model_name} ${custody.model_status}</td>
                        <td style="white-space: nowrap;">${custody.reg_date ? custody.reg_date.replaceAll("-", ".") : ""}</td>
                        <td class="numeric">${money(custody.currentvalue)}</td>
                      </tr>
                    `,
									)}
                  <tr>
                    <td colspan="${this.showDisabledCustodies ? 6 : 5}"><strong>I alt</strong></td>
                    <td class="numeric">
                      <strong>${money(this.totalValue)}</strong>
                    </td>
                  </tr>`
						}
          </tbody>
        </table>
      </div>
      <fm-dialog
        .opened="${this.openCreateCustodyDialog}"
        @close="${() => {
					this.openCreateCustodyDialog = false;
				}}"
      >
        ${
					this.openCreateCustodyDialog
						? html`<create-custody-view
              .userId="${this.userId}"
              @created="${this.custodyCreated}"
            ></create-custody-view>`
						: null
				}
      </fm-dialog>
    `;
	}

	async updated(changedProperties: PropertyValues<this>) {
		if (changedProperties.has("userId")) {
			await this.loadCustodies();
		}
	}

	async loadCustodies() {
		this.totalValue = 0;

		const clientResponse = await get<Client>(`/clients/${this.clientId}`);

		if (!clientResponse.ok) {
			return;
		}

		const custodiesResponse = await get<{ data: CustodiesCustody[] }>(
			`/custodies?query=&user_id=${this.userId}&all=true`,
		);
		if (!custodiesResponse.ok) {
			return;
		}
		const custodies: CustodiesCustody[] = custodiesResponse.value.data;

		// const custodies = await Promise.all(custodyPromises);
		const userCustodies: CustodiesCustody[] = [];

		// TODO: Not optimal, but it works
		for (const custody of custodies) {
			if (
				this.userId === custody.owner_id ||
				this.userId === custody.owner2_id
			) {
				userCustodies.push(custody);
				this.totalValue += custody.currentvalue ? custody.currentvalue : 0;
			}
		}

		this.custodies = userCustodies;

		this.requestUpdate();
	}

	createClick() {
		this.openCreateCustodyDialog = true;
	}

	async custodyCreated() {
		this.openCreateCustodyDialog = false;

		await this.loadCustodies();
	}
}
