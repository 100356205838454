import { html, css } from "lit-element";
import { customElement, property, state } from "lit-element/decorators.js";
import { styles as sharedStyles } from "../../../styles/shared.js";
import { styles as tableStyles } from "../../../styles/tables.js";
import { styles as inputStyles } from "../../../styles/input.js";
import { LitElement } from "lit";
import { Custody, Redemption } from "../../../types/types.js";
import { toast } from "../../../utils.js";
import { get, post, put } from "../../../api/client.js";
import { DateFormatter, date } from "../../../formatting/dateformats.js";
import { live } from "lit/directives/live.js";

declare global {
	interface HTMLElementTagNameMap {
		"custody-redemptions-view": CustodyRedemptionsView;
	}
}

@customElement("custody-redemptions-view")
export default class CustodyRedemptionsView extends LitElement {
	@property({ type: Number })
	accessor custodyId: number | undefined;

	@property({ type: Boolean })
	accessor disabled: boolean = false;

	@state()
	accountId: number | undefined;

	@state()
	redemptions: Redemption[] = [];

	@state()
	loading: boolean = false;

	@state()
	editRedemptionId: number | null = null;

	@state()
	firstdate: string = "";

	@state()
	lastdate: string = "";

	@state()
	amount = "";

	@state()
	bank_reg = "";

	@state()
	bank_account = "";

	static styles = [
		sharedStyles,
		tableStyles,
		inputStyles,
		css`.form-space {
    float: initial;
    margin: initial;
  }

  table input[type="date"],
  table input[type="number"] {
    font-size: 14px;
  }

  table input[type="date"] {
    min-width: 125px;
    max-width: 125px;
  }

  table input[type="number"] {
    min-width: 70px;
    max-width: 70px;
  }`,
	];

	render() {
		return html`
      <div class="card">
        <div class="card-header">
          <h2>Nedsparingsplaner</h2>
        </div>
        <div class="card-block">
          ${
						this.loading
							? html`<p>Indlæser nedsparingsplaner...</p>`
							: html`
                <fm-form id="redemption-form">
                  <table>
                    <thead>
                      <tr>
                        <th>Fra</th>
                        <th>Til</th>
                        <th>Frekvens</th>
                        <th>Dag</th>
                        <th>Beløb</th>
                        <th>Regnr.</th>
                        <th>Kontonr.</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      ${this.renderRedemption()}
                      ${this.redemptions.map((r) =>
												r.id === this.editRedemptionId
													? this.renderRedemption(r)
													: html`<tr>
                              <td>${
																r?.firstdate
																	? DateFormatter.format(
																			new Date(r.firstdate),
																			"dd/mm/yyyy",
																	  )
																	: ""
															}</td>
                              <td>${
																r?.lastdate
																	? DateFormatter.format(
																			new Date(r.lastdate),
																			"dd/mm/yyyy",
																	  )
																	: ""
															}</td>
                              <td>
                                Hver
                                ${
																	r.frequency_months === 1
																		? ""
																		: `${r.frequency_months}.`
																}
                                måned
                              </td>
                              <td>
                                ${
																	r.frequency_days === -1
																		? "Salg sidste bankdag i måneden"
																		: r.frequency_days === -4
																		? "Udbetaling sidste bankdag i måneden"
																		: ""
																}
                              </td>
                              <td>
                                ${
																	r.method_id === 1
																		? `${r.amount} kr.`
																		: r.method_id === 2
																		? `${r.amount}%`
																		: r.amount
																}
                              </td>
                              <td>${r.bank_reg}</td>
                              <td>${r.bank_account}</td>
                              <td>
                                <fm-button-v2
                                  class="btn button-xsmall"
                                  type="button"
                                  redemption_id="${r.id}"
                                  @click="${this.editRedemptionClick}"
                                  ?disabled="${
																		this.disabled ||
																		this.editRedemptionId != null
																	}"
                                >
                                  Rediger
                                </fm-button-v2>
                              </td>
                            </tr>`,
											)}
                    </tbody>
                  </table>
                </fm-form>
              `
					}
        </div>
      </div>
    `;
	}

	renderRedemption(redemption?: Redemption) {
		if (redemption === undefined) {
			return html`
			<tr>
				<td>
					<div class="form-space"><input type="date" name="firstdate" .value="${live(
						this.firstdate,
					)}" id="firstdate" /></div>
				</td>
				<td>
					<div class="form-space"><input type="date" name="lastdate" .value="${live(
						this.lastdate,
					)}" id="lastdate" /></div>
				</td>
				<td>
					<select name="frequency_months" id="frequency_months">
						<option value="1">Hver måned</option>
						<option value="2">Hver 2. måned</option>
						<option value="3">Hver 3. måned</option>
						<option value="4">Hver 4. måned</option>
						<option value="5">Hver 5. måned</option>
						<option value="6">Hver 6. måned</option>
						<option value="7">Hver 7. måned</option>
						<option value="8">Hver 8. måned</option>
						<option value="9">Hver 9. måned</option>
						<option value="10">Hver 10. måned</option>
						<option value="11">Hver 11. måned</option>
						<option value="12">Hver 12. måned</option>
					</select>
				</td>
				<td>
					<select name="frequency_days" id="frequency_days">
						<option value="-4">Udbetaling sidste bankdag i måneden</option>
						<option value="-1">Salg sidste bankdag i måneden</option>
					</select>
				</td>
				<td>
					<div class="form-space"><input type="number" name="amount" id="amount" .value="${live(
						this.amount,
					)}" placeholder="Beløb" /></div>
				</td>
				<td>
					<div class="form-space"><input type="number" name="bank_reg" id="bank_reg" .value="${live(
						this.bank_reg,
					)}" placeholder="Reg.nr." /></div>
				</td>
				<td>
					<div class="form-space"><input type="number" name="bank_account" id="bank_account" .value="${live(
						this.bank_account,
					)}" placeholder="Kontonr." /></div>
				</td>
				<td>
					<fm-button-v2 class="btn button-xsmall" type="button" ?disabled="${
						this.disabled
					}" @click="${this.saveRedemptionClick}">Opret</fm-button-v2>
				</td>
    		</tr>`;
		} else {
			const firstdate = redemption.firstdate
				? date(new Date(redemption.firstdate))
				: "";

			const lastdate = redemption.lastdate
				? date(new Date(redemption.lastdate))
				: "";

			return html`
				<tr>
			<td>
				<div class="form-space">
				<input
					type="date"
					name="firstdate"
					value="${firstdate}"
					id="firstdate"
				/>
				</div>
			</td>
			<td>
				<div class="form-space">
				<input
					type="date"
					name="lastdate"
					value="${lastdate}"
					id="lastdate"
				/>
				</div>
			</td>
			<td>
				<select name="frequency_months" id="frequency_months">
					<option value="1" ?selected="${redemption.frequency_months === 1}">Hver måned</option>
					<option value="2" ?selected="${redemption.frequency_months === 2}">Hver 2. måned</option>
					<option value="3" ?selected="${redemption.frequency_months === 3}">Hver 3. måned</option>
					<option value="4" ?selected="${redemption.frequency_months === 4}">Hver 4. måned</option>
					<option value="5" ?selected="${redemption.frequency_months === 5}">Hver 5. måned</option>
					<option value="6" ?selected="${redemption.frequency_months === 6}">Hver 6. måned</option>
					<option value="7" ?selected="${redemption.frequency_months === 7}">Hver 7. måned</option>
					<option value="8" ?selected="${redemption.frequency_months === 8}">Hver 8. måned</option>
					<option value="9" ?selected="${redemption.frequency_months === 9}">Hver 9. måned</option>
					<option value="10" ?selected="${redemption.frequency_months === 10}">Hver 10. måned</option>
					<option value="11" ?selected="${redemption.frequency_months === 11}">Hver 11. måned</option>
					<option value="12" ?selected="${redemption.frequency_months === 12}">Hver 12. måned</option>
				</select>
			</td>
			<td>
				<select name="frequency_days" id="frequency_days">
					<option value="-4" ?selected="${redemption.frequency_days === -4}">Udbetaling sidste bankdag i måneden</option>
					<option value="-1" ?selected="${redemption.frequency_days === -1}">Salg sidste bankdag i måneden</option>
				</select>
			</td>
			<td>
				<div class="form-space">
					<input
						type="number"
						name="amount"
						id="amount"
						value="${redemption.amount}"
						placeholder="Beløb"
					/>
				</div>
			</td>
			<td>
				<div class="form-space">
				<input
					type="number"
					name="bank_reg"
					id="bank_reg"
					value="${redemption.bank_reg}"
					placeholder="Reg.nr."
				/>
				</div>
			</td>
			<td>
				<div class="form-space">
				<input
					type="number"
					name="bank_account"
					id="bank_account"
					value="${redemption.bank_account}"
					placeholder="Kontonr."
				/>
				</div>
			</td>
			<td>
					<fm-button-v2 class="btn button-xsmall" style="margin-bottom: 8px;" type="button" redemption_id="${
						redemption.id
					}" @click="${this.saveRedemptionClick}">Gem</fm-button-v2>
					<fm-button-v2 class="btn button-xsmall" type="button" @click="${
						this.cancelEditRedemptionClick
					}">Fortryd</fm-button-v2>
				</td>
			</tr>`;
		}
	}

	async connectedCallback() {
		super.connectedCallback();
		this.loading = true;

		const getCustodyResponse = await get<{ data: Custody }>(
			`/custodies/${this.custodyId}`,
		);

		if (!getCustodyResponse.ok) {
			const error = await getCustodyResponse.error.json();

			if ("message" in error) {
				toast(error.message);
			} else {
				toast("Der er sket en fejl.");
			}
			return Promise.reject();
		}

		const custody = getCustodyResponse.value.data;

		this.accountId = custody.account_id;

		this.redemptions = await this.getCustodyRedemptions(
			this.accountId,
			custody.id,
		);

		this.loading = false;
	}

	async getCustodyRedemptions(accountId: number, custodyId: number) {
		const getRedemptionsResponse = await get<{
			data: Redemption[];
		}>(
			`/redemptions?account_id=${accountId}&subtrantype_id=4`, // 4 = Nedsparingsaftale
		);

		if (!getRedemptionsResponse.ok) {
			const error = await getRedemptionsResponse.error.json();

			if ("message" in error) {
				toast(error.message);
			} else {
				toast("Der er sket en fejl.");
			}
			return [];
		}

		const accountRedemptions = getRedemptionsResponse.value.data;
		const custodyRedemptions = accountRedemptions.filter((r) => {
			return r.custody_id === custodyId;
		});

		return custodyRedemptions;
	}

	editRedemptionClick(event) {
		this.editRedemptionId =
			Number.parseInt(event.target.getAttribute("redemption_id")) || null;
	}

	cancelEditRedemptionClick() {
		this.editRedemptionId = null;
	}

	async saveRedemptionClick(event) {
		const redemptionId = event.target.getAttribute("redemption_id");
		const formdata = (
			this.shadowRoot?.getElementById("redemption-form") as HTMLFormElement
		)["value"];

		if (!this.accountId) {
			toast("FEJL: Mangler account id");
			return;
		} else if (!this.custodyId) {
			toast("FEJL: Mangler custody id");
			return;
		}

		const redemption = {
			amount: formdata.amount,
			bank_reg: formdata.bank_reg,
			bank_account: formdata.bank_account,
			custody_id: this.custodyId,
			firstdate: formdata.firstdate,
			lastdate: formdata.lastdate,
			frequency_days: formdata.frequency_days,
			frequency_months: formdata.frequency_months,
			id: redemptionId,
			method_id: 1, // Fixed amount
			subtrantype_id: 4, // RED
		};

		if (redemptionId) {
			const updateRedemptionResponse = await put<
				typeof redemption,
				{ data: Redemption }
			>(`/redemptions/${redemptionId}`, redemption);

			if (!updateRedemptionResponse.ok) {
				const error = await updateRedemptionResponse.error.json();

				if ("message" in error) {
					toast(error.message);
				} else {
					toast("Der er sket en fejl");
				}
				return;
			}

			toast("Gemt");
		} else {
			const newRedemptionResponse = await post<
				typeof redemption,
				{ data: Redemption }
			>("/redemptions", redemption);

			if (!newRedemptionResponse.ok) {
				const error = await newRedemptionResponse.error.json();

				if ("message" in error) {
					toast(error.message);
				} else {
					toast("Der er sket en fejl");
				}
				return;
			}
			toast("Oprettet");
		}

		this.redemptions = await this.getCustodyRedemptions(
			this.accountId,
			this.custodyId,
		);

		if (this.editRedemptionId) {
			this.editRedemptionId = null;
		} else {
			const frequencyMonthsElement = this.shadowRoot?.getElementById(
				"frequency_months",
			) as HTMLSelectElement;
			if (frequencyMonthsElement) {
				frequencyMonthsElement.selectedIndex = 0;
			}
			const frequencyDaysElement = this.shadowRoot?.getElementById(
				"frequency_days",
			) as HTMLSelectElement;
			if (frequencyDaysElement) {
				frequencyDaysElement.selectedIndex = 0;
			}

			this.firstdate = "";
			this.lastdate = "";
			this.amount = "";
			this.bank_reg = "";
			this.bank_account = "";
		}
	}
}
