import * as Sentry from "@sentry/browser";
import { LitElement, html, css } from "lit";
import { customElement, state } from "lit/decorators.js";
import type { EmptyObject } from "type-fest";
import pMap from "p-map";
import { get, post } from "../../api/client.js";
import { styles as sharedStyles } from "../../styles/shared.js";
import { styles as tableStyles } from "../../styles/tables.js";
import { styles as inputStyles } from "../../styles/input.js";
import { date } from "../../formatting/dateformats.js";
import { toast } from "../../utils.js";
import type { LovClient } from "../../types/types.js";
import type { ButtonElementV2 } from "../../components/fm-button-v2.js";

declare global {
	interface HTMLElementTagNameMap {
		"customers-send-periodics-report-view": CustomersSendPeriodicsReportView;
	}
}

@customElement("customers-send-periodics-report-view")
export default class CustomersSendPeriodicsReportView extends LitElement {
	@state()
	clients: LovClient[] = [];

	@state()
	selectedClients: number[] = [];

	@state()
	sending = false;

	@state()
	fromDate = "";

	@state()
	toDate = "";

	static styles = [
		sharedStyles,
		tableStyles,
		inputStyles,
		css`label.client {
		cursor: pointer;
	  }

	  div.actions {
		display: flex;
		flex-grow: 0;
		flex-direction: row;
		margin-left: auto;
		align-items: flex-start;
	  }

	  div.card-header {
		align-items: flex-start;
	  }

	  div.card-header .info p {
		margin: 0px;
		color: #666;
	  }

	  fm-form {
		display: flex;
		align-items: flex-end;
		gap: 8px;
	  }

	  div.form-space {
		margin: 0px;
		min-width: initial;
	  }

	  div.form-space input[type="date"] {
		font-size: 12px;
		height: 28px;
	  }
	  div.form-space fm-button-v2.button-small {
		margin: 0px;
	  }`,
	];

	render() {
		return html`
      <div class="card">
        <div class="card-header">
          <div class="info">
            <h1>Send perioderapporter</h1>
          </div>
          <div class="actions">${this.paramForm()}</div>
        </div>
        <div class="card-block">${this.renderClients()}</div>
      </div>
    `;
	}

	async connectedCallback() {
		super.connectedCallback();

		const clients = await get<{ data: LovClient[] }>("/lov/clients");

		if (!clients.ok) {
			return;
		}

		this.clients = clients.value.data;

		const now = new Date();
		now.setDate(now.getDate() - 20);
		const quarter = Math.floor(now.getMonth() / 3);
		const fromDate = new Date(now.getFullYear(), quarter * 3, 1);
		this.fromDate = date(fromDate);
		this.toDate = date(
			new Date(fromDate.getFullYear(), fromDate.getMonth() + 3, 0),
		);
	}

	paramForm() {
		return html` <fm-form method="get" id="parms" class="reportform no-print">
      <div class="form-space">
        <label>Fra</label>
        <input type="date" name="fromdate" value="${this.fromDate}"
              @input="${(e: InputEvent) => {
								this.fromDate = (e.target as HTMLInputElement).value;
							}}"  />
      </div>
      <div class="form-space">
        <label>Til</label>
        <input type="date" name="todate" value="${this.toDate}"
              @input="${(e: InputEvent) => {
								this.toDate = (e.target as HTMLInputElement).value;
							}}"  />
      </div>
      <div class="form-space">
        <fm-button-v2
          id="submit_button"
          type="submit"
          class="button submit"
          @click="${this.mailSelected}"
          ?disabled="${this.sending || !this.formValid()}"
          >Send til valgte</fm-button-v2
        >
      </div>
    </fm-form>`;
	}

	renderClients() {
		return html`<table>
      <thead>
        <tr>
          <th>
            <input
              type="checkbox"
              @click="${this.toggleAll}"
              .checked="${this.allSelected()}"
            />
          </th>
          <th>Navn</th>
          <th>Send</th>
        </tr>
      </thead>
      <tbody>
        ${this.clients.map((c) => this.renderClientRow(c))}
      </tbody>
    </table>`;
	}

	renderClientRow(client: LovClient) {
		return html`<tr>
      <td>
        <input
          type="checkbox"
          value="${client.id}"
          id="client-${client.id}"
          @change="${(e: Event) => this.handleCheckboxChange(e, client.id)}"
          ?disabled="${this.sending}"
          .checked="${this.isSelected(client.id)}"
        />
      </td>
      <td width="100%">
        <label
          class="client ${this.sending ? "disabled-label" : ""}"
          for="client-${client.id}"
          id="client-${client.id}-label"
          >${client.name}</label
        >
      </td>
      <td>
        <fm-button-v2
          style="display:inline-block;"
          type="submit"
          class="button submit button-xsmall"
          @click="${this.mail}"
          data-client-id="${client.id}"
          ?disabled="${this.sending || !this.formValid()}"
          >Send</fm-button-v2
        >
      </td>
    </tr>`;
	}

	isSelected(clientId: number) {
		return this.selectedClients.includes(clientId);
	}

	allSelected() {
		return (
			this.selectedClients.length === this.clients.length &&
			this.clients.length > 0
		);
	}

	toggleAll() {
		if (this.allSelected()) {
			this.selectedClients = [];
		} else {
			this.selectedClients = this.clients.flatMap((client) => client.id);
		}
	}

	handleCheckboxChange(e: Event, item: number) {
		const target = e.target as HTMLInputElement;

		if (target.checked) {
			this.selectedClients = [...this.selectedClients, item];
		} else {
			this.selectedClients = this.selectedClients.filter(
				(selectedClient) => selectedClient !== item,
			);
		}
	}

	mailClient(clientId: number, fromDate: string, toDate: string) {
		return post<EmptyObject, { data: { status: string } }>(
			`/clients/${clientId}/periodic-mail?from=${fromDate}&to=${toDate}`,
			{},
		);
	}

	// ONLY FOR REFERENCE
	// Uses /clients/periodic-mails endpoints to send mails asynchronously server side
	// async mailSelectedAsync(event: Event) {
	// 	if (this.selectedClients.length === 0) {
	// 		toast("Ingen kunder valgt.");

	// 		return;
	// 	}

	// 	this.sending = true;

	// 	try {
	// 		const periodicsMails = await post<
	// 			{ clientIds: number[] },
	// 			{ data: PeriodicMails }
	// 		>(`/clients/periodic-mails?from=${this.fromDate}&to=${this.toDate}`, {
	// 			clientIds: this.selectedClients,
	// 		});

	// 		if (!periodicsMails.ok) {
	// 			return;
	// 		}

	// 		if (periodicsMails.value.data.status === "ERROR") {
	// 			toast(
	// 				`FEJL: Der opstod fejl ved afsendelse af mail${
	// 					this.selectedClients.length > 1 ? "s" : ""
	// 				} til ${this.selectedClients.length} kunde${
	// 					this.selectedClients.length > 1 ? "r" : ""
	// 				}`,
	// 			);
	// 		} else {
	// 			toast(
	// 				`Sender mail${this.selectedClients.length > 1 ? "s" : ""} til ${
	// 					this.selectedClients.length
	// 				} kunde${this.selectedClients.length > 1 ? "r" : ""}`,
	// 			);
	// 		}

	// 		// Removes checkboxes from succesful calls
	// 		periodicsMails.value.data.clientIds?.map((rv: PeriodicMailsClient) => {
	// 			if (rv.status === "OK") {
	// 				this.selectedClients = this.selectedClients.filter(
	// 					(e) => e !== rv.clientId,
	// 				);
	// 			}
	// 		});
	// 	} catch (err) {
	// 		Sentry.captureException(err);
	// 		toast(
	// 			`FEJL: Ved afsendelse af mail${
	// 				this.selectedClients.length > 1 ? "s" : ""
	// 			} til ${this.selectedClients.length} kunde${
	// 				this.selectedClients.length > 1 ? "r" : ""
	// 			}`,
	// 		);
	// 	} finally {
	// 		this.sending = false;
	// 	}
	// }

	async mailSelected(event: Event) {
		if (this.selectedClients.length === 0) {
			toast("Ingen kunder valgt.");

			return;
		}

		const target = event.target as ButtonElementV2;
		target.loading = true;

		this.sending = true;

		let remainingClients = this.selectedClients;

		// Rate-limit number of concurrent reports requests being made
		const mapper = async (clientId: number) => {
			try {
				const res = await this.mailClient(clientId, this.fromDate, this.toDate);

				if (!res.ok) {
					const error = await res.error.json();

					if ("message" in error) {
						toast(`FEJL: ${error.message}`);
					} else {
						toast("Der er sket en fejl.");
					}

					return false;
				}

				remainingClients = remainingClients.filter((e) => e !== clientId);

				// Update UI while request is active
				const checkbox = this.shadowRoot?.getElementById(
					`client-${clientId}`,
				) as HTMLInputElement;

				if (!checkbox) {
					return;
				}

				checkbox.checked = false;
			} catch (err) {
				Sentry.captureException(err);
				return false;
			}

			return true;
		};

		await pMap(this.selectedClients, mapper, {
			concurrency: 5,
			stopOnError: false,
		});

		if (remainingClients.length > 0) {
			toast(
				`FEJL: ${remainingClients.length} af ${
					this.selectedClients.length
				} mail${
					this.selectedClients.length > 1 ? "s" : ""
				} kunne ikke blive sendt.`,
			);
		} else {
			toast(
				`Mail${this.selectedClients.length > 1 ? "s" : ""} sendt til ${
					this.selectedClients.length
				} kunde${this.selectedClients.length > 1 ? "r" : ""}`,
			);
		}

		this.selectedClients = remainingClients;
		this.sending = false;

		target.loading = false;
	}

	async mail(event: Event) {
		const target = event.target as ButtonElementV2;
		target.loading = true;
		this.sending = true;

		const clientIdString = target.dataset["clientId"] || "";

		if (Number.isNaN(clientIdString)) {
			toast("FEJL: Ingen kunde valgt.");
			return;
		}

		const clientId = Number.parseInt(clientIdString);

		try {
			const res = await this.mailClient(clientId, this.fromDate, this.toDate);

			if (!res.ok) {
				const error = await res.error.json();

				if ("message" in error) {
					toast(`FEJL: ${error.message}`);
				} else {
					toast("Der er sket en fejl.");
				}
			} else {
				toast("Mail sendt til kunden.");
			}
		} catch (err) {
			Sentry.captureException(err);
			toast(`FEJL: Mail kunne ikke sendes til kunden (${clientId}).`);
		}

		this.sending = false;
		target.loading = false;
	}

	formValid() {
		return this.fromDate && this.toDate;
	}
}
