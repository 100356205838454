import { LitElement, html, css } from "lit";
import { styles as sharedStyles } from "../../styles/shared.js";
import { styles as tableStyles } from "../../styles/tables.js";
import { styles as inputStyles } from "../../styles/input.js";
import store from "../../store/index.js";
import { setTitle } from "../../store/actions.js";
import router from "../../router/index.js";
import { customElement, property } from "lit/decorators.js";

declare global {
	interface HTMLElementTagNameMap {
		"groups-container-view": GroupsContainerView;
	}
}

@customElement("groups-container-view")
export default class GroupsContainerView extends LitElement {
	@property({ type: String })
	accessor groupId: string | undefined;

	async connectedCallback() {
		super.connectedCallback();
		store.dispatch(setTitle("Kundegrupper"));
	}

	static styles = [
		sharedStyles,
		tableStyles,
		inputStyles,
		css`select {
    background: var(--primary-color);
    color: white;
    border: none;
    font-weight: 500;
    font-family: Roboto, sans-serif;
  }

  select:focus {
    outline: 0;
  }

  ul.action-links li > label {
    background: var(--primary-color);
    padding: 8px 8px;
  }

  ul.action-links option:first-child {
    text-align: center;
  }

  ul.action-links li:last-child {
    margin-right: 0px;
    padding-right: 0px;
  }`,
	];

	render() {
		return html`
      ${
				this.groupId != null
					? html` <header class="horizontal-menu-header">
            <div class="secondary">
              <div class="actions">
                <ul class="action-links">
                  <li>
                    <label for="reports">
                      <select id="reports" @change="${this.report}">
                        <option value="">RAPPORTER</option>
                        <option disabled>----------------------</option>
                        <option value="overview">Overblik</option>
                        <option value="transactions">Transaktioner</option>
                      </select>
                    </label>
                  </li>
                </ul>
              </div>
            </div>
          </header>`
					: null
			}
      <slot></slot>
    `;
	}

	report(event: Event) {
		const report = (event.target as HTMLSelectElement).value;

		// If the user views a report and then a second one,
		// we use this regex to remove the previous report from the path
		const basePathRegEx = /.*[0-9]+/;
		let basePath = "/groups";

		if (basePathRegEx.test(window.location.pathname)) {
			const parts = basePathRegEx.exec(window.location.pathname);

			basePath = parts ? parts[0] : basePath;
		}

		router.push(`${basePath}/${report}`);
	}
}
