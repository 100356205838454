import { LitElement, html, css } from "lit";
import { state, property, customElement } from "lit/decorators.js";

import { styles as sharedStyles } from "../../../styles/shared.js";
import { styles as tableStyles } from "../../../styles/tables.js";
import { styles as inputStyles } from "../../../styles/input.js";

import { sort, toast } from "../../../utils.js";
import { money } from "../../../formatting/numberformats.js";
import type { User } from "@sentry/browser";
import { get } from "../../../api/client.js";
import type { TaxFormReportItem } from "../../../types/types.js";
import type { Result } from "../../../result.js";

declare global {
	interface HTMLElementTagNameMap {
		"person-tax-form-report-view": typeof PersonTaxFormReportView;
	}
}

@customElement("person-tax-form-report-view")
export default class PersonTaxFormReportView extends LitElement {
	@property({ type: Number })
	accessor userId: number | undefined;

	@state()
	taxFormReport: TaxFormReportItem[] = [];

	@state()
	user?: User;

	async connectedCallback() {
		super.connectedCallback();

		if (!this.userId) {
			return;
		}

		const userResponse = await get<{ data: User }>(`/users/${this.userId}`);
		if (!userResponse.ok) {
			const error = await userResponse.error.json();

			if ("message" in error) {
				toast(error.message);
			} else {
				toast("Der er sket en fejl.");
			}

			return;
		}

		this.user = userResponse.value.data;

		if (!this.user) {
			return;
		}

		let taxFormResponse: Result<
			{
				data: TaxFormReportItem[];
			},
			Response
		>;

		if (this.user["cpr"]) {
			taxFormResponse = await get<{ data: TaxFormReportItem[] }>(
				`/taxform?q=${this.user["cpr"]}`,
			);
		} else if (this.user["cvr"]) {
			taxFormResponse = await get<{ data: TaxFormReportItem[] }>(
				`/taxform?q=${this.user["cvr"]}`,
			);
		} else {
			toast("Brugeren har hverken et CPR-nummer eller CVR-nummer tilknyttet.");

			return;
		}

		if (!taxFormResponse.ok) {
			const error = await taxFormResponse.error.json();

			if ("message" in error) {
				toast(error.message);
			} else {
				toast("Der er sket en fejl.");
			}

			return;
		}

		this.taxFormReport = taxFormResponse.value.data;
	}

	static styles = [
		sharedStyles,
		tableStyles,
		inputStyles,
		css`tr.sum {
    font-weight: bold;
  }`,
	];
	render() {
		return html`
      <div class="card">
        <div class="card-header">
          <h1>Selvangivelse</h1>
        </div>
        <div class="card-block">${this.renderTable()}</div>
      </div>
    `;
	}

	renderTable() {
		return html`
      <table>
        <thead>
          <tr>
            <th data-key="custody_code" @click="${this.sort}">
              Konto
            </th>
            <th data-key="year" @click="${this.sort}">Skatteår</th>
            <th data-key="owner_count" @click="${this.sort}">Antal ejere</th>
            <th data-key="taxfield_code" @click="${this.sort}">Rubrik</th>
            <th data-key="security_name" @click="${this.sort}">
              Investeringsbevis
            </th>
            <th class="numeric" data-key="amount" @click="${this.sort}">
              Beløb
            </th>
          </tr>
        </thead>
        <tbody>
          ${this.taxFormReport.map(
						(item) => html`
              <tr class="${item.security_name === "Ialt " ? "sum" : ""}">
                <td>${item.custody_code}</td>
                <td>${item.year}</td>
                <td>${item.owner_count}</td>
                <td>${item.taxfield_code} <br />${item.taxfield_description}</td>
                <td>${item.security_name}</td>
                <td class="numeric">${money(item.amount)}</td>
              </tr>
            `,
					)}
        </tbody>
      </table>
    `;
	}

	sort(event: Event) {
		const key = (event.target as HTMLTableCellElement).getAttribute("data-key");

		if (!key) {
			return;
		}

		this.taxFormReport = sort(this.taxFormReport, key) as TaxFormReportItem[];

		this.requestUpdate();
	}
}
