import { LitElement, html, css } from "lit";
import { customElement, state, property } from "lit/decorators.js";

import { styles as sharedStyles } from "../../../styles/shared.js";
import { styles as tableStyles } from "../../../styles/tables.js";
import { styles as inputStyles } from "../../../styles/input.js";

import { get } from "../../../api/client.js";

import { type Formats, toCsvF, toast } from "../../../utils.js";
import { money, quantity } from "../../../formatting/numberformats.js";
import { date, DateFormatter } from "../../../formatting/dateformats.js";
import type {
	Custody,
	CustodyStatement,
	CustodyStatementStatus,
	CustodyStatementTransaction,
	Nota,
} from "../../../types/types.js";
import type { ButtonElementV2 } from "../../../components/fm-button-v2.js";

declare global {
	interface HTMLElementTagNameMap {
		"custody-statement-report-view": typeof CustodyStatementReportView;
	}
}

@customElement("custody-statement-report-view")
export default class CustodyStatementReportView extends LitElement {
	@property({ type: Number })
	accessor custodyId: number | undefined;

	@state()
	custody?: Custody;

	@state()
	statement?: CustodyStatement;

	@state()
	fromDate: string = date(new Date().addDays(-10));

	@state()
	toDate: string = date(new Date());

	@state()
	loading = false;

	@state()
	downloading = false;

	@state()
	valueSum = 0;

	@state()
	fundorderId?: number;

	static styles = [
		sharedStyles,
		tableStyles,
		inputStyles,
		css` .tiny-button {
    min-width: 50px;
    font-size: 10px;
    padding: 4px;
  }

  div.actions {
    display: flex;
    flex-grow: 0;
    flex-direction: row;
    margin-left: auto;
    align-items: flex-start;
  }

  div.card-header {
    align-items: flex-start;
  }

  div.card-header .info p {
    margin: 0px;
    color: #666;
  }

  fm-form {
    display: flex;
    align-items: flex-end;
    gap: 8px;
  }

  div.form-space {
    margin: 0px;
    min-width: initial;
  }

  div.form-space input[type="date"] {
    font-size: 12px;
    height: 28px;
  }
  div.form-space fm-button-v2.button-small {
    margin: 0px;
  }

  div.status {
    display: flex;
    width: 100%;
    flex-direction: row;
    gap: 24px;
    padding: 12px 24px;
  }

  div.status > div {
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 1px solid #ccc;
    padding: 8px 16px;
  }

  div.status tfoot td {
    border-bottom: none;
    font-weight: bold;
  }

  div.transactions {
    display: flex;
    flex-direction: column;
    padding: 0px 24px 12px;
    /* gap: 10px; */
  }

  div.transactions tr td {
    border-bottom: none;
    padding: 8px;
  }

  div.transactions tr:last-child td {
    border-bottom: 1px solid #ddd;
  }

  div.transactions tr.nota td {
    border-top: 1px solid #ddd;
  }`,
	];

	render() {
		return html`
      <div class="card">
        <div class="card-header">
          <div class="info">
            <h1>Kontoudtog</h1>
            ${
							this.statement
								? html`<p>
                  Periode:
                  ${DateFormatter.format(new Date(this.fromDate), "dd/mm/yyyy")}
                  til
                  ${DateFormatter.format(new Date(this.toDate), "dd/mm/yyyy")}
                </p>`
								: null
						}
          </div>

          <div class="actions">
            <fm-form
              id="parms"
              class="reportform no-print"
              @submit="${this.refresh}"
            >
              <div class="form-space">
                <label>Fra (handelsdato)</label>
                <input
                  type="date"
                  class="date-input"
                  name="fromdate"
                  value="${this.fromDate}"
                  @input="${(e: Event) => {
										this.fromDate = (e.target as HTMLInputElement).value;
									}}"
                />
              </div>
              <div class="form-space">
                <label>Til (statusdato)</label>
                <input
                  type="date"
                  class="date-input"
                  name="todate"
                  value="${this.toDate}"
                  @input="${(e: Event) => {
										this.toDate = (e.target as HTMLInputElement).value;
									}}"
                />
              </div>
              <div class="form-space">
                <fm-button-v2 id="submit_button" type="submit" class="button-small" ?disabled="${!this.formValid()}" .loading="${
									this.loading
								}"
                  >Vis</fm-button-v2
                >
              </div>
              <div class="form-space">
                <fm-button-v2
                  id="download_button"
                  class="button-small"
                  ?disabled="${!this.statement}"
                  .loading="${this.downloading}"
                  @click="${this.onDownload}"
                  >Download</fm-button-v2
                >
              </div>
            </fm-form>
          </div>
        </div>
        ${
					this.statement
						? html`
              <div class="status">
                ${this.renderStatus(this.statement.primo)}
                ${this.renderStatus(this.statement.ultimo)}
              </div>
              ${this.renderTran(this.statement.tran)}
            `
						: null
				}
      </div>
    `;
	}

	renderStatusRow(r: CustodyStatementStatus) {
		this.valueSum += r.beloeb;
		return html`
      <tr>
        <td>${r.inv_bevis}</td>
        <td>${quantity(r.antal_stk)}</td>
        <td class="numeric">${money(r.beloeb)}</td>
      </tr>
    `;
	}

	renderStatus(rows: CustodyStatementStatus[]) {
		this.valueSum = 0;
		if (rows.length === 0) {
			return null;
		}
		return html`
      <div>
        <h3>
          Status pr.
          ${DateFormatter.format(new Date(rows[0].dato), "dd/mm/yyyy")}
        </h3>
        <table>
          <thead>
            <tr>
              <th>Inv.bevis</th>
              <th>Beholdning</th>
              <th class="numeric">Værdi</th>
            </tr>
          </thead>
          ${(rows || []).map((r) => this.renderStatusRow(r))}
          <tfoot>
            <tr>
              <td colspan="2">I alt</td>
              <td class="numeric">${money(this.valueSum)}</td>
            </tr>
          </tfoot>
        </table>
      </div>
    `;
	}

	renderTranRow(r: CustodyStatementTransaction) {
		const fundorderId = this.fundorderId;
		this.fundorderId = r.fundorder_id;
		if (r.fundorder_id === fundorderId) {
			return html`
        <tr>
          <td></td>
          <td>${r.stmt_text}</td>
          <td>${r.security_name}</td>
          <td class="numeric">${quantity(r.quantity)}</td>
          <td class="numeric">${money(r.amountqc)}</td>
          <td class="numeric">${money(r.taxqc)}</td>
          <td class="numeric">${quantity(r.position_after)}</td>
        </tr>
      `;
		}

		return html`
        <tr class="nota">
          <td>${DateFormatter.format(new Date(r.stmt_date), "dd/mm/yyyy")}</td>
          <td>
            ${r.stmt_text}
            <fm-button-v2
              class="button tiny-button"
              data-id="${r.fundorder_id}"
              @click="${this.onNota}"
            >
              Nota
            </fm-button-v2>
          </td>
          <td>${r.security_name}</td>
          <td class="numeric">${quantity(r.quantity)}</td>
          <td class="numeric">${money(r.amountqc)}</td>
          <td class="numeric">${money(r.taxqc)}</td>
          <td class="numeric">${quantity(r.position_after)}</td>
        </tr>
      `;
	}

	renderTran(rows: CustodyStatementTransaction[]) {
		if (rows.length === 0) {
			return "";
		}
		this.fundorderId = 0;
		return html` <div class="transactions">
      <h3>Bevægelser</h3>
      <table>
        <thead>
          <tr>
            <th>Dato</th>
            <th>Bevægelse</th>
            <th>Inv.bevis</th>
            <th class="numeric">Antal stk.</th>
            <th class="numeric">Beløb</th>
            <th class="numeric">Skat</th>
            <th class="numeric">Beholdning</th>
          </tr>
        </thead>
        ${(rows || []).map((r) => this.renderTranRow(r))}
      </table>
    </div>`;
	}

	async search() {
		const custodyResponse = await get<{ data: Custody }>(
			`/custodies/${this.custodyId}`,
		);

		if (!custodyResponse.ok) {
			const error = await custodyResponse.error.json();

			if ("message" in error) {
				toast(error.message);
			} else {
				toast("Der er sket en fejl.");
			}

			return;
		}

		this.custody = custodyResponse.value.data;

		const statementResponse = await get<{ data: CustodyStatement }>(
			`/custodies/${this.custodyId}/statement?from=${this.fromDate}&to=${this.toDate}`,
		);

		if (!statementResponse.ok) {
			const error = await statementResponse.error.json();

			if ("message" in error) {
				toast(error.message);
			} else {
				toast("Der er sket en fejl.");
			}

			return;
		}
		this.statement = statementResponse.value.data;
	}

	async refresh() {
		this.loading = true;
		await this.search();
		this.loading = false;
	}

	async onNota(event: Event) {
		const target = event.target as ButtonElementV2;
		target.loading = true;

		const id = target.getAttribute("data-id");
		const notaResponse = await get<Nota>(`/nota/${id}`);

		if (!notaResponse.ok) {
			const error = await notaResponse.error.json();

			if ("message" in error) {
				toast(error.message);
			} else {
				toast("Der er sket en fejl.");
			}

			return;
		}

		const PDF = notaResponse.value.nota;

		const a = document.createElement("a");
		a.setAttribute("href", `data:application/pdf;base64,${PDF}`);
		a.setAttribute("download", "fundmarket_nota.pdf");
		a.click();
		target.loading = false;
	}

	async onDownload() {
		this.downloading = true;

		await this.search();

		const csvHeaders = [
			"dato",
			"tekst",
			"inv.bevis",
			"Antal",
			"Beløb",
			"Beholdning",
		];
		const csvFields1 = [
			"dato",
			"tekst",
			"inv_bevis",
			"antal_stk",
			"beloeb",
			"beholdning_efter",
		];
		const csvFields2 = [
			"stmt_date",
			"stmt_text",
			"security_name",
			"quantity",
			"amountqc",
			"position_after",
		];
		const csvFormats: Formats[] = ["s", "s", "s", "q", "m", "q"];

		if (!this.statement) {
			return;
		}

		let txtdata = toCsvF(
			csvHeaders,
			csvFields1,
			csvFormats,
			this.statement.primo,
		);
		txtdata = txtdata + toCsvF([], csvFields2, csvFormats, this.statement.tran);
		txtdata =
			txtdata + toCsvF([], csvFields1, csvFormats, this.statement.ultimo);

		txtdata = btoa(txtdata);

		const a = document.createElement("a");
		a.setAttribute("href", `data:text/csv;base64,${txtdata}`);
		a.setAttribute("download", "fm_kontoudtog.csv");
		a.click();

		this.downloading = false;
	}

	formValid() {
		return this.fromDate && this.toDate;
	}
}
