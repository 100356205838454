import { LitElement, html, css } from "lit";

import { styles as sharedStyles } from "../../styles/shared.js";
import { styles as tableStyles } from "../../styles/tables.js";
import { styles as inputStyles } from "../../styles/input.js";
import { styles as uploadStyles } from "../../styles/upload.js";
import { customElement, property, state } from "lit/decorators.js";
import { toast } from "../../utils.js";
import { live } from "lit/directives/live.js";
import { post } from "../../api/client.js";

declare global {
	interface HTMLElementTagNameMap {
		"upload-agreement-file-view": UploadAgreementFileView;
	}
}

@customElement("upload-agreement-file-view")
export default class UploadAgreementFileView extends LitElement {
	@property({ type: Number })
	accessor agreementId: number | undefined;

	@state()
	row: object = {};

	@state()
	userComment = "";

	@state()
	fileName?: string;

	@state()
	fileType?: string;

	@state()
	fileContent?: string;

	static styles = [
		sharedStyles,
		tableStyles,
		inputStyles,
		uploadStyles,
		css`#form {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 16px;
  }

  #form label {
    margin-bottom: 4px;
  }

  #form div.row {
    display: flex;
    flex-direction: row;
    gap: 16px;
  }

  #form div.row > div {
    width: 100%;
  }

  input#description {
    padding: 8px;
    width: 100%;
    height: 32px;
    border: 1px solid #ddd;
  }

  div.overlay {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }`,
	];

	render() {
		return html`
      <div class="overlay">
        <h1>Upload aftaledokument</h1>
        <fm-form
          id="form"
        >
          <div class="row">
            <div>
              <label>Aftaledokument</label>
              <input type="file" id="file" @change="${this.onFileChange}" />
            </div>
            <div>
              <label>Beskrivelse</label>
              <input
                type="text"
                name="user_comment"
                .value="${live(this.userComment)}"
			  @input="${(e: Event) => {
					this.userComment = (e.target as HTMLInputElement).value;
				}}"
              />
            </div>
          </div>
          <div class="row">
            <div>
              <fm-button-v2
                class="button"
                type="button"
                @click="${this.onSave}"
                ?disabled="${!this.isFormValid()}"
              >
                Gem
              </fm-button-v2>
            </div>
          </div>
        </fm-form>
      </div>
    `;
	}

	isFormValid() {
		return this.fileName !== undefined;
	}

	fileRead(f: File) {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.addEventListener("loadend", (e: ProgressEvent<FileReader>) => {
				if (e.target?.result) {
					this.fileContent = e.target.result.toString();
					resolve("");
				} else {
					reject("Error reading file");
				}
			});
			reader.readAsDataURL(f);
		});
	}

	async onFileChange(event: Event) {
		event.preventDefault();
		const target = event.target as HTMLInputElement;

		if (!target.files) {
			return;
		}

		const file = target.files[0];
		this.fileName = file.name;
		this.fileType = file.type;

		await this.fileRead(file);
	}

	async onSave(event: Event) {
		event.preventDefault();

		if (!this.fileName || !this.fileType || !this.fileContent) {
			return;
		}

		const newAgreementDocumentFile = {
			user_comment: this.userComment,
			filename: this.fileName,
			filetype: this.fileType,
			content: this.fileContent,
		};

		const newAgreementDocumentFileResponse = await post<
			{
				user_comment: string;
				filename: string;
				filetype: string;
				content: string;
			},
			{
				id: number;
				message: "OK";
			}
		>(`/agreements/${this.agreementId}/file`, newAgreementDocumentFile);

		if (!newAgreementDocumentFileResponse.ok) {
			const error = await newAgreementDocumentFileResponse.error.json();

			if ("message" in error) {
				toast(error.message);
			} else {
				toast("Der er sket en fejl");
			}
			return;
		}

		this.dispatchEvent(new CustomEvent("uploaded"));
		toast("Dokumentet blev uploadet");
	}
}
