import { html, css } from "lit";

export const styles = css`
fm-file-upload {
    display: block;
    border: 2px dashed #BDBDBD;
    border-radius: 4px;
    margin: 16px 0;
    height: 300px;
    cursor: pointer;
  }
  
  .upload-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100%;
    font-size: 20px;
    padding: 32px 16px;
    pointer-events: none;
  }
  
  fm-file-upload:not([empty]) .empty {
    display: none;
  }
  
  fm-file-upload:not([populated]) + button {
    display: none;
  }
  
  fm-file-upload:not([populated]) .populated {
    display: none;
  }
  
  fm-file-upload.sent {
    display: none;
  }
  
  
  fm-file-upload:not([uploading]) .uploading {
    display: none;
  }
  
  fm-file-upload:not([done]) .done {
    display: none;
  }
  
  fm-file-upload[populated] {
    border-color: var(--primary-color);
  }
  
  fm-file-upload[dropping] {
    background: #eee;
  }
  
  fm-file-upload[populated] fm-icon {
    color: var(--primary-color);
  }
  `;

export default html`<style>${styles}</style>`;
