import type { Client, Custody, User } from "../types/types";

export const RECEIVE_STATUS = "RECEIVE_STATUS";
export function receiveStatus(status: { authenticated: boolean }) {
	return {
		type: RECEIVE_STATUS,
		status,
	};
}

export const INVALIDATE_STATUS = "INVALIDATE_STATUS";
export function invalidateStatus() {
	return {
		type: INVALIDATE_STATUS,
	};
}

export const SET_TITLE = "SET_TITLE";
export function setTitle(title: string) {
	return {
		type: SET_TITLE,
		title,
	};
}

// Show Personal Identifiable Information
export const SET_SHOW_PII = "SET_SHOW_PII";
export function setShowPII(showPII: boolean) {
	return {
		type: SET_SHOW_PII,
		showPII,
	};
}

export const SET_CLIENT_DATA = "SET_CLIENT_DATA";
export function setClientData(clientData: Client | null) {
	return {
		type: SET_CLIENT_DATA,
		clientData,
	};
}

export const SET_USER_DATA = "SET_USER_DATA";
export function setUserData(userData: User | null) {
	return {
		type: SET_USER_DATA,
		userData,
	};
}

export const SET_CUSTODY_DATA = "SET_CUSTODY_DATA";
export function setCustodyData(custodyData: Custody | null) {
	return {
		type: SET_CUSTODY_DATA,
		custodyData,
	};
}
