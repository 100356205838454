import { LitElement, html, css } from "lit";
import { customElement, state } from "lit/decorators.js";
import { styles as sharedStyles } from "../../styles/shared.js";
import { styles as tableStyles } from "../../styles/tables.js";
import { styles as inputStyles } from "../../styles/input.js";
import { toast } from "../../utils.js";
import { Agreement } from "../../types/types.js";
import { post } from "../../api/client.js";
import { live } from "lit/directives/live.js";

declare global {
	interface HTMLElementTagNameMap {
		"create-agreement-view": CreateAgreementView;
	}
}

@customElement("create-agreement-view")
export default class CreateAgreementView extends LitElement {
	@state()
	clientaccountId?: number;

	@state()
	userComment: string = "";

	static styles = [
		sharedStyles,
		tableStyles,
		inputStyles,
		css`#form {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 16px;
  }

  #form label {
    margin-bottom: 4px;
  }

  #form div.row {
    display: flex;
    flex-direction: row;
    gap: 16px;
  }

  #form div.row > div {
    width: 100%;
  }

  input#description {
    padding: 8px;
    width: 100%;
    height: 32px;
    border: 1px solid #ddd;
  }

  div.overlay {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }`,
	];

	render() {
		return html`
      <div class="overlay">
        <h1>Opret ny kundeaftale</h1>
        <fm-form id="form" url="/agreements/" method="post" role="form">
          <div class="row">
            <div>
              <label>Kundeengagement</label>
              <fm-autocomplete
                name="clientaccount_id"
                url="/lov/clientaccounts"
                display="name"
                select="id"
                @select="${this.onClientSelect}"
              >
              </fm-autocomplete>
            </div>
            <div>
              <label>Beskrivelse</label>
              <input type="text" name="user_comment" id="description" .value="${live(
								this.userComment,
							)}" @input="${(e: Event) => {
			this.userComment = (e.target as HTMLInputElement).value;
		}}"  />
            </div>
          </div>
          <div class="row">
            <div>
              <fm-button-v2
                class="button"
                type="button"
                @click="${this.save}"
                ?disabled="${!this.isFormValid()}"
              >
                Gem
              </fm-button-v2>
            </div>
          </div>
        </fm-form>
      </div>
    `;
	}

	isFormValid() {
		return this.clientaccountId !== undefined;
	}

	async onClientSelect(event: CustomEvent) {
		const clientaccountIdString = (event.target as HTMLInputElement)?.value;
		this.clientaccountId = parseInt(clientaccountIdString) || undefined;
	}

	async save(event: Event) {
		event.preventDefault();

		if (!this.clientaccountId) {
			toast("FEJL: Ingen kunde valgt");
			return;
		}

		const newAgreementData = {
			clientaccount_id: this.clientaccountId,
			user_comment: this.userComment,
		};

		const newAgreementResponse = await post<
			{ clientaccount_id: number; user_comment: string },
			{ data: Agreement }
		>("/agreements/", newAgreementData);

		if (!newAgreementResponse.ok) {
			const error = await newAgreementResponse.error.json();

			if ("error" in error) {
				toast(`${error.type}: ${error.error}`);
			} else {
				toast("Der er sket en fejl");
			}
			return;
		}

		this.dispatchEvent(
			new CustomEvent("created", {
				detail: { agreementId: newAgreementResponse.value.data.id },
			}),
		);
	}
}
