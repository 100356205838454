import { html, css } from "lit";

export const styles = css`
    table {
      border-collapse: collapse;
      border-bottom: none;
      width: 100%;
    }

    table a {
      color: #448aff;
      text-decoration: none;
    }

    th,
    td {
      padding: 12px;
      border-bottom: 1px solid #ddd;
    }

    .tight {
      padding: 4px;
    }

    table.tight tr td {
      font-size: 13px;
      padding: 6px 12px;
    }

    td.separate {
      padding: 12px;
      border-bottom: 2px solid black;
    }

    th {
      text-align: left;
      color: #333;
      font-size: 13px;
      font-weight: 500;
    }

    th[data-key] {
      cursor: pointer;
    }

    table thead.sticky tr th {
      position: sticky;
      top: 0px;
      background-color: white;

      z-index: 100;
    }

    td {
      color: #333;
      font-size: 14px;
    }

    .numeric {
      text-align: right;
    }

    .right-align {
      text-align: right;
    }

    .red {
      background: red;
    }

    .order-detail {
      width: 100%;
      display: grid;
      grid-template-columns: 4% 4% 11% 10% 10% 8% 8% 6% 6% 6% 6% 8% 6% 4%;
      grid-template-rows: auto;
      border-bottom: 1px solid #ddd;
    }
`;

export default html`<style>${styles}</style>`;
