import { LitElement, html, css } from "lit";
import { customElement, property, state } from "lit/decorators.js";

import { styles as sharedStyles } from "../../../styles/shared.js";
import { styles as tableStyles } from "../../../styles/tables.js";
import { styles as inputStyles } from "../../../styles/input.js";

import { toast } from "../../../utils.js";
import type {
	Custody,
	ModelItem,
	ModelCurrent,
	ShoppingListItem,
} from "../../../types/types.js";
import { get, post } from "../../../api/client.js";

declare global {
	interface HTMLElementTagNameMap {
		"custody-model-portfolio-view": typeof CustodyModelPortfolioView;
	}
}

@customElement("custody-model-portfolio-view")
export default class CustodyModelPortfolioView extends LitElement {
	@property({ type: Number })
	accessor custodyId: number | undefined;

	@state()
	custody?: Custody;

	@state()
	shoppingList: ShoppingListItem[] = [];

	@state()
	shareSum = 0.0;

	@state()
	modelId?: number;

	@state()
	models: ModelItem[] = [];

	@state()
	loading = false;

	@state()
	unlockShoppingList = false;

	@state()
	disableSave = false;

	@state()
	disableCancel = false;

	async connectedCallback() {
		super.connectedCallback();

		if (this.custodyId) {
			this.loading = true;

			const modelsResponse = await get<{ data: ModelItem[] }>("/models");

			if (!modelsResponse.ok) {
				const error = await modelsResponse.error.json();

				if ("message" in error) {
					toast(error.message);
				} else {
					toast("Der er sket en fejl.");
				}

				this.loading = false;
				return;
			}

			const custodyResponse = await get<{ data: Custody }>(
				`/custodies/${this.custodyId}`,
			);

			if (!custodyResponse.ok) {
				const error = await custodyResponse.error.json();

				if ("message" in error) {
					toast(error.message);
				} else {
					toast("Der er sket en fejl.");
				}

				this.loading = false;
				return;
			}

			const shoppingListResponse = await get<{ data: ShoppingListItem[] }>(
				`/custodies/${this.custodyId}/shopping-list`,
			);

			if (!shoppingListResponse.ok) {
				const error = await shoppingListResponse.error.json();

				if ("message" in error) {
					toast(error.message);
				} else {
					toast("Der er sket en fejl.");
				}

				this.loading = false;
				return;
			}

			// No errors so set the state
			this.models = modelsResponse.value.data;
			this.custody = custodyResponse.value.data;
			this.shoppingList = shoppingListResponse.value.data;

			this.loading = false;

			if (this.custody) {
				this.modelId = this.custody.model_id;
				this.calcSum();
			}
		}
	}

	static styles = [
		sharedStyles,
		inputStyles,
		tableStyles,
		css`.security-input {
		border: 1px solid #ddd;
		width: 60px;
		/* padding: 2px;
		margin: 2px; */
		text-align: center;
		font-size: 12px;
		font-family: "Roboto";
		font-weight: 400;
	  }
	  .security-input[data-invalid] {
		border-color: #ef5350;
	  }

	  div.actions {
		display: flex;
		flex-grow: 0;
		flex-direction: row;
		gap: 0.5rem;
		justify-content: flex-end;
		margin-top: 0.5rem;
	  }

	  table tbody tr:last-child td {
		border-bottom: none;
	  }

	  s {
		font-weight: bold;
	  }

	  .unlock-shopping-list {
		display: flex;
		flex-direction: row;
		align-items: center;
	  }`,
	];

	render() {
		return html`
      <div>
        <h2>Modelportefølje</h2>

        ${
					this.loading
						? html`<p>Indlæser modelporteføljer...</p>`
						: html` ${this.renderModelPortfolioSelection()}
              ${this.renderModelPortfoliosDropdown()}

              <div>${this.renderShoppingList()}</div>

              <div class="actions">
                <fm-button-v2
                  @click="${this.cancelClick}"
                  ?disabled="${this.disableCancel}"
                  >Annuller</fm-button-v2
                >
                <fm-button-v2
                  ?disabled="${this.disableSave}"
                  @click="${this.onSLSave}"
                >
                  Skift modelportefølje
                </fm-button-v2>
              </div>`
				}
      </div>`;
	}

	renderModelPortfolioSelection() {
		if (!this.custody || this.custody.model_id === null) {
			return html`<p>Ingen modelportefølje tilknyttet</p>`;
		}

		if (this.modelId === this.custody.model_id) {
			return html`<p>Følger: ${this.custody.model_name}</p>`;
		}

		return html`<p>Følger: <s>${this.custody.model_name}</s></p>`;
	}

	renderModelPortfoliosDropdown() {
		return html`<label class="form-field">
      <select id="model" @change="${this.assignModel}">
        <option value="custom" ?selected="${!this.modelId}">
          Tilpasset modelportefølje
        </option>
        <option disabled>MODELPORTFØLJER</option>
        ${this.models.map(
					(m) =>
						html`
              <option value="${m.id}" ?selected="${
								m.id === this.custody?.model_id
							}">
                ${m.name}
              </option>
            `,
				)}
      </select>
    </label>`;
	}

	renderShoppingItem(item: ShoppingListItem, i: number) {
		return html`
      <tr>
        <td>${item.security_name}</td>
        <td>
          <input
            class="security-input"
            type="number"
            min="0"
            max="100"
            data-index="${i}"
            .value="${item.secshare ? item.secshare.toString() : ""}"
            ?disabled="${!this.unlockShoppingList}"
            @change="${this.onSLChange}"
          />
        </td>
        <td>${
					item.tax_check === "R" ? "Tvungen geninvestering af udbytter" : ""
				}</td>
      </tr>
    `;
	}

	renderShoppingList() {
		return html`
      <table>
        <thead>
          <tr>
            <th>Investeringsbevis</th>
            <th>Andel (%)</th>
            <th>Bemærkning</th>
          </tr>
        </thead>
        <tbody>
          ${this.shoppingList.map((item, i) =>
						this.renderShoppingItem(item, i),
					)}
          <tr>
            <td class="unlock-shopping-list">
              <input
                type="checkbox"
                id="unlock-shopping-list"
                .checked="${this.unlockShoppingList}"
                @click="${this.toggleUnlockShoppingList}"
              />
              <label for="unlock-shopping-list">Lås op</label>
            </td>
            <td><strong>${this.shareSum}%</strong></td>
            <td></td>
          </tr>
        </tbody>
      </table>
    `;
	}

	cancelClick() {
		this.dispatchEvent(new Event("cancel"));
	}

	toggleUnlockShoppingList() {
		this.unlockShoppingList = !this.unlockShoppingList;

		if (this.unlockShoppingList) {
			this.modelId = undefined;
			this.calcSum();
		}
	}

	onSLChange(event: Event) {
		const target = event.target as HTMLInputElement;
		const ixString = target.getAttribute("data-index");

		if (!ixString || Number.isNaN(Number.parseInt(ixString))) {
			return;
		}

		const ix = Number.parseInt(ixString);

		this.shoppingList[ix].secshare = Number.parseFloat(target.value);
		this.calcSum();
		this.requestUpdate();
	}

	calcSum() {
		let sharesum = 0;

		this.shoppingList.map((r) => {
			sharesum += Number.isNaN(r.secshare) ? 0 : r.secshare;
		});

		this.shareSum = sharesum;

		this.disableSave =
			this.shareSum !== 100 ||
			(this.modelId !== undefined && this.custody?.model_id === this.modelId);
	}

	async onSLSave(_event: Event) {
		if (this.shareSum === 100) {
			this.disableCancel = true;
			this.disableSave = true;

			const saveShoppingListResponse = await post<
				{ data: ShoppingListItem[]; model_id?: number },
				{ status: "OK" | "ERROR"; message: string }
			>(`/custodies/${this.custodyId}/shopping-list`, {
				data: this.shoppingList,
				...(this.modelId ? { model_id: this.modelId } : {}),
			});

			if (!saveShoppingListResponse.ok) {
				const error = await saveShoppingListResponse.error.json();

				if ("message" in error) {
					toast(error.message);
				} else {
					toast("Der er sket en fejl.");
				}
			} else {
				if (this.modelId) {
					toast("Modelporteføljen er ændret");
				} else {
					toast("Indkøbssedlen er gemt");
				}

				this.dispatchEvent(new Event("done"));
			}

			this.disableCancel = false;
			this.disableSave = false;
		} else {
			toast(
				`Indkøbssedlen er IKKE gemt da summen ${this.shareSum} skal være 100%`,
			);
		}
	}

	async assignModel(event: Event) {
		const modelString = (event.target as HTMLSelectElement)?.value;

		if (modelString === "custom") {
			this.unlockShoppingList = true;
			this.modelId = undefined;
		} else {
			this.unlockShoppingList = false;
			const modelId = Number.parseInt(modelString);

			const modelCurrentResponse = await get<{ data: ModelCurrent[] }>(
				`/models/${modelId}/current`,
			);

			if (!modelCurrentResponse.ok) {
				const error = await modelCurrentResponse.error.json();

				if ("message" in error) {
					toast(error.message);
				} else {
					toast("Der er sket en fejl.");
				}
				return;
			}

			this.modelId = Number.parseInt(modelString);

			const model = modelCurrentResponse.value.data;

			for (let i = 0; i < this.shoppingList.length; i++) {
				this.shoppingList[i].secshare = 0.0;
				for (let j = 0; j < model.length; j++) {
					if (this.shoppingList[i].security_id === model[j].security_id) {
						this.shoppingList[i].secshare = model[j].secshare;
					}
				}
			}
		}
		this.calcSum();
		this.requestUpdate();
	}
}
