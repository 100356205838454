import { LitElement, html, css, PropertyValues } from "lit";

import { styles as sharedStyles } from "../../styles/shared.js";
import { styles as inputStyles } from "../../styles/input.js";
import { customElement, property, state } from "lit/decorators.js";
import { get, post } from "../../api/client.js";
import { Custody, CustodyType, User } from "../../types/types.js";
import { toast } from "../../utils.js";
import { live } from "lit/directives/live.js";

declare global {
	interface HTMLElementTagNameMap {
		"create-custody-view": typeof CreateCustodyView;
	}
}

@customElement("create-custody-view")
export default class CreateCustodyView extends LitElement {
	@property({ type: Number })
	accessor userId: number | undefined;

	@state()
	secondaryOwnerUserId?: number;

	@state()
	modelId?: number;

	@state()
	custodyType?: string;

	@state()
	custodyName: string = "";

	@state()
	user?: User;

	@state()
	custodyTypes: CustodyType[] = [];

	static styles = [
		sharedStyles,
		inputStyles,
		css`#form {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 16px;
  }

  #form label {
    margin-bottom: 4px;
  }

  #form div.row {
    display: flex;
    flex-direction: row;
    gap: 16px;
  }

  #form div.row > div {
    width: 100%;
  }

  div.custody_name {
    display: flex;
    flex-direction: column;
  }

  div.custody_name input,
  select {
    padding: 8px;
    width: 100%;
    height: 32px;
    border: 1px solid #ddd;
  }

  div.overlay {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }`,
	];

	render() {
		return html`
      <div class="overlay">
        <h1>Opret ny investeringskonto</h1>
        <fm-form id="form">
          <div class="row">
            <div>
              <label>Ejer (person/virksomhed)</label>
              ${
								this.userId && this.user
									? html`<div>
                    <input
                      type="hidden"
                      name="owner_user1_id"
                      id="owner_user1_id"
                      .value="${live(this.userId).toString()}"
                    />
                    <strong>${this.user.name}</strong>
                  </div>`
									: html`<fm-autocomplete
                    name="owner_user1_id"
                    id="owner_user1_id"
                    url="/lov/owners"
                    display="name"
                    select="id"
                    @select="${this.onUserSelect}"
                    ></fm-autocomplete>`
							}
            </div>
            <div>
              <label>Evt. ejer 2 (person)</label>
              <fm-autocomplete
                name="owner_user2_id"
                id="owner_user2_id"
                url="/lov/persons"
                display="name"
                select="id"
                @select="${this.onSecondaryOwnerSelect}"
              >
              </fm-autocomplete>
            </div>
          </div>
          <div class="row">
            <div>
              <label>Type</label>
              <select
                name="custodytype_code"
                id="custodytype_code"
                @input="${this.onCustodyTypeSelect}"
              >
                <option></option>
                ${this.custodyTypes.map((custodyType) => {
									return html`<option value="${custodyType.code}">
                    ${custodyType.code} ${custodyType.name}
                  </option>`;
								})}
              </select>
            </div>
            <div>
              <label>Modelportefølje</label>
              <fm-autocomplete
                name="model_id"
                id="model_id"
                url="/lov/models"
                display="name"
                select="id"
                @select="${this.onModelSelect}"
              >
              </fm-autocomplete>
            </div>
          </div>
          <div class="row">
            <div class="custody_name">
              <label>Kontonavn</label>
              <input
                type="text"
                name="name"
                id="name"
                .value="${live(this.custodyName)}" @input="${(e: Event) => {
			this.custodyName = (e.target as HTMLInputElement).value;
		}}"
              />
            </div>
          </div>
          <div class="row">
            <div>
              <fm-button-v2
                class="button"
                type="button"
                @click="${this.save}"
                ?disabled="${!this.isFormValid()}"
              >
                Gem
              </fm-button-v2>
            </div>
          </div>
        </fm-form>
      </div>
    `;
	}

	async connectedCallback() {
		super.connectedCallback();

		const custodyTypesResponse = await get<{ data: CustodyType[] }>(
			"/lov/newcustodytypes",
		);

		if (!custodyTypesResponse.ok) {
			const error = await custodyTypesResponse.error.json();

			if ("message" in error) {
				toast(error.message);
			} else {
				toast("Der er sket en fejl.");
			}
			return;
		}

		this.custodyTypes = custodyTypesResponse.value.data;
	}

	async updated(changedProperties: PropertyValues<this>) {
		if (changedProperties.has("userId")) {
			if (this.userId) {
				this.user = await this.getUser(this.userId);
			} else {
				this.user = undefined;
			}
		}
	}

	async getUser(userId: number) {
		const getUserResponse = await get<{ data: User }>(`/users/${this.userId}`);

		if (!getUserResponse.ok) {
			const error = await getUserResponse.error.json();

			if ("message" in error) {
				toast(error.message);
			} else {
				toast("Der er sket en fejl.");
			}
			return undefined;
		}

		return getUserResponse.value.data;
	}

	isFormValid() {
		return this.userId && this.custodyType && this.custodyName !== "";
	}

	async onUserSelect(event: CustomEvent) {
		const userIdString = (event.target as HTMLInputElement)?.value;
		this.userId = parseInt(userIdString) || undefined;

		if (!this.userId) {
			return;
		}

		this.user = await this.getUser(this.userId);
	}

	onSecondaryOwnerSelect(event: CustomEvent) {
		const secondaryOwnerUserIdString = (event.target as HTMLInputElement)
			?.value;
		this.secondaryOwnerUserId =
			parseInt(secondaryOwnerUserIdString) || undefined;
	}

	onModelSelect(event: CustomEvent) {
		const modelIdString = (event.target as HTMLInputElement)?.value;
		this.modelId = parseInt(modelIdString) || undefined;
	}

	onCustodyTypeSelect(event: Event) {
		this.custodyType = (event.target as HTMLInputElement)?.value;
	}

	async save(event: Event) {
		event.preventDefault();

		if (!this.userId || !this.user || !this.custodyType) {
			toast("FEJL: Ingen bruger valgt");
			return;
		}

		const newCustody = {
			owner_user1_id: this.userId,
			name: this.custodyName,
			custodytype_code: this.custodyType,
			issuer: "Fundmarket",
			model_id: this.modelId,
			owner_user2_id: this.secondaryOwnerUserId,
		};

		const newCustodyResponse = await post<
			{
				owner_user1_id: number;
				name: string;
				custodytype_code: string;
				issuer: string;
				model_id?: number;
				owner_user2_id?: number;
			},
			{ data: Custody }
		>(`/accounts/${this.user.account_id}/custodies`, newCustody);

		if (!newCustodyResponse.ok) {
			const error = await newCustodyResponse.error.json();

			if ("message" in error) {
				toast(error.message);
			} else {
				toast("Der er sket en fejl.");
			}
			return;
		}

		toast("Kontoen oprettet");

		this.dispatchEvent(
			new CustomEvent("created", {
				detail: { custodyId: newCustodyResponse.value.data.id },
			}),
		);
	}
}
