import { LitElement, html, css } from "lit";
import { state } from "lit/decorators.js";

import store, { connect } from "../store/index.js";
import router from "../router/index.js";
import { setShowPII } from "../store/actions.js";
import { BASE_PATH as _BASE_PATH } from "../env.js";

const BASE_PATH = _BASE_PATH === "/" ? "" : _BASE_PATH;

export class PageChrome extends LitElement {
	@state()
	showPII = false;

	@state()
	headerContent = "";

	static styles = css` * {
    box-sizing: border-box;
  }

  :host {
    display: block;
  }

  aside {
    position: fixed;
    height: 100vh;
    width: 256px;
    border-right: 1px solid #ddd;
    background: white;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
  }

  aside .search {
    padding: 8px 8px;
  }

  .backdrop {
    display: none;
  }

  .menu-button {
    display: none;
  }

  .menu-button fm-icon {
    color: black;
  }

  nav {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 1;
  }

  main {
    padding-left: 256px;
  }

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px;
    width: 100%;
    background: var(--primary-color);
    height: 64px;
    color: white;
    font-size: 24px;
  }

  .notification-icon {
    color: white;
  }

  router-link {
    width: 100%;
    height: 48px;
    display: flex;
    align-items: center;
    text-decoration: none;
    font-weight: 500;
    color: #666;
    padding: 0 16px;
    transition: background-color 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  }

  router-link:hover {
    color: #333;
    background: rgba(0, 0, 0, 0.07);
    cursor: pointer;
  }

  router-link a {
    text-decoration: none;
    color: #666;
  }

  router-link[active],
  router-link[active] a,
  router-link a[active] {
    color: var(--primary-color);
    font-weight: bold;
  }

  router-link[disabled],
  router-link[disabled] a {
    color: #666;
  }

  .top-container {
    display: flex;
    align-items: center;
    padding: 0 24px;
    height: 64px;
  }

  .logo {
    width: 24px;
  }

  .logo-text {
    font-weight: 500;
    font-size: 20px;
    margin-left: 12px;
    margin-right: 24px;
  }

  .logo-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 8px;
  }

  .logged-in-user {
    text-align: left;
    padding: 16px 24px;
    margin: 0;
    color: #333;
    font-size: 16px;
    font-weight: 500;
  }

  nav ul {
    margin: 0px;
    padding: 0px;
    list-style-type: none;
  }

  nav ul li {
    margin: 0px;
    padding: 0px;
  }

  nav ul li router-link {
    margin: 0px;
    padding: 0px 40px;
    font-weight: normal;
    height: 40px;
  }

  nav.bottom {
    display: flex;
    justify-content: flex-end;
    margin-top: auto;
  }
  nav.bottom label {
    padding: 12px 12px;
    font-size: 12px;
  }

  nav.bottom input {
    width: 12px;
    height: 12px;
    margin-right: 6px;
  }

  @media (max-width: 1024px) {
    aside {
      z-index: 100;
      top: 0;
      bottom: 0;
      left: -300px;
      width: 300px;
      box-shadow: 3px 0 8px 3px rgba(0, 0, 0, 0.1);
      transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
      transition-property: transform;
      transition-duration: 0.3s;
    }

    main {
      padding: 0;
    }

    .backdrop {
      z-index: 100;
      display: block;
      position: fixed;
      visibility: hidden;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      opacity: 0;
      background: black;
      transition-property: opacity;
    }

    .menu-button {
      display: block;
      position: fixed;
      bottom: 0;
      left: 0;
      margin: 16px;
      height: 56px;
      width: 56px;
      padding: 12px;
      border-radius: 3px;
      cursor: pointer;
      background: white;
      border: 1px solid #ddd;
      box-shadow: 0 6px 8px rgba(0, 0, 0, 0.19),
        0 2px 2px rgba(0, 0, 0, 0.23);
    }

    :host([open]) aside {
      transform: translateX(300px);
    }

    :host([open]) .backdrop {
      visibility: visible;
      opacity: 0.3;
    }
  }

  @media print {
    main {
      padding-left: initial;
    }
    .menu-button {
      display: none;
    }
    .backdrop {
      display: none;
    }
    aside {
      display: none;
    }
  }`;

	render() {
		return html`
      <button class="menu-button" @click="${this.open}">
        <fm-icon>menu</fm-icon>
      </button>
      <div class="backdrop" @click="${this.close}"></div>
      <aside id="aside">
        <div class="top-container">
          <div class="logo-container">
            <img
              class="logo"
              src="${BASE_PATH}/static/logo.svg"
              alt="Fundmarket logo"
            />
          </div>
          <h1 class="logo-text">Fundmarket</h1>
        </div>
        <div class="search">
          <fm-autocomplete
            name="client_id"
            url="/lov/clients"
            display="name"
            select="id"
            @select="${this.onChange}"
            placeholder="Søg efter engagement"
          >
          </fm-autocomplete>
        </div>
        <nav>
          <router-link to="/customers" exact>
            <a ?active="${this.isCustomerRoute()}">Kunder</a>
          </router-link>
          <router-link to="/agreements"
            ><a>Kundeaftaler</a></router-link
          >
          <router-link to="/groups"><a>Kundegrupper</a></router-link>
          <router-link>Overblik</router-link>
          <ul>
            <li>
              <router-link to="/custodies"
                ><a>Investeringskonti</a></router-link
              >
            </li>
            <li>
              <router-link to="/orders"><a>Handelsordrer</a></router-link>
            </li>
          </ul>
          <router-link>Indstillinger</router-link>
          <ul>
            <li>
              <router-link to="/users"><a>Brugere</a></router-link>
            </li>
            <li>
              <router-link to="/models"><a>Modelporteføljer</a></router-link>
            </li>
          </ul>
        </nav>
        <nav class="bottom">
          <label for="showPII">
            <input
              type="checkbox"
              id="showPII"
              .checked="${this.showPII}"
              @click="${this.toggleShowPII}"
            />
            Vis CPR-numre</label
          >

          <router-link to="/login"><a>Log ud</a></router-link>
        </nav>
      </aside>
      <main>
        <header>${this.headerContent}</header>
        <slot></slot>
      </main>
    `;
	}

	constructor() {
		super();
		this.open = this.open.bind(this);
		this.close = this.close.bind(this);
	}

	disconnectedCallback() {
		if (this.hasAttribute("open")) {
			document.body.style.overflow = "";
		}
	}

	open() {
		this.setAttribute("open", "");
		document.body.style.overflow = "hidden";
	}

	close() {
		this.removeAttribute("open");
		document.body.style.overflow = "";
	}

	onChange(event: Event) {
		const clientId = (event.target as HTMLInputElement)?.value;

		if (clientId) {
			router.push(`/customers/${clientId}`);
		}
	}

	isCustomerRoute() {
		// Matches /customers and /customers/123 but not /customers/agreements
		const customerRoute = /^\/customers(?:\/\d+(?:\/.*)?)?\/?$/;

		return customerRoute.test(window.location.pathname);
	}

	toggleShowPII() {
		store.dispatch(setShowPII(!this.showPII));
	}
}

const ConnectedElement = connect(PageChrome, {
	selectors: (state) => ({
		headerContent: state.title,
		showPII: state.showPII,
	}),
}) as typeof PageChrome;

// TODO: Check typeof!
// customElements.define("pm-page-chrome", ConnectedElement as typeof PageChrome);
customElements.define("pm-page-chrome", ConnectedElement);

export default ConnectedElement;
