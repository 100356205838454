import { html, css, LitElement } from "lit";
import { customElement, property, state } from "lit/decorators.js";
import store from "../../store/index.js";
import { setTitle } from "../../store/actions.js";
import { styles as sharedStyles } from "../../styles/shared.js";
import { styles as inputStyles } from "../../styles/input.js";
import { styles as tableStyles } from "../../styles/tables.js";
import { get, post, put } from "../../api/client.js";
import "./upload-agreement-file-view.js";
import { toast } from "../../utils.js";
import {
	Agreement,
	AgreementDocument,
	AgreementDocumentFile,
	CustodiesCustody,
	Redemption,
	RedemptionMethod,
} from "../../types/types.js";
import { DateFormatter, date } from "../../formatting/dateformats.js";

type CustodyWithAdvisorFeeRedemption = CustodiesCustody & {
	advisorFeeRedemption?: Redemption;
};

declare global {
	interface HTMLElementTagNameMap {
		"agreement-view": AgreementView;
	}
}

@customElement("agreement-view")
export default class AgreementView extends LitElement {
	@property({ type: Number })
	agreementId: number | undefined;

	@state()
	agreement: Agreement | null = null;

	@state()
	documents: AgreementDocument[] = [];

	@state()
	custodies: CustodyWithAdvisorFeeRedemption[] = [];

	@state()
	redemptions: Redemption[] = [];

	@state()
	redemptionMethods: RedemptionMethod[] = [];

	@state()
	openUploadAgremeentFileDialog: boolean = false;

	@state()
	editAdvisorFeeCustodyId: string | null = null;

	@state()
	editAdvisorFeeRedemptionId: string | null = null;

	@state()
	createAdvisorFeeRedemption: boolean = false;

	async connectedCallback() {
		super.connectedCallback();
		store.dispatch(setTitle("Kundeaftaler"));

		if (this.agreementId == null) {
			return;
		}
		const getAgreementResponse = await get<{
			data: Agreement;
		}>(`/agreements/${this.agreementId}`);

		if (!getAgreementResponse.ok) {
			const error = await getAgreementResponse.error.json();

			if ("message" in error) {
				toast(error.message);
			} else {
				toast("Der er sket en fejl.");
			}
			return;
		}

		this.agreement = getAgreementResponse.value.data;

		const getDocumentsResponse = await get<{
			data: AgreementDocument[];
		}>(`/agreements/${this.agreementId}/documents`);

		if (!getDocumentsResponse.ok) {
			const error = await getDocumentsResponse.error.json();

			if ("message" in error) {
				toast(error.message);
			} else {
				toast("Der er sket en fejl.");
			}
			return;
		}

		this.documents = getDocumentsResponse.value.data;

		if (this.agreement.clientaccount_id) {
			const getRedemptionsResponse = await get<{
				data: Redemption[];
			}>(
				`/redemptions?account_id=${this.agreement.clientaccount_id}&subtrantype_id=1`,
			);

			if (!getRedemptionsResponse.ok) {
				const error = await getRedemptionsResponse.error.json();

				if ("message" in error) {
					toast(error.message);
				} else {
					toast("Der er sket en fejl.");
				}
				return;
			}

			this.redemptions = getRedemptionsResponse.value.data;

			const getCustodiesResponse = await get<{
				data: CustodiesCustody[];
			}>(`/custodies?query=&account_id=${this.agreement.clientaccount_id}`);

			if (!getCustodiesResponse.ok) {
				const error = await getCustodiesResponse.error.json();

				if ("message" in error) {
					toast(error.message);
				} else {
					toast("Der er sket en fejl.");
				}
				return;
			}

			this.custodies = getCustodiesResponse.value.data;
		}

		const getRedemptionMethodsResponse = await get<{
			data: RedemptionMethod[];
		}>("/lov/redemptionmethods");

		if (!getRedemptionMethodsResponse.ok) {
			const error = await getRedemptionMethodsResponse.error.json();

			if ("message" in error) {
				toast(error.message);
			} else {
				toast("Der er sket en fejl.");
			}
			return;
		}

		this.redemptionMethods = getRedemptionMethodsResponse.value.data;
	}

	static styles = [
		sharedStyles,
		inputStyles,
		tableStyles,
		css`.form-space {
    float: initial;
    margin: initial;
  }

  table.advisor-fee-redemption input[type="date"],
  table.advisor-fee-redemption input[type="number"] {
    font-size: 14px;
  }

  table.advisor-fee-redemption input[type="date"] {
    min-width: 125px;
    max-width: 125px;
  }

  table.advisor-fee-redemption input[type="number"] {
    min-width: 70px;
    max-width: 70px;
  }`,
	];

	render() {
		return html`
      <div class="card">
        <div class="card-header">
          <h1>Kundeaftale</h1>
        </div>
        <fm-form id="form" class="form-grid" url="/agreements/" method="put">
          <label class="form-field">
            Kunde
            <fm-autocomplete
              name="clientaccount_id"
              url="/lov/clientaccounts"
              .value="${{
								id: this.agreement?.clientaccount_id || "",
								name: this.agreement?.client_name || "",
							}}"
              display="name"
              select="id"
            >
            </fm-autocomplete>
          </label>
          <label class="form-field">
            Beskrivelse
            <input
              type="text"
              name="user_comment"
              value="${this.agreement?.user_comment || ""}"
            />
          </label>
          <fm-button-v2 class="btn btn--light" type="button" @click="${
						this.save
					}">
            Gem
          </fm-button-v2>
          <label class="form-field">
            Oprettet
            <input
              type="text"
              disabled
              readonly
              value="${this.agreement?.reg_date || ""}"
            />
          </label>
          <label class="form-field">
            Oprettet af
            <input
              type="text"
              disabled
              readonly
              value="${this.agreement?.reg_user || ""}"
            />
          </label>
          <label class="form-field">
            Ændret
            <input
              type="text"
              disabled
              readonly
              value="${this.agreement?.mod_date || ""}"
            />
          </label>
          <label class="form-field">
            Ændret af
            <input
              type="text"
              disabled
              readonly
              value="${this.agreement?.mod_user || ""}"
            />
          </label>
        </fm-form>
      </div>
      <div class="card">
        <div class="card-header">
          <h2>Aftaledokumenter</h2>
          <div class="actions">
            <fm-button-v2
              class="button-small"
              type="button"
              @click="${this.createClick}"
              >Upload aftaledokument</fm-button-v2
            >
          </div>
        </div>
        ${this.renderDocuments()}
      </div>
      <div class="card">
        <div class="card-header">
          <h2>Rådgivningshonorar</h2>
        </div>

		${this.renderAdvisorFeeRedemptions()}
      </div>
      <fm-dialog
        .opened="${this.openUploadAgremeentFileDialog}"
        @close="${() => {
					this.openUploadAgremeentFileDialog = false;
				}}"
      >
        ${
					this.openUploadAgremeentFileDialog
						? html`<upload-agreement-file-view
              agreementId="${this.agreementId}"
              @uploaded="${this.agreementFileUploaded}"
            ></upload-agreement-file-view>`
						: null
				}
      </fm-dialog>
    `;
	}

	renderDocuments() {
		return html`
      <table>
        <thead>
          <tr>
            <th>Navn</th>
            <th>Kommentar</th>
            <th>Fundmarket kommentar</th>
            <th>Uploaded</th>
            <th>Uploaded af</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          ${this.documents.map(
						(d) => html`
              <tr>
                <td>${d.filename}</td>
                <td>${d.user_comment}</td>
                <td>${d.adm_comment}</td>
                <td>${d.reg_date}</td>
                <td>${d.reg_user}</td>
                <td>
                  <fm-button-v2
                    class="btn btn--light button-small"
                    type="button"
                    data-idx="${d.id}"
                    @click="${this.download}"
                  >
                    Download
                  </fm-button-v2>
                </td>
              </tr>
            `,
					)}
        </tbody>
      </table>
    `;
	}

	renderAdvisorFeeRedemption(c: CustodyWithAdvisorFeeRedemption) {
		if (c.advisorFeeRedemption) {
			return html`
			<tr>
				<td>${c.custody_code}</td>
				<td>${c.owner_name}</td>
				<td>${c.name}</td>
				<td>${
					c.advisorFeeRedemption.firstdate
						? DateFormatter.format(
								new Date(c.advisorFeeRedemption.firstdate),
								"dd/mm/yyyy",
						  )
						: ""
				}</td>
								<td>${
									c.advisorFeeRedemption.lastdate !== null
										? DateFormatter.format(
												new Date(c.advisorFeeRedemption.lastdate),
												"dd/mm/yyyy",
										  )
										: ""
								}</td>
				<td>
				Hver
				${
					c.advisorFeeRedemption.frequency_months === 1
						? ""
						: `${c.advisorFeeRedemption.frequency_months}.`
				}
				måned
				</td>
				<td>
				${
					c.advisorFeeRedemption.frequency_days < 0
						? c.advisorFeeRedemption.frequency_days === -1
							? "Sidste bankdag"
							: `${Math.abs(
									c.advisorFeeRedemption.frequency_days,
							  )} før sidste bankdag`
						: c.advisorFeeRedemption.frequency_days === 1
						? "Første bankdag"
						: `${c.advisorFeeRedemption.frequency_days}. bankdag`
				}
				</td>
				<td>${c.advisorFeeRedemption.method_description}</td>
				<td>
				${
					c.advisorFeeRedemption.method_id === 1
						? `${c.advisorFeeRedemption.amount} kr.`
						: c.advisorFeeRedemption.method_id === 2
						? `${c.advisorFeeRedemption.amount}%`
						: c.advisorFeeRedemption.amount
				}
				</td>
				<td>
				<fm-button-v2
					class="btn button-xsmall"
					type="button"
					custody_id="${c.custody_id}"
					redemption_id="${
						c.advisorFeeRedemption ? c.advisorFeeRedemption.id : ""
					}"
					?disabled="${this.editAdvisorFeeCustodyId != null}"
					@click="${this.editAdvisorFeeRedemptionClick}"
				>
				Rediger
				</fm-button-v2>
				</td>
			</tr>`;
		} else {
			return html`
			<tr>
				<td>${c.custody_code}</td>
				<td>${c.owner_name}</td>
				<td>${c.name}</td>
				<td colspan="6"></td>
				<td>
				<fm-button-v2
				class="btn button-xsmall"
				type="button"
				custody_id="${c.custody_id}"
				redemption_id=""
				.disabled="${this.editAdvisorFeeCustodyId != null}"
				@click="${this.editAdvisorFeeRedemptionClick}"
				>
				Opret
				</fm-button-v2>
				</td>
			</tr>`;
		}
	}

	renderEditAdvisorFeeRedemption(c: CustodyWithAdvisorFeeRedemption) {
		if (c.advisorFeeRedemption) {
			const firstdate = c.advisorFeeRedemption.firstdate
				? date(new Date(c.advisorFeeRedemption.firstdate))
				: null;
			const lastdate = c.advisorFeeRedemption.lastdate
				? date(new Date(c.advisorFeeRedemption.lastdate))
				: null;
			let frequencyMonthsText = "";
			let frequencyDaysText = "";

			if (c.advisorFeeRedemption.frequency_months === 1) {
				frequencyMonthsText = "Hver måned";
			} else {
				frequencyMonthsText = `Hver ${c.advisorFeeRedemption.frequency_months}. måned`;
			}

			if (c.advisorFeeRedemption.frequency_days < 0) {
				if (c.advisorFeeRedemption.frequency_days === -1) {
					frequencyDaysText = "Sidste bankdag";
				} else {
					frequencyDaysText = `${Math.abs(
						c.advisorFeeRedemption.frequency_days,
					)} før sidste bankdag`;
				}
			} else {
				if (c.advisorFeeRedemption.frequency_days === 1) {
					frequencyDaysText = "Første bankdag";
				} else {
					frequencyDaysText = `${c.advisorFeeRedemption.frequency_days}. bankdag`;
				}
			}

			return html`
				<tr>
                    <td>${c.custody_code}</td>
                    <td>${c.owner_name}</td>
                    <td>${c.name}</td>
                    <td>
                      <div class="form-space">
                        <input
                          type="date"
                          name="firstdate"
                          value="${firstdate}"
                        />
                      </div>
                    </td>
                    <td>
                      <div class="form-space">
                        <input
                          type="date"
                          name="lastdate"
                          value="${lastdate}"
                        />
                      </div>
                    </td>
                    <td>
                      ${frequencyMonthsText}
                    </td>
                    <td>
                      ${frequencyDaysText}
                    </td>
                    <td>
                      <select name="method_id">
                        ${this.redemptionMethods.map(
													(rm) =>
														html`<option
                              value="${rm.id}"
                              ?selected="${
																rm.id === c.advisorFeeRedemption?.method_id
															}"
                            >
                              ${rm.description}
                            </option>`,
												)}
                      </select>
                    </td>
                    <td>
                      <div class="form-space">
                        <input
                          type="number"
                          name="amount"
                          value="${c.advisorFeeRedemption?.amount}"
                        />
                      </div>
                    </td>
                    <td>
                      <fm-button-v2
                        class="btn button-xsmall"
                        style="margin-bottom: 8px;"
                        type="button"
                        custody_id="${c.custody_id}"
                        redemption_id="${
													c.advisorFeeRedemption
														? c.advisorFeeRedemption.id
														: ""
												}"
                        @click="${this.saveAdvisorFeeRedemptionClick}"
                      >
                        Gem
                      </fm-button-v2>

                      <fm-button-v2
                        class="btn button-xsmall"
                        type="button"
                        custody_id="${c.custody_id}"
                        redemption_id="${
													c.advisorFeeRedemption
														? c.advisorFeeRedemption.id
														: ""
												}"
                        @click="${this.cancelEditAdvisorFeeRedemptionClick}"
                      >
                        Fortryd
                      </fm-button-v2>
                    </td>
                  </tr>`;
		} else {
			return html`
			<tr>
				<td>${c.custody_code}</td>
				<td>${c.owner_name}</td>
				<td>${c.name}</td>
				<td>
				<div class="form-space">
					<input type="date" name="firstdate" value="" />
				</div>
				</td>
				<td>
				<div class="form-space">
					<input type="date" name="lastdate" value="" />
				</div>
				</td>
				<td>Hver måned</td>
				<td>Sidste bankdag</td>
				<td>
				<select name="method_id">
					${this.redemptionMethods.map(
						(rm) =>
							html`<option
						value="${rm.id}"
						?selected="${rm.id === c.advisorFeeRedemption?.method_id}"
						>
						${rm.description}
						</option>`,
					)}
				</select>
				</td>
				<td>
				<div class="form-space">
					<input type="number" name="amount" value="" />
				</div>
				</td>
				<td>
				<fm-button-v2
					class="btn button-xsmall"
					style="margin-bottom: 8px;"
					type="button"
					custody_id="${c.custody_id}"
					@click="${this.saveAdvisorFeeRedemptionClick}"
				>
					Gem
				</fm-button-v2>

				<fm-button-v2
					class="btn button-xsmall"
					type="button"
					custody_id="${c.custody_id}"
					@click="${this.cancelEditAdvisorFeeRedemptionClick}"
				>
					Fortryd
				</fm-button-v2>
				</td>
			</tr>`;
		}
	}

	renderAdvisorFeeRedemptions() {
		const custodies = [...this.custodies];

		for (const custody of custodies) {
			const advisorFeeRedemption = this.redemptions.find(
				(r) => custody.custody_id === r.custody_id,
			);
			if (advisorFeeRedemption) {
				custody.advisorFeeRedemption = advisorFeeRedemption;
			}
		}

		return html`
      <fm-form id="advisor-fee-form">
        <table class="advisor-fee-redemption">
          <thead>
            <tr>
              <th>Kontonummer</th>
              <th>Navn</th>
              <th>Kontonavn</th>
              <th>Fra</th>
              <th>Til</th>
              <th>Frekvens</th>
              <th>Dag</th>
              <th>Metode</th>
              <th>Beløb</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
		  ${
				this.documents.length === 0
					? html`<tr><td colspan="10">Upload aftaledokument(er) for at administrere rådgivningshonorarer.</td></tr>`
					: custodies.map((c) =>
							c.custody_id.toString() !== this.editAdvisorFeeCustodyId
								? this.renderAdvisorFeeRedemption(c)
								: this.renderEditAdvisorFeeRedemption(c),
					  )
			}
          </tbody>
        </table>
      </fm-form>
    `;
	}

	async download(event) {
		event.preventDefault();
		const target = event.target;
		const id = target.getAttribute("data-idx");

		const getAgreementDocumentResponse = await get<{
			data: AgreementDocumentFile;
		}>(`/agreements/documents/${id}`);

		if (!getAgreementDocumentResponse.ok) {
			const error = await getAgreementDocumentResponse.error.json();

			if ("message" in error) {
				toast(error.message);
			} else {
				toast("Der er sket en fejl.");
			}
			return;
		}

		const data = getAgreementDocumentResponse.value.data;

		const a = document.createElement("a");
		a.href = data.content;
		a.download = data.filename;
		a.click();
	}

	async save(event) {
		event.preventDefault();

		const formdata = (
			this.shadowRoot?.getElementById("form") as HTMLFormElement
		)["value"];

		if (!formdata) {
			return;
		}

		const updateAgreementResponse = await put<
			{ clientaccount_id: number; user_comment: string; fee: number },
			{ data: Agreement }
		>(`/agreements/${this.agreementId}`, formdata);

		if (!updateAgreementResponse.ok) {
			const error = await updateAgreementResponse.error.json();

			if ("message" in error) {
				toast(error.message);
			} else {
				toast("Der er sket en fejl");
			}
			return;
		}

		this.agreement = updateAgreementResponse.value.data;

		toast("Gemt");
	}

	createClick() {
		this.openUploadAgremeentFileDialog = true;
	}

	async agreementFileUploaded(event) {
		this.openUploadAgremeentFileDialog = false;

		const getAgreementDocumentsResponse = await get<{
			data: AgreementDocument[];
		}>(`/agreements/${this.agreementId}/documents`);

		if (!getAgreementDocumentsResponse.ok) {
			const error = await getAgreementDocumentsResponse.error.json();

			if ("message" in error) {
				toast(error.message);
			} else {
				toast("Der er sket en fejl.");
			}
			return;
		}

		this.documents = getAgreementDocumentsResponse.value.data;
	}

	editAdvisorFeeRedemptionClick(event) {
		this.editAdvisorFeeCustodyId = event.target.getAttribute("custody_id");
		this.editAdvisorFeeRedemptionId =
			event.target.getAttribute("redemption_id") || null;
	}

	cancelEditAdvisorFeeRedemptionClick() {
		this.editAdvisorFeeCustodyId = null;
		this.editAdvisorFeeRedemptionId = null;
	}

	async saveAdvisorFeeRedemptionClick(event) {
		const custodyId = event.target.getAttribute("custody_id");
		const redemptionId = event.target.getAttribute("redemption_id");

		const formdata = (
			this.shadowRoot?.getElementById("advisor-fee-form") as HTMLFormElement
		)["value"];

		if (!formdata) {
			return;
		}

		const advisorFeeRedemption = {
			amount: formdata.amount,
			bank_account: "",
			bank_reg: "",
			custody_id: custodyId,
			firstdate: formdata.firstdate,
			frequency_days: -1,
			frequency_months: 1,
			id: redemptionId,
			lastdate: formdata.lastdate,
			method_id: formdata.method_id,
			subtrantype_id: 1, // ADVISOR FEE
		};

		if (redemptionId) {
			const updateRedemptionResponse = await put<
				typeof advisorFeeRedemption,
				{ data: Redemption }
			>(`/redemptions/${redemptionId}`, advisorFeeRedemption);

			if (!updateRedemptionResponse.ok) {
				const error = await updateRedemptionResponse.error.json();

				if ("message" in error) {
					toast(error.message);
				} else {
					toast("Der er sket en fejl");
				}
				return;
			}

			toast("Gemt");
		} else {
			const newRedemptionResponse = await post<
				typeof advisorFeeRedemption,
				{ data: Redemption }
			>("/redemptions", advisorFeeRedemption);

			if (!newRedemptionResponse.ok) {
				const error = await newRedemptionResponse.error.json();

				if ("message" in error) {
					toast(error.message);
				} else {
					toast("Der er sket en fejl");
				}
				return;
			}
			toast("Oprettet");
		}

		if (this.agreement?.clientaccount_id) {
			const getRedemptionsResponse = await get<{
				data: Redemption[];
			}>(
				`/redemptions?account_id=${this.agreement.clientaccount_id}&subtrantype_id=1`,
			);

			if (!getRedemptionsResponse.ok) {
				const error = await getRedemptionsResponse.error.json();

				if ("message" in error) {
					toast(error.message);
				} else {
					toast("Der er sket en fejl.");
				}
				return;
			}

			this.redemptions = getRedemptionsResponse.value.data;
		}

		this.cancelEditAdvisorFeeRedemptionClick();
	}
}
