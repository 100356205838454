const moneyFormatter = new Intl.NumberFormat("da-DK", {
	useGrouping: true,
	minimumFractionDigits: 2,
	maximumFractionDigits: 2,
});

export const money = (n: number | string) => {
	const parsedN = typeof n === "string" ? parseFloat(n) : n;
	return isNaN(parsedN) ? "" : moneyFormatter.format(parsedN);
};

const quantityFormatter = new Intl.NumberFormat("da-DK", {
	useGrouping: true,
	minimumFractionDigits: 6,
	maximumFractionDigits: 6,
});

export const quantity = (n: number | string) => {
	const parsedN = typeof n === "string" ? parseFloat(n) : n;
	return isNaN(parsedN) ? "" : quantityFormatter.format(parsedN);
};

const priceFormatter = new Intl.NumberFormat("da-DK", {
	useGrouping: true,
	minimumFractionDigits: 6,
	maximumFractionDigits: 6,
});

export const price = (n: number | string) => {
	const parsedN = typeof n === "string" ? parseFloat(n) : n;
	return isNaN(parsedN) ? "" : priceFormatter.format(parsedN);
};

export class NumberFormatter {
	/**
	 * Format a number.
	 * @param {number} value
	 * @param {number} minDecimals
	 * @param {number} [maxDecimals]
	 */
	static format(
		value: number,
		minDecimals: number,
		maxDecimals: number = minDecimals,
	) {
		if (value == null) {
			return "";
		} else {
			return new Intl.NumberFormat("da-dk", {
				useGrouping: true,
				minimumFractionDigits: minDecimals,
				maximumFractionDigits: maxDecimals,
			}).format(value);
		}
	}
}
