import { LitElement, html, css } from "lit";
import { customElement, state, property } from "lit/decorators.js";

import { styles as sharedStyles } from "../../../styles/shared.js";
import { styles as tableStyles } from "../../../styles/tables.js";
import { styles as inputStyles } from "../../../styles/input.js";

import { get, post } from "../../../api/client.js";
import { Client, User } from "../../../types/types.js";
import { toast } from "../../../utils.js";

declare global {
	interface HTMLElementTagNameMap {
		"create-person-view": typeof CreatePersonView;
	}
}

@customElement("create-person-view")
export default class CreatePersonView extends LitElement {
	@property({ type: Number })
	accessor clientId: number | undefined;

	@state()
	userId?: number;

	@state()
	newUser: {
		name: string;
		address1: string;
		address2: string;
		zipcity: string;
		phone: string;
		email: string;
		cpr: string;
		cvr: string;
		tin: string;
		country_id: string;
		country_name: string;
		citizen1_id: string;
		citizen2_id: string;
		account_id?: number;
		account_name?: string;
	} = {
		name: "",
		address1: "",
		address2: "",
		zipcity: "",
		phone: "",
		email: "",
		cpr: "",
		cvr: "",
		tin: "",
		country_id: "",
		country_name: "",
		citizen1_id: "",
		citizen2_id: "",
	};

	@state()
	client?: Client;

	async connectedCallback() {
		super.connectedCallback();

		if (this.clientId) {
			const clientResponse = await get<Client>(`/clients/${this.clientId}`);

			if (!clientResponse.ok) {
				const error = await clientResponse.error.json();

				if ("message" in error) {
					toast(error.message);
				} else {
					toast("Der er sket en fejl.");
				}

				return;
			}

			this.client = clientResponse.value;
			this.newUser.account_id = this.client.account_id;
		}
	}

	static styles = [
		sharedStyles,
		tableStyles,
		inputStyles,
		css`div.overlay {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }`,
	];

	render() {
		return html`
      <div class="overlay">
        <h1>Opret ny person</h1>
        <fm-form
      class="form-grid"
      id="editform"
      method="put"
      @submit="${this.saveUser}"
    >
      ${this.renderAccount()}
      
      <label class="form-field">
        Navn
        <input
          type="text"
          name="name"
          required
          value="${this.newUser.name || ""}"
          @input="${(e: Event) => {
						this.newUser.name = (e.target as HTMLInputElement).value;
					}}"
        />
      </label>
      <label class="form-field">
        CPR
        <input type="text" required name="cpr" value="${
					this.newUser.cpr || ""
				}"  @input="${(e: Event) => {
			this.newUser.cpr = (e.target as HTMLInputElement).value;
		}}" />
      </label>
      <label class="form-field">
        CVR
        <input type="text" name="cvr" value="${
					this.newUser.cvr || ""
				}"  @input="${(e: Event) => {
			this.newUser.cvr = (e.target as HTMLInputElement).value;
		}}" />
      </label>
      <label class="form-field">
        Adresse
        <input
          type="text"
          required
          name="address1"
          value="${this.newUser.address1 || ""}"
          @input="${(e: Event) => {
						this.newUser.address1 = (e.target as HTMLInputElement).value;
					}}"
        />
        <input
          type="text"
          required
          name="address2"
          value="${this.newUser.address2 || ""}"
          @input="${(e: Event) => {
						this.newUser.address2 = (e.target as HTMLInputElement).value;
					}}"
        />
      </label>
      <label class="form-field">
        Postnr. og by
        <input
          type="text"
          required
          name="zipcity"
          value="${this.newUser.zipcity || ""}"
          @input="${(e: Event) => {
						this.newUser.zipcity = (e.target as HTMLInputElement).value;
					}}"
        />
      </label>

      <label class="form-field">
        Bopælsland
        <fm-autocomplete
          name="country_id"
          url="/lov/countries"
          select="id"
          display="name"
          @select="${(e: Event) => {
						this.newUser.country_id = (e.target as HTMLInputElement).value;
					}}"
        >
        </fm-autocomplete>
      </label>
      <label class="form-field">
        TIN (hvis skattepligtig udenfor Danmark)
        <input type="text" name="tin" value="${
					this.newUser.tin || ""
				}" @input="${(e: Event) => {
			this.newUser.tin = (e.target as HTMLInputElement).value;
		}}" />
      </label>

      <label class="form-field">
        Statsborgerskab 1
        <fm-autocomplete
          name="citizen1_id"
          url="/lov/countries"
          select="id"
          display="name"
          @select="${(e: Event) => {
						this.newUser.citizen1_id = (e.target as HTMLInputElement).value;
					}}"
        >
        </fm-autocomplete>
      </label>
      <label class="form-field">
        Statsborgerskab 2
        <fm-autocomplete
          name="citizen2_id"
          url="/lov/countries"
          select="id"
          display="name"
          @select="${(e: Event) => {
						this.newUser.citizen2_id = (e.target as HTMLInputElement).value;
					}}"
        >
        </fm-autocomplete>
      </label>

      <label class="form-field">
        E-mail
        <input
          type="email"
          name="email"
          required
          value="${this.newUser.email || ""}"
          @input="${(e: Event) => {
						this.newUser.email = (e.target as HTMLInputElement).value;
					}}"
        />
      </label>
      <label class="form-field">
        Telefonnr.
        <input type="tel" name="phone" value="${
					this.newUser.phone || ""
				}" @input="${(e: Event) => {
			this.newUser.phone = (e.target as HTMLInputElement).value;
		}}" />
      </label>

      <fm-button-v2 class="btn btn--light" type="submit"> Gem </fm-button-v2>
    </fm-form>
      </div>
    `;
	}

	renderAccount() {
		return html` <label class="form-field">
      Engagement
      ${
				this.client?.account_id
					? html`<div>
            <input
              type="hidden"
              name="account_id"
              id="account"
              value="${this.client.account_id}"
            />
            <strong>${this.client.name}</strong>
          </div>`
					: html` <fm-autocomplete
            url="/lov/clientaccounts"
            name="account_id"
            required
            .rawValue="${{
							id: this.newUser?.account_id || "",
							name: this.newUser?.account_name || "",
						}}"
            select="id"
            display="name"
          >
          </fm-autocomplete>`
			}
    </label>`;
	}

	async saveUser(event: Event) {
		event.preventDefault();

		const newUserData = {
			name: this.newUser.name,
			address1: this.newUser.address1,
			address2: this.newUser.address2,
			zipcity: this.newUser.zipcity,
			phone: this.newUser.phone,
			email: this.newUser.email,
			cpr: this.newUser.cpr,
			cvr: this.newUser.cvr,
			tin: this.newUser.tin,
			country_id: this.newUser.country_id,
			citizen1_id: this.newUser.citizen1_id,
			citizen2_id: this.newUser.citizen2_id,
			account_id: this.newUser.account_id,
		};

		const newUserResponse = await post<typeof newUserData, { data: User }>(
			"/users",
			newUserData,
		);

		if (!newUserResponse.ok) {
			const error = await newUserResponse.error.json();

			if ("message" in error) {
				toast(error.message);
			} else {
				toast("Der er sket en fejl.");
			}

			return;
		}

		const newUser = newUserResponse.value.data;

		toast("Gemt");

		this.dispatchEvent(
			new CustomEvent("created", {
				detail: { clientId: newUser.client_id, userId: newUser.id },
			}),
		);
	}
}
