import { post } from "../../api/client.js";
import { styles as sharedStyles } from "../../styles/shared.js";
import { styles as inputStyles } from "../../styles/input.js";
import { styles as tableStyles } from "../../styles/tables.js";
import router from "../../router/index.js";
import { LitElement, html, css } from "lit";
import { customElement, state } from "lit/decorators.js";
import { toast } from "../../utils.js";

declare global {
	interface HTMLElementTagNameMap {
		"create-group-view": CreateGroupView;
	}
}

@customElement("create-group-view")
export default class CreateGroupView extends LitElement {
	@state()
	loading = false;

	@state()
	code = "";

	@state()
	name = "";

	static styles = [
		sharedStyles,
		inputStyles,
		tableStyles,
		css`#form {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 16px;
  }

  #form label {
    margin-bottom: 4px;
  }

  #form div.row {
    display: flex;
    flex-direction: row;
    gap: 16px;
  }

  #form div.row > div {
    width: 100%;
  }

  input[type="text"] {
    padding: 8px;
    width: 100%;
    height: 32px;
    border: 1px solid #ddd;
  }

  div.overlay {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }`,
	];

	render() {
		return html`
      <div class="overlay">
        <h1>Opret ny kundegruppe</h1>

        <fm-form
          id="form"
          url="/client-groups/"
          method="post"
          role="form"
          @response="${this.onSave}"
          @error="${this.onError}"
        >
          <div class="row">
            <div>
              <label for="code">Kode</label>
              <input
                type="text"
                name="code"
                id="code"
                .value="${this.code}"
                @input="${(e: InputEvent) => {
									this.code = (e.target as HTMLInputElement).value;
								}}"
              />
            </div>
            <div>
              <label for="name">Navn</label>
              <input
                type="text"
                name="name"
                id="name"
                .value="${this.name}"
                @input="${(e: Event) => {
									this.name = (e.target as HTMLInputElement).value;
								}}"
              />
            </div>
          </div>
          <div class="row">
            <div>
              <fm-button-v2
                class="button"
                type="button"
                @click="${this.onSave}"
                ?disabled="${this.loading || !this.isFormValid()}"
              >
                Gem
              </fm-button-v2>
            </div>
          </div>
        </fm-form>
      </div>
    `;
	}

	isFormValid() {
		return this.code.trim() !== "" && this.name.trim() !== "";
	}

	async onSave(event: CustomEvent) {
		event.preventDefault();

		if (!this.code || !this.name) {
			toast("Udfyld venligst alle felter.");
			return;
		}

		this.loading = true;

		type NewClientGroup = {
			code: string;
			name: string;
		};

		const newClientGroup: NewClientGroup = {
			code: this.code,
			name: this.name,
		};

		const addClientGroupResponse = await post<
			NewClientGroup,
			{
				id: number;
				message: "OK";
			}
		>("/client-groups/", newClientGroup);

		this.loading = false;

		if (!addClientGroupResponse.ok) {
			const error = await addClientGroupResponse.error.json();

			if ("message" in error) {
				toast(error.message);
			} else {
				toast("Der er sket en fejl.");
			}
			return;
		}

		router.push(`/groups/${addClientGroupResponse.value.id}`);
	}

	async onError(event: CustomEvent) {
		const error = event.detail;
		if (error?.json) {
			const response = await error.json();
			toast(response.message);
		} else {
			toast("Der er opstået en fejl.");
		}
	}
}
