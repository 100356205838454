import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import root from './reducers.js';
import connector from './connector.js';

const store = createStore(
  root,
  applyMiddleware(thunk)
);

export default store;
export const connect = connector(store);
