import { LitElement, html, css } from "lit";
import { customElement, property, state } from "lit/decorators.js";
import { live } from "lit/directives/live.js";
import { styles as sharedStyles } from "../../styles/shared.js";
import { styles as tableStyles } from "../../styles/tables.js";
import { styles as inputStyles } from "../../styles/input.js";

import { get } from "../../api/client.js";

import { date } from "../../formatting/dateformats.js";
import { money } from "../../formatting/numberformats.js";
import { ButtonElementV2 } from "../../components/fm-button-v2.js";
import { ClientGroupOverview } from "../../types/types.js";
import { toast } from "../../utils.js";

declare global {
	interface HTMLElementTagNameMap {
		"group-overview-report-view": GroupOverviewReportView;
	}
}

@customElement("group-overview-report-view")
export default class GroupOverviewReportView extends LitElement {
	@property({ type: Number })
	accessor groupId: number | undefined;

	@state()
	groupOverview?: ClientGroupOverview;

	@state()
	fromDate: string = "";

	@state()
	toDate: string = "";

	static styles = [
		sharedStyles,
		tableStyles,
		inputStyles,
		css`div.actions {
    display: flex;
    flex-grow: 0;
    flex-direction: row;
    margin-left: auto;
    align-items: flex-start;
  }

  div.card-header {
    align-items: flex-start;
  }

  div.card-header .info p {
    margin: 0px;
    color: #666;
  }

  fm-form {
    display: flex;
    align-items: flex-end;
    gap: 8px;
  }

  div.form-space {
    margin: 0px;
    min-width: initial;
  }

  div.form-space input[type="date"] {
    font-size: 12px;
    height: 28px;
  }
  div.form-space fm-button-v2.button-small {
    margin: 0px;
  }`,
	];

	render() {
		return html`
      <div class="card">
        <div class="card-header">
          <div class="info">
            <h1>Overblik</h1>
          </div>
          <div class="actions">
            <fm-form
              method="get"
              id="parms"
              class="reportform no-print"
              @submit="${this.generate}"
            >
              <div class="form-space">
                <label>Fra</label>
                <input type="date" id="fromdate" .value="${live(
									this.fromDate,
								)}" @input="${(e: InputEvent) => {
			this.fromDate = (e.target as HTMLInputElement).value;
		}}"/>
              </div>
              <div class="form-space">
                <label>Til</label>
                <input type="date" id="todate"  .value="${live(
									this.toDate,
								)}" @input="${(e: InputEvent) => {
			this.toDate = (e.target as HTMLInputElement).value;
		}}"/>
              </div>
              <div class="form-space">
                <fm-button-v2
                  id="submit_button"
                  type="submit"
                  class="button submit"
                  ?disabled="${!this.formValid()}"
                  >Vis</fm-button-v2
                >
              </div>
            </fm-form>
          </div>
        </div>
        ${
					this.groupOverview?.account_name
						? html`<div style="flex-direction: column; padding: 24px;">
              ${this.reportHeader()} ${this.reportTable()}
            </div>`
						: null
				}
      </div>
    `;
	}

	reportHeader() {
		if (this.groupOverview?.account_name) {
			return html`
        <h2>${this.groupOverview.account_name}</h2>
        <p>
          Porteføljeoverblik for perioden ${this.groupOverview.fromdate} til
          ${this.groupOverview.todate}
        </p>
      `;
		} else {
			return "";
		}
	}

	reportTable() {
		if (this.groupOverview?.overview) {
			return html`
        <div>
          <table>
            <thead>
              <th colspan="2">Nøgletal</th>
            </thead>
            <tbody>
              <tr>
                <td>Værdi ${this.groupOverview.fromdate}</td>
                <td class="numeric">
                  ${money(this.groupOverview.overview.primovalue)}
                </td>
              </tr>
              <tr>
                <td>Værdi ${this.groupOverview.todate}</td>
                <td class="numeric">
                  ${money(this.groupOverview.overview.ultimovalue)}
                </td>
              </tr>
              <tr>
                <td>Indbetalt</td>
                <td class="numeric">
                  ${money(this.groupOverview.overview.inflow)}
                </td>
              </tr>
              <tr>
                <td>Udbetalt</td>
                <td class="numeric">
                  ${money(this.groupOverview.overview.outflow)}
                </td>
              </tr>
              <tr>
                <td>Afkast i procent</td>
                <td class="numeric">
                  ${money(this.groupOverview.overview.return)}
                </td>
              </tr>
              <tr>
                <td>Rådgivningshonorar</td>
                <td class="numeric">
                  ${money(this.groupOverview.overview.advisorfee)}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      `;
		} else {
			return "";
		}
	}

	async connectedCallback() {
		super.connectedCallback();

		const now = new Date();
		now.setDate(now.getDate() - 20);
		const quarter = Math.floor(now.getMonth() / 3);

		const fromdate = new Date(now.getFullYear(), quarter * 3, 1);
		this.fromDate = date(fromdate);
		this.toDate = date(
			new Date(fromdate.getFullYear(), fromdate.getMonth() + 3, 0),
		);
	}

	async generate(event: Event) {
		const button = this.shadowRoot?.getElementById(
			"submit_button",
		) as ButtonElementV2;

		button.loading = true;

		this.groupOverview = undefined;

		if (this.groupId) {
			const getGroupOverviewResponse = await get<{
				data: ClientGroupOverview;
			}>(
				`/clients/${this.groupId}/overview?from=${this.fromDate}&to=${this.toDate}`,
			);

			if (!getGroupOverviewResponse.ok) {
				const error = await getGroupOverviewResponse.error.json();

				if ("message" in error) {
					toast(error.message);
				} else {
					toast("Der er sket en fejl.");
				}
				return;
			}

			this.groupOverview = getGroupOverviewResponse.value.data;
		}
		button.loading = false;
	}

	formValid() {
		return this.fromDate && this.toDate;
	}
}
