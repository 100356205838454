import { API_URL } from "../env.js";
import { Err, Ok, type Result } from "../result.js";

export const getToken = () => localStorage.getItem("APIToken");

export const saveToken = (token: string) =>
	localStorage.setItem("APIToken", token);

export const clearToken = () => localStorage.removeItem("APIToken");

export const get = async <T>(path: string): Promise<Result<T, Response>> => {
	const response = await fetch(`${API_URL}${path}`, {
		method: "GET",
		headers: {
			"content-type": "application/json",
			"fundmarket-token": getToken() ?? "",
		},
	});

	if (response.ok) {
		return Ok(await response.json());
	}

	return Err(response);
};

export const post = async <B, T>(
	path: string,
	body: B,
): Promise<Result<T, Response>> => {
	const response = await fetch(`${API_URL}${path}`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			"fundmarket-token": getToken() ?? "",
		},
		body: JSON.stringify(body),
	});

	if (response.ok) {
		return Ok(await response.json());
	}

	return Err(response);
};

export const put = async <B, T>(
	path: string,
	body: B,
): Promise<Result<T, Response>> => {
	const response = await fetch(`${API_URL}${path}`, {
		method: "PUT",
		headers: {
			"content-type": "application/json",
			"fundmarket-token": getToken() ?? "",
		},
		body: JSON.stringify(body),
	});

	if (response.ok) {
		return Ok(await response.json());
	}

	return Err(response);
};

export const del = async <B, T>(
	path: string,
	body: B,
): Promise<Result<T, Response>> => {
	const response = await fetch(`${API_URL}${path}`, {
		method: "DELETE",
		headers: {
			"content-type": "application/json",
			"fundmarket-token": getToken() ?? "",
		},
		body: JSON.stringify(body),
	});

	if (response.ok) {
		return Ok(await response.json());
	}

	return Err(response);
};
