import { LitElement, html, css } from "lit";
import { customElement, state, property } from "lit/decorators.js";

import { styles as sharedStyles } from "../../../styles/shared.js";
import { styles as tableStyles } from "../../../styles/tables.js";
import { styles as inputStyles } from "../../../styles/input.js";

import { NumberFormatter } from "../../../formatting/numberformats.js";
import { toast } from "../../../utils.js";
import { get, post } from "../../../api/client.js";
import {
	CustodyRebalance,
	CustodyRebalanceTarget,
} from "../../../types/types.js";
import { ButtonElementV2 } from "../../../components/fm-button-v2.js";

declare global {
	interface HTMLElementTagNameMap {
		"custody-rebalance-view": typeof CustodyRebalanceView;
	}
}

@customElement("custody-rebalance-view")
export default class CustodyRebalanceView extends LitElement {
	@property({ type: Number })
	accessor custodyId: number | undefined;

	@state()
	rebalance?: CustodyRebalance;

	@state()
	loading: boolean = false;

	@state()
	targets: CustodyRebalanceTarget[] = [];

	constructor() {
		super();
		this.targets = [];
		this.rebalance = {
			status: "ERROR",
			msg: "Not calculated",
		};
	}

	async connectedCallback() {
		super.connectedCallback();

		if (this.custodyId) {
			this.loading = true;

			const targetsResponse = await get<{ data: CustodyRebalanceTarget[] }>(
				`/custodies/${this.custodyId}/target`,
			);

			this.loading = false;

			if (!targetsResponse.ok) {
				const error = await targetsResponse.error.json();

				if ("message" in error) {
					toast(error.message);
				} else {
					toast("Der er sket en fejl.");
				}
				return;
			}

			this.targets = targetsResponse.value.data;
		}
	}

	static styles = [
		sharedStyles,
		inputStyles,
		tableStyles,
		css`th {
		white-space: nowrap;
	  }

	  tfoot tr td {
		border-bottom: none;
	  }

	  div.actions {
		display: flex;
		flex-grow: 0;
		flex-direction: row;
		gap: 0.5rem;
		justify-content: flex-end;
		margin-top: 0.5rem;
	  }`,
	];

	render() {
		return html`
      <div>
        <h2>Rebalancer</h2>

        ${
					this.loading
						? html`<p>Beregner rebalancering...</p>`
						: html` ${this.renderTarget()} ${this.renderRebalance()}`
				}
      </div>`;
	}

	formatDecimal(value: number, decimals: number) {
		return NumberFormatter.format(value, decimals);
	}

	renderTarget() {
		return html`
      <table>
        <thead>
          <tr>
            <th>Investeringsbevis</th>
            <th>Beholdning</th>
            <th>Værdi</th>
            <th>Andel (%)</th>
            <th>Ønsket andel (%)</th>
          </tr>
        </thead>
        <tbody>
          ${this.targets.map(
						(target, i) =>
							html`
                <tr>
                  <td>${target.security_name}</td>
                  <td class="numeric">
                    ${this.formatDecimal(target.quantity, 6) || ""}
                  </td>
                  <td class="numeric">
                    ${this.formatDecimal(target.marketvalue_qc, 2) || ""}
                  </td>
                  <td class="numeric">${this.formatDecimal(target.pct, 2) || ""}</td>
                  <td class="numeric">
                    ${this.formatDecimal(target.targetpct, 2) || ""}
                  </td>
                </tr>
              `,
					)}
        </tbody>
      </table>
      <div class="actions">
        <fm-button-v2 class="button" @click="${this.cancelClick}">
          Annuller
        </fm-button-v2>
        <fm-button-v2
          class="button"
          ?disabled="${this.rebalance?.status === "OK"}"
          @click="${this.onCalculate}"
          >Beregn handler</fm-button-v2>
      </div>
    `;
	}

	renderRebalance() {
		if (this.rebalance && this.rebalance.status === "OK") {
			return html`
        <h3>Handler</h3>
        <table>
          <thead>
            <tr>
              <th>Sælg</th>
              <th class="numeric">Antal styk</th>
              <th>Geninvestering</th>
            </tr>
          </thead>
          <tbody>
            ${this.rebalance.content?.map(
							(r) =>
								html`
                  <tr>
                    <td>${r.security_name}</td>
                    <td class="numeric">
                      ${this.formatDecimal(r.quantity, 6) || ""}
                    </td>
                    <td>${r.resecurity_name}</td>
                  </tr>
                `,
						)}
          </tbody>
          <tfoot>
            <tr>
              <td colspan="2"></td>
              <td>
                <fm-button-v2 class="button" @click="${this.onBook}"
                  >Bogfør</fm-button-v2
                >
              </td>
            </tr>
          </tfoot>
        </table>
      `;
		} else {
			return "";
		}
	}

	async onCalculate(event: Event) {
		const target = event.target as ButtonElementV2;
		target.loading = true;
		const rebalanceResponse = await get<{ data: CustodyRebalance }>(
			`/custodies/${this.custodyId}/rebalance`,
		);
		target.loading = false;

		if (!rebalanceResponse.ok) {
			const error = await rebalanceResponse.error.json();

			if ("message" in error) {
				toast(error.message);
			} else {
				toast("Der er sket en fejl.");
			}
			return;
		}

		this.rebalance = rebalanceResponse.value.data;
	}

	async onBook(event: Event) {
		if (!this.rebalance || !this.rebalance.id) {
			return;
		}

		const target = event.target as ButtonElementV2;
		target.loading = true;

		const rebalanceResponse = await post<
			{ rebalance_id: number },
			CustodyRebalance
		>(`/custodies/${this.custodyId}/rebalance`, {
			rebalance_id: this.rebalance.id,
		});

		target.loading = false;

		if (!rebalanceResponse.ok) {
			const error = await rebalanceResponse.error.json();

			if ("message" in error) {
				toast(error.message);
			} else {
				toast("Der er sket en fejl.");
			}

			return;
		} else if (rebalanceResponse.value.status === "ERROR") {
			toast(`FEJL: ${rebalanceResponse.value.msg}`);

			return;
		}

		toast("Rebalanceringen er gennemført");

		this.dispatchEvent(new Event("done"));
	}

	cancelClick() {
		this.dispatchEvent(new Event("cancel"));
	}
}
