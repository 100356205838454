import { LitElement, html } from "lit";
import { customElement } from "lit/decorators.js";

declare global {
	interface HTMLElementTagNameMap {
		"empty-view": typeof EmptyView;
	}
}

@customElement("empty-view")
export default class EmptyView extends LitElement {
	render() {
		return html`<slot></slot>`;
	}
}
