import { LitElement, html, css } from "lit";
import router from "../../router/index.js";
import { styles as sharedStyles } from "../../styles/shared.js";
import { styles as tableStyles } from "../../styles/tables.js";
import { styles as inputStyles } from "../../styles/input.js";
import { customElement, state } from "lit/decorators.js";

import store from "../../store/index.js";
import { setTitle } from "../../store/actions.js";
import { sort, toast } from "../../utils.js";
import "./create-agreement-view.js";
import type { ButtonElementV2 } from "../../components/fm-button-v2.js";
import type { Agreement } from "../../types/types.js";
import { get } from "../../api/client.js";
import { live } from "lit/directives/live.js";

declare global {
	interface HTMLElementTagNameMap {
		"agreements-view": AgreementsView;
	}
}

@customElement("agreements-view")
export default class AgreementsView extends LitElement {
	@state()
	agreements: Agreement[] = [];

	@state()
	openCreateAgreementDialog = false;

	@state()
	isLoading = false;

	@state()
	query = "";

	static styles = [
		sharedStyles,
		tableStyles,
		inputStyles,
		css`.card {
      padding: 12px;
    }

    #parms {
      display: flex;
      flex-direction: row;
      margin-bottom: 8px;
    }

    div.search {
      display: flex;
      flex-direction: column;
    }

    div.search div {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;
    }

    div.actions {
      display: flex;
      flex-grow: 0;
      flex-direction: row;
      margin-left: auto;
      align-items: flex-start;
    }`,
	];

	render() {
		return html`
      <div class="card">
        <fm-form
          id="parms"
          class="reportform no-print"
          @submit="${this.refresh}"
        >
          <div class="search">
            <div class="query">
              <input
                type="search"
                class="search-input-big"
                name="query"
                placeholder="Søg efter navn"
                .value="${live(this.query)}" @input="${(e: Event) => {
									this.query = (e.target as HTMLInputElement).value;
								}}"
              />
              <fm-button-v2
                id="submit_button"
                class="button submit"
                @click="${this.refresh}"
                .disabled="${this.isLoading}"
                >Søg</fm-button-v2
              >
            </div>
          </div>
          <div class="actions">
            <fm-button-v2
              class="button-small"
              type="button"
              @click="${this.createClick}"
              >Opret ny</fm-button-v2
            >
          </div>
        </fm-form>
        <table>
          <thead>
            <tr>
              <th data-key="client_name" @click="${this.sort}">Kunde</th>
              <th data-key="user_comment" @click="${this.sort}">Beskrivelse</th>
              <th data-key="reg_date" @click="${this.sort}">Oprettet</th>
            </tr>
          </thead>
          <tbody>
            ${
							this.isLoading
								? html`<tr>
                  <td colspan="3">Henter kundeaftaler...</td>
                </tr>`
								: this.agreements.map(
										(agreement) => html`
                    <tr>
                      <td>
                        <router-link to="/agreements/${agreement.id}">
                          <a>${agreement.client_name}</a>
                        </router-link>
                      </td>
                      <td>${agreement.user_comment}</td>
                      <td>${agreement.reg_date}</td>
                    </tr>
                  `,
									)
						}
          </tbody>
        </table>
      </div>
      <fm-dialog
        .opened="${this.openCreateAgreementDialog}"
        @close="${() => {
					this.openCreateAgreementDialog = false;
				}}"
      >
        ${
					this.openCreateAgreementDialog
						? html`<create-agreement-view
              @created="${this.agreementCreated}"
            ></create-agreement-view>`
						: null
				}
      </fm-dialog>
    `;
	}

	async connectedCallback() {
		super.connectedCallback();
		store.dispatch(setTitle("Kundeaftaler"));
	}

	createClick() {
		this.openCreateAgreementDialog = true;
	}

	agreementCreated(event: CustomEvent) {
		this.openCreateAgreementDialog = false;

		router.push(`/agreements/${event.detail.agreementId}`);
	}

	async refresh() {
		const button = this.shadowRoot?.getElementById(
			"submit_button",
		) as ButtonElementV2;

		button.loading = true;
		await this.search(this.query);
		button.loading = false;
	}

	async search(query: string) {
		this.isLoading = true;

		const getAgreementsResponse = await get<{
			data: Agreement[];
		}>(`/agreements?query=${query}`);

		if (!getAgreementsResponse.ok) {
			const error = await getAgreementsResponse.error.json();

			if ("message" in error) {
				toast(error.message);
			} else {
				toast("Der er sket en fejl.");
			}
			return;
		}

		this.agreements = getAgreementsResponse.value.data;
		this.isLoading = false;
	}

	sort(event: Event) {
		const key = (event.target as HTMLTableCellElement).getAttribute("data-key");

		if (!key) {
			return;
		}

		this.agreements = sort(this.agreements, key) as Agreement[];
		this.requestUpdate();
	}
}
