import { LitElement, html, css } from "lit";
import { customElement, state, property } from "lit/decorators.js";

import { styles as sharedStyles } from "../../../styles/shared.js";
import { styles as tableStyles } from "../../../styles/tables.js";
import { styles as inputStyles } from "../../../styles/input.js";

import { get, post } from "../../../api/client.js";

import { toast } from "../../../utils.js";
import { CustodyPosition, CustodyPositions } from "../../../types/types.js";

declare global {
	interface HTMLElementTagNameMap {
		"custody-sell-view": typeof CustodySellView;
	}
}

@customElement("custody-sell-view")
export default class CustodySellView extends LitElement {
	@property({ type: Number })
	accessor custodyId: number | undefined;

	@state()
	positions: CustodyPosition[] = [];

	@state()
	sellType: "amount" | "securities" = "amount";

	@state()
	loading: boolean = false;

	@state()
	executingSalesOrder: boolean = false;

	static styles = [
		sharedStyles,
		tableStyles,
		inputStyles,
		css`fm-button-v2 {
		text-align: center;
	  }

	  .card-block {
		border-bottom: none;
	  }

	  div.actions {
		display: flex;
		flex-grow: 0;
		flex-direction: row;
		gap: 0.5rem;
		justify-content: flex-end;
		margin-top: 0.5rem;
	  }

	  .numeric .quantity {
		cursor: copy;
	  }`,
	];

	render() {
		return html`
      <div>
        <h2>Sælg</h2>
        <label class="form-field">
          <select
            id="sell-type"
            @change="${(event: Event) => {
							this.sellType = (event.target as HTMLSelectElement).value as
								| "amount"
								| "securities";
						}}"
          >
            <option value="amount" ?selected="${this.sellType === "amount"}">
              Sælg fast beløb
            </option>
            <option
              value="securities"
              ?selected="${this.sellType === "securities"}"
            >
              Sælg investeringsbevis(er)
            </option>
          </select>
        </label>

        ${this.renderSalesOrderForm()}
      </div>
    `;
	}

	renderSalesOrderForm() {
		if (this.sellType === "amount") {
			return this.renderSellAmountForm();
		} else if (this.sellType === "securities") {
			return this.renderSellSecuritiesForm();
		}
	}

	renderSellAmountForm() {
		return html`<fm-form id="parms" @submit="${this.sellAmount}">
      <div class="form-field">
        <label>Beløb</label>
        <input type="number" name="amount" id="amount" min="0" />
      </div>
      <div class="actions">
        <fm-button-v2 class="button" @click="${this.closeClick}"> Luk </fm-button-v2>
        <fm-button-v2
          class="button"
          type="submit"
          id="submit_button"
          ?disabled="${this.executingSalesOrder}"
          >Sælg beløb</fm-button-v2
        >
      </div>
    </fm-form>`;
	}

	renderPosition(position: CustodyPosition, index: number) {
		return html`
      <tr>
        <td>${position.name}</td>
        <td class="numeric">
          <span data-index="${index}" class="quantity" @click="${this.qtyClick}"
            >${position.quantity}</span
          >
        </td>
        <td class="numeric">
          <input
            data-index="${index}"
            type="number"
			min="0"
            @change="${this.qtyChange}"
            .value="${position.sell.toString()}"
          />
        </td>
        <td>
          <fm-button-v2
            type="button"
            class="button-small"
            data-index="${index}"
            ?disabled="${this.executingSalesOrder}"
            @click="${this.sellQuantity}"
            >Sælg andele</fm-button-v2
          >
        </td>
      </tr>
    `;
	}

	renderSellSecuritiesForm() {
		return html`
      <div class="card-block">
        ${
					this.loading
						? html`<p>Indlæser beholdning...</p>`
						: html`
              <fm-form id="parms" @submit="${this.sellAmount}">
                <table>
                  <tr>
                    <th>Investeringsbevis</th>
                    <th class="numeric">Beholdning</th>
                    <th class="numeric">Sælg andele</th>
                    <th></th>
                  </tr>
                  ${(this.positions || []).map((position, index) =>
										this.renderPosition(position, index),
									)}
                </table>
                <div class="actions">
                  <fm-button-v2 class="button" @click="${this.closeClick}">
                    Luk
                  </fm-button-v2>
                  <fm-button-v2
                    type="button"
                    class="button"
                    ?disabled="${this.executingSalesOrder}"
                    @click="${this.sellAll}"
                    >Sælg alt</fm-button-v2
                  >
                </div>
              </fm-form>
            `
				}
      </div>
    `;
	}

	async connectedCallback() {
		super.connectedCallback();

		if (this.custodyId) {
			this.loading = true;
			const custodyPositionsResponse = await get<{ data: CustodyPositions }>(
				`/custodies/${this.custodyId}/positions`,
			);
			this.loading = false;

			if (!custodyPositionsResponse.ok) {
				const error = await custodyPositionsResponse.error.json();

				if ("message" in error) {
					toast(error.message);
				} else {
					toast("Der er sket en fejl.");
				}

				return;
			}

			this.positions = custodyPositionsResponse.value.data;
		}
	}

	qtyChange(event: Event) {
		const target = event.target as HTMLInputElement;
		const indexString = target.getAttribute("data-index");

		if (!indexString || Number.isNaN(Number.parseInt(indexString))) {
			return;
		}

		const index = Number.parseInt(indexString);

		this.positions[index].sell = Number.parseFloat(
			target.value.replace(",", "."),
		);
	}

	qtyClick(event: Event) {
		const target = event.target as HTMLInputElement;
		const indexString = target.getAttribute("data-index");

		if (!indexString || Number.isNaN(Number.parseInt(indexString))) {
			return;
		}

		const index = Number.parseInt(indexString);

		this.positions[index].sell = this.positions[index].quantity;
		this.requestUpdate();
	}

	async sellAmount(event: Event) {
		if (!this.custodyId) {
			toast("FEJL: Der er ikke valgt en investeringskonto");
			return;
		}

		const amountString = (
			this.shadowRoot?.getElementById("amount") as HTMLInputElement
		).value;

		if (!amountString) {
			toast("FEJL: Beløb mangler");
			return;
		}

		const amount = Number.parseFloat(amountString);

		if (!amount) {
			toast("FEJL: Ugyldigt beløb");
		}

		const sellMoneyOrder = {
			custody_id: this.custodyId,
			amount: amount,
		};

		this.executingSalesOrder = true;
		const sellMoneyOrderResponse = await post<
			typeof sellMoneyOrder,
			{ status: "OK" | "ERROR"; message: string }
		>("/orders/sellmoney", sellMoneyOrder);
		this.executingSalesOrder = false;

		if (!sellMoneyOrderResponse.ok) {
			const error = await sellMoneyOrderResponse.error.json();

			if ("message" in error) {
				toast(error.message);
			} else {
				toast("Der er sket en fejl.");
			}

			return;
		} else if (sellMoneyOrderResponse.value.status === "ERROR") {
			toast(`FEJL: ${sellMoneyOrderResponse.value.message}`);

			return;
		}

		toast(sellMoneyOrderResponse.value.message);
	}

	async sellQuantity(event: Event) {
		if (!this.custodyId) {
			toast("FEJL: Der er ikke valgt en investeringskonto");
			return;
		}

		const target = event.target as HTMLInputElement;
		const indexString = target.getAttribute("data-index");
		
		if (!indexString || Number.isNaN(Number.parseInt(indexString))) {
			return;
		}

		const index = Number.parseInt(indexString);

		if (!this.positions[index].security_id) {
			toast("FEJL: Investeringsbevis mangler");
			return;
		} else if (!this.positions[index].sell) {
			toast("FEJL: Antal styk mangler");
			return;
		}

		const quantity = this.positions[index].sell;

		if (!quantity) {
			toast("FEJL: Ugyldigt antal");
			return;
		}

		const sellOrder = {
			custody_id: this.custodyId,
			positions: [
				{
					security_id: this.positions[index].security_id,
					quantity: quantity,
				},
			],
		};

		this.executingSalesOrder = true;
		const sellOrderResponse = await post<
			typeof sellOrder,
			{ status: "OK" | "ERROR"; message: string }
		>("/orders/sell", sellOrder);
		this.executingSalesOrder = false;

		if (!sellOrderResponse.ok) {
			const error = await sellOrderResponse.error.json();

			if ("message" in error) {
				toast(error.message);
			} else {
				toast("Der er sket en fejl.");
			}

			return;
		} else if (sellOrderResponse.value.status === "ERROR") {
			toast(`FEJL: ${sellOrderResponse.value.message}`);

			return;
		}

		toast(sellOrderResponse.value.message);
	}

	async sellAll(event: Event) {
		if (!this.custodyId) {
			toast("FEJL: Der er ikke valgt en investeringskonto");
			return;
		}

		if (!confirm("Vil du sælge alle investeringsbeviser?")) {
			return;
		}

		const sellOrder: {
			custody_id: number;
			positions: { security_id: number; quantity: number }[];
		} = {
			custody_id: this.custodyId,
			positions: [],
		};

		for (const position of this.positions) {
			sellOrder.positions.push({
				security_id: position.security_id,
				quantity: position.quantity,
			});
		}

		this.executingSalesOrder = true;
		const sellOrderResponse = await post<
			typeof sellOrder,
			{ status: "OK" | "ERROR"; message: string }
		>("/orders/sell", sellOrder);
		this.executingSalesOrder = false;

		if (!sellOrderResponse.ok) {
			const error = await sellOrderResponse.error.json();

			if ("message" in error) {
				toast(error.message);
			} else {
				toast("Der er sket en fejl.");
			}

			return;
		} else if (sellOrderResponse.value.status === "ERROR") {
			toast(`FEJL: ${sellOrderResponse.value.message}`);

			return;
		}

		toast(sellOrderResponse.value.message);
	}

	closeClick() {
		this.dispatchEvent(new Event("done"));
	}
}
