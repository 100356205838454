import UsersView from "./users-view.js";
import UserView from "./user-view.js";
import { Snapshot } from "runway";

export default [
	{
		path: "",
		component: UsersView,
		properties: ({ query }: Snapshot) => ({
			query: query.get("query"),
		}),
	},
	{
		path: ":userId",
		component: UserView,
		properties: ({ parameters }: Snapshot) => {
			const userId = parameters.get("userId");

			return { userId: userId ? Number.parseInt(userId) : undefined };
		},
	},
];
