import { LitElement, html, css } from "lit";
import { customElement } from "lit/decorators.js";

declare global {
	interface HTMLElementTagNameMap {
		"not-found-view": NotFoundView;
	}
}

@customElement("not-found-view")
export default class NotFoundView extends LitElement {
	static styles = [
		css`:host {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    min-width: 100%;
  }

  h1 {
    font-weight: 500;
    margin: 0;
    padding: 16px 0;
  }`,
	];

	render() {
		return html`
      <h1>Siden blev ikke fundet</h1>
      <router-link>
        <a href="/customers">Tilbage til forsiden</a>
      </router-link>
    `;
	}
}
