import AgreementsView from "./agreements-view.js";
import AgreementView from "./agreement-view.js";
import type { Snapshot } from "runway";

export default [
	{
		path: "",
		component: AgreementsView,
	},
	{
		path: ":agreementId",
		component: AgreementView,
		properties: ({ parameters }: Snapshot) => {
			const agreementId = parameters.get("agreementId");

			return {
				agreementId: agreementId ? Number.parseInt(agreementId) : undefined,
			};
		},
	},
];
