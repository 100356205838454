import { LitElement, html } from "lit";
import api from "../api/index.js";

class AutocompleteElement extends LitElement {
	static get properties() {
		return {
			name: {
				type: String,
				reflect: true,
			},
			url: {
				type: String,
				reflect: true,
			},
			include: {
				type: String,
				reflect: true,
			},
			exclude: {
				type: String,
				reflect: true,
			},
			select: {
				type: String,
				reflect: true,
			},
			display: {
				type: String,
				reflect: true,
			},
			value: {
				type: String,
				reflect: true,
				converter: {
					toAttribute(val) {
						return String(val);
					},

					fromAttribute(val) {
						let retVal;
						try {
							retVal = JSON.parse(val);
						} catch (e) {
							retVal = val;
						}
						return retVal;
					},
				},
			},
			rawValue: {
				type: Object,
				reflect: true,
				converter: {
					toAttribute(val) {
						return String(val);
					},

					fromAttribute(val) {
						let retVal;
						try {
							retVal = JSON.parse(val);
						} catch (e) {
							retVal = val;
						}
						return retVal;
					},
				},
			},
			items: {
				type: Array,
			},
			placeholder: {
				type: String,
				reflect: true,
			},
		};
	}

	constructor() {
		super();
		this.select = null;
		this.display = null;
		this.value = {};
		this.items = [];
		this.include = [];
		this.exclude = [];
		this.rawValue = {};
		this.placeholder = "";
		this.onFocus = this.onFocus.bind(this);
		this.onBlur = this.onBlur.bind(this);
		this.onInput = this.onInput.bind(this);
		this.onKeyup = this.onKeyup.bind(this);
		this.onSelect = this.onSelect.bind(this);
	}

	render(obj) {
		const show =
			this.display && this.rawValue && this.display in this.rawValue
				? this.rawValue[this.display]
				: "";
		return html`
    <style>
      :host {
        display: block;
        position: relative;
      }

      * {
        box-sizing: border-box;
      }

      input {
        width: 100%;
        font-family: 'Roboto', sans-serif;
        font-size: 12px;
        padding: 8px;
        border: 1px solid #ddd;
      }

      #options {
        visibility: hidden;
        position: absolute;
        z-index: 100;
        top: 100%;
        display: block;
        width: 100%;
        max-height: 160px;
        overflow-y: auto;
        border: 1px solid #ddd;
      }

      :host([open]) #options {
        visibility: visible;
      }
    </style>
    <input id="input" type="text"
      value="${show}"
      placeholder="${this.placeholder}"
      @focus="${this.onFocus}"
      @blur="${this.onBlur}"
      @keyup="${this.onKeyup}"
      @input="${this.onInput}">
     <fm-options id="options">
    ${this.items.map(
			(item) => html`
      <fm-option
        value="${JSON.stringify(item)}"
        @select="${this.onSelect}">
        ${this.display ? item[this.display] : item}
      </fm-option>
    `,
		)}
    </fm-options>
    `;
	}

	firstUpdated() {
		this.options = this.shadowRoot.querySelector("fm-options");
	}

	set open(open) {
		if (open) {
			this.setAttribute("open", "");
		} else {
			this.removeAttribute("open");
		}
		this.setAttribute("aria-expanded", open);
	}

	get open() {
		return this.hasAttribute("open");
	}

	set value(value) {
		this.rawValue = value;
	}

	get value() {
		return this.select && this.rawValue && this.select in this.rawValue
			? this.rawValue[this.select]
			: this.rawValue;
	}

	_update(query) {
		clearTimeout(this._timeout);
		this._timeout = setTimeout(async () => {
			let url;

			if (this.url.includes("?")) {
				url = `${this.url}&query=${query}`;
			} else {
				url = `${this.url}?query=${query}`;
			}

			if (this.include.length > 0) {
				url += `&include=${this.include.join(",")}`;
			}

			if (this.exclude.length > 0) {
				url += `&exclude=${this.exclude.join(",")}`;
			}

			this.items = await api.get(url);

			if (this.items.length === 1) {
				this.value = this.items[0];
			}
		}, 200);
	}

	onFocus() {
		this.open = true;
	}

	onBlur() {
		this.open = false;
	}

	onKeyup(event) {
		switch (event.key) {
			case "ArrowDown": {
				this.options.select(this.options.next());
				break;
			}
			case "ArrowUp": {
				this.options.select(this.options.previous());
				break;
			}
			case "Enter": {
				if (this.open) {
					event.stopPropagation();
					this.rawValue = this.options.value;
					const show =
						this.display && this.rawValue && this.display in this.rawValue
							? this.rawValue[this.display]
							: this.rawValue;
					const i = this.shadowRoot.querySelector("#input");
					i.value = show;
					this.dispatchEvent(new Event("select"));
				}
				// No break
			}
			case "Escape": {
				this.open = false;
			}
		}
	}

	onInput(event) {
		this._update(event.target.value);
	}

	onSelect(event) {
		this.rawValue = event.target.value;

		const show =
			this.display && this.rawValue && this.display in this.rawValue
				? this.rawValue[this.display]
				: this.rawValue;

		const i = this.shadowRoot.querySelector("#input");
		i.value = show;

		this.open = false;
		this.dispatchEvent(new Event("select"));
	}
}

customElements.define("fm-autocomplete", AutocompleteElement);
