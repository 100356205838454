import GroupsContainerView from "./groups-container-view.js";
import GroupsView from "./groups-view.js";
import GroupView from "./group-view.js";
import GroupOverviewReportView from "./group-overview-report-view.js";
import GroupTransactionsReportView from "./group-transactions-report-view.js";
import type { Snapshot } from "runway";

export default [
	{
		path: "",
		component: GroupsView,
	},
	{
		path: ":groupId",
		exact: true,
		component: GroupsContainerView,
		children: [
			{
				path: "",
				component: GroupView,
				properties: ({ parameters }: Snapshot) => {
					const groupId = parameters.get("groupId");

					return {
						groupId: groupId ? Number.parseInt(groupId) : undefined,
					};
				},
			},
		],
		properties: ({ parameters }: Snapshot) => {
			const groupId = parameters.get("groupId");

			return {
				groupId: groupId ? Number.parseInt(groupId) : undefined,
			};
		},
	},
	{
		path: ":groupId/overview",
		exact: true,
		component: GroupsContainerView,
		children: [
			{
				path: "",
				component: GroupOverviewReportView,
				properties: ({ parameters }: Snapshot) => {
					const groupId = parameters.get("groupId");

					return {
						groupId: groupId ? Number.parseInt(groupId) : undefined,
					};
				},
			},
		],
		properties: ({ parameters }: Snapshot) => {
			const groupId = parameters.get("groupId");

			return {
				groupId: groupId ? Number.parseInt(groupId) : undefined,
			};
		},
	},
	{
		path: ":groupId/transactions",
		exact: true,
		component: GroupsContainerView,
		children: [
			{
				path: "",
				component: GroupTransactionsReportView,
				properties: ({ parameters }: Snapshot) => {
					const groupId = parameters.get("groupId");

					return {
						groupId: groupId ? Number.parseInt(groupId) : undefined,
					};
				},
			},
		],
		properties: ({ parameters }: Snapshot) => {
			const groupId = parameters.get("groupId");

			return {
				groupId: groupId ? Number.parseInt(groupId) : undefined,
			};
		},
	},
];
