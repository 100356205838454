import { LitElement, PropertyValues, css, html } from "lit";
import { customElement, property, state } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";

declare global {
	interface HTMLElementTagNameMap {
		"fm-button-v2": ButtonElementV2;
	}
}

@customElement("fm-button-v2")
export class ButtonElementV2 extends LitElement {
	static styles = css`
        :host {
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            min-width: 100px;
            border: none;
            border-radius: 2px;
            font-family: "Roboto";
            font-size: 14px;
            font-weight: 500;
            text-transform: uppercase;
            padding: 12px 16px;
            transition-duration: 0.3s;
            transition-property: background-color, box-shadow;
            transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
            background-color: var(--primary-color);
            color: #fff;
            white-space: nowrap;
            box-shadow:
                0 2px 2px 0 rgba(0, 0, 0, 0.14),
                0 1px 5px 0 rgba(0, 0, 0, 0.12),
                0 3px 1px -2px rgba(0, 0, 0, 0.2);
        }

        :host[hidden] {
            display: none;
        }

        :host([light]) {
            background: none;
            color: #333;
            box-shadow: none;
        }

		:host([disabled]) {
            background: #ddd;
            color: #666;
            box-shadow: none;
            cursor: default;
        }

		:host([light][disabled]) {
			background: #eeeeee40;
		}

		:host(:not([disabled]):hover),
		:host(:not([disabled]):focus) {
            box-shadow:
                0 4px 5px 0 rgba(0, 0, 0, 0.14),
                0 1px 10px 0 rgba(0, 0, 0, 0.12),
                0 2px 4px -1px rgba(0, 0, 0, 0.4);
        }

        :host([light]:not([disabled]):hover),
		:host([light]:not([disabled]):focus) {
            background: #f2f2f2;
            color: #111;
			box-shadow: none;
        }

		@keyframes spin {
        from {
          transform: rotate(0);
        }
        to {
          transform: rotate(360deg);
        }
      }
      
    .spinner {
      height: 6px;
      width: 6px;
      border-radius: 50%;
      border: 3px solid transparent;
      border-top-color: white;
      margin: 0 6px;
      animation: spin 1s infinite linear
    }
    `;

	@property({ reflect: true })
	accessor role: string = "button";

	@property({ reflect: true })
	accessor tabindex: string | null = "0";

	@property({ type: Boolean, reflect: true })
	accessor disabled: boolean = false;

	@property({ type: Boolean, reflect: true })
	accessor light: boolean = false;

	@property({ type: Boolean })
	accessor loading: boolean = false;

	@state()
	focused: boolean = false;

	constructor() {
		super();
		this.addEventListener("click", this.onClick);
	}

	connectedCallback(): void {
		super.connectedCallback();
	}

	disconnectedCallback(): void {
		this.removeEventListener("click", this.onClick);
		super.disconnectedCallback();
	}

	render() {
		return html`<slot></slot><span id="after" class="${classMap({
			spinner: this.loading,
		})}"></span>`;
	}

	protected willUpdate(changedProperties: PropertyValues<this>): void {
		if (changedProperties.has("disabled")) {
			this.setAttribute("aria-disabled", String(this.disabled));
			if (this.disabled) {
				this.blur();
				this.tabindex = null;
			} else {
				this.tabindex = "0";
			}
		}
	}

	private onClick(event: MouseEvent) {
		if (this.disabled) {
			event.stopImmediatePropagation();
		}
	}

	focus() {
		if (this.disabled) {
			return;
		}

		this.focused = true;
		this.dispatchEvent(new FocusEvent("focus"));
		this.dispatchEvent(
			new FocusEvent("focusin", {
				bubbles: true,
			}),
		);
	}

	blur() {
		if (this.disabled) {
			return;
		}

		this.focused = false;
		this.dispatchEvent(new FocusEvent("blur"));
		this.dispatchEvent(
			new FocusEvent("focusout", {
				bubbles: true,
			}),
		);
	}
}
