import { LitElement, html, css } from "lit";
import { customElement, state } from "lit/decorators.js";

import { styles as sharedStyles } from "../../styles/shared.js";
import { styles as inputStyles } from "../../styles/input.js";
import { toast } from "../../utils.js";
import type { AMUser } from "../../types/types.js";
import { post } from "../../api/client.js";

declare global {
	interface HTMLElementTagNameMap {
		"create-user-view": typeof CreateUserView;
	}
}

@customElement("create-user-view")
export default class CreateUserView extends LitElement {
	@state()
	user: {
		name: string;
		email: string;
		phone: string;
		username: string;
		password: string;
		twophase: string | null;
	} = {
		name: "",
		email: "",
		phone: "",
		username: "",
		password: "",
		twophase: null,
	};

	static styles = [
		sharedStyles,
		inputStyles,
		css`#form {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 16px;
  }

  #form label {
    margin-bottom: 4px;
  }

  #form div.row {
    display: flex;
    flex-direction: row;
    gap: 16px;
  }

  #form div.row > div {
    width: 100%;
  }

  input[type="text"] {
    padding: 8px;
    width: 100%;
    height: 32px;
    border: 1px solid #ddd;
  }

  div.overlay {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }`,
	];

	render() {
		return html`
      <div class="overlay">
        <h1>Opret ny bruger</h1>
        <fm-form id="info" class="form-grid" @submit="${this.save}">
          <div class="form-field">
            <label for="name">
              Navn
              <input type="text" name="name" id="name" required .value="${
								this.user.name
							}" @input="${(e: InputEvent) => {
								this.user.name = (e.target as HTMLInputElement).value;
							}}" />
            </label>
          </div>
          <div class="form-field">
            <label for="email">
              E-mail
              <input type="text" name="email" id="email" required .value="${
								this.user.email
							}" @input="${(e: InputEvent) => {
								this.user.email = (e.target as HTMLInputElement).value;
							}}" />
            </label>
          </div>
          <div class="form-field">
            <label for="phone">
              Telefonnr. (til SMS kode)
              <input type="text" name="phone" id="phone" required .value="${
								this.user.name
							}" @input="${(e: InputEvent) => {
								this.user.phone = (e.target as HTMLInputElement).value;
							}}" />
            </label>
          </div>
          <div class="form-field">
            <label for="username">
              Brugernavn
              <input type="text" name="username" id="username" .value="${
								this.user.username
							}" @input="${(e: InputEvent) => {
								this.user.username = (e.target as HTMLInputElement).value;
							}}" />
            </label>
          </div>
          <div class="form-field">
            <label for="password">
              Password
              <input type="password" name="password" id="password" .value="${
								this.user.name
							}" @input="${(e: Event) => {
								this.user.password = (e.target as HTMLInputElement).value;
							}}" />
            </label>
          </div>
          <div class="form-field">
            <label for="twophase">
              Brug SMS code
              <input type="checkbox" name="twophase" id="twophase" value="S" value="S" ?checked="${
								this.user.twophase === "S"
							}" @input="${(e: Event) => {
								this.user.twophase = (e.target as HTMLInputElement).checked
									? "S"
									: null;
							}}"  />
            </label>
          </div>
          <div class="form-field">
            <fm-button-v2 type="submit" class="btn btn--light">Gem</fm-button-v2>
          </div>
        </fm-form>
      </div>
    `;
	}

	async save(event: Event) {
		event.preventDefault();

		const createUserResponse = await post<typeof this.user, { data: AMUser }>(
			"/amusers",
			this.user,
		);

		if (!createUserResponse.ok) {
			const error = await createUserResponse.error.json();

			if ("message" in error) {
				toast(error.message);
			} else {
				toast("Der er sket en fejl.");
			}
			return;
		}

		toast("Bruger oprettet");

		this.dispatchEvent(new CustomEvent("created"));
	}
}
