import { LitElement, html, css } from "lit";
import { state, property, customElement } from "lit/decorators.js";

import { styles as sharedStyles } from "../../../styles/shared.js";
import { styles as tableStyles } from "../../../styles/tables.js";
import { styles as inputStyles } from "../../../styles/input.js";

import { date } from "../../../formatting/dateformats.js";
import { quantity, money } from "../../../formatting/numberformats.js";
import {
	ReportPositionCustody,
	ReportPositionCustodyPosition,
} from "../../../types/types.js";
import { get } from "../../../api/client.js";
import { toast } from "../../../utils.js";

declare global {
	interface HTMLElementTagNameMap {
		"customer-positions-report-view": typeof CustomerPositionsReportView;
	}
}

@customElement("customer-positions-report-view")
export default class CustomerPositionsReportView extends LitElement {
	@property({ type: Number })
	accessor clientId: number | undefined;

	@state()
	reportPositionCustodies: ReportPositionCustody[] = [];

	@state()
	loading: boolean = false;

	@state()
	fromDate: string = date(new Date(Date.now() - 30 * 24 * 60 * 60 * 1000));

	@state()
	toDate: string = date(new Date());

	@state()
	sum_marketvalue_pc = 0;

	@state()
	sum_buysell_pc = 0;

	@state()
	sum_pl_pc = 0;

	@state()
	sum_intdiv_pc = 0;

	@state()
	sum_tax_pc = 0;

	@state()
	sum_return_pc = 0;

	static styles = [
		sharedStyles,
		tableStyles,
		inputStyles,
		css` div.actions {
    display: flex;
    flex-grow: 0;
    flex-direction: row;
    margin-left: auto;
    align-items: flex-start;
  }

  div.card-header {
    align-items: flex-start;
  }

  div.card-header .info p {
    margin: 0px;
    color: #666;
  }

  fm-form {
    display: flex;
    align-items: flex-end;
    gap: 8px;
  }

  div.form-space {
    margin: 0px;
    min-width: initial;
  }

  div.form-space input[type="date"] {
    font-size: 12px;
    height: 28px;
  }
  div.form-space fm-button-v2.button-small {
    margin: 0px;
  }

  tfoot td {
    font-weight: bold;
  }`,
	];

	render() {
		return html`
      <div class="card">
        <div class="card-header">
          <div class="info">
            <h1>Afkast</h1>
          </div>
          <div class="actions">${this.paramForm()}</div>
        </div>
        ${this.reportPositionCustodies.map((c) => this.renderCustody(c))}
      </div>
    `;
	}

	paramForm() {
		return html` <fm-form
      method="get"
      id="parms"
      class="reportform no-print"
      @submit="${this.refresh}"
    >
      <div class="form-space">
        <label>Fra</label>
        <input type="date" name="from" value="${
					this.fromDate || ""
				}" @input="${(e: InputEvent) => {
			this.fromDate = (e.target as HTMLInputElement).value;
		}}" />
      </div>
      <div class="form-space">
        <label>Til</label>
        <input type="date" name="to" value="${this.toDate || ""}" @input="${(
			e: Event,
		) => {
			this.toDate = (e.target as HTMLInputElement).value;
		}}" />
      </div>
      <div class="form-space">
        <fm-button-v2 id="submit_button" type="submit" class="button submit" ?disabled="${!this.formValid()}" .loading="${
			this.loading
		}"
          >Vis</fm-button-v2
        >
      </div>
    </fm-form>`;
	}

	renderCustody(c: ReportPositionCustody) {
		this.resetSum();
		if (c.positions.length > 0) {
			return html`
        <div class="card-header">
          <h3>${c.custody_code} ${c.name} ${c.custodytype}</h3>
        </div>
        <table>
          <thead>
            <tr>
              <th>ISIN</th>
              <th>Navn</th>
              <th class="numeric">Antal stk.</th>
              <th class="numeric">Værdi</th>
              <th class="numeric">Omsætning</th>
              <th class="numeric">Kursavance</th>
              <th class="numeric">Udbytte</th>
              <th class="numeric">Udbytteskat</th>
              <th class="numeric">Afkast</th>
              <th class="numeric">Afkast i %</th>
            </tr>
          </thead>
          <tbody>
            ${(c.positions || []).map((p) => this.renderPosition(p))}
          </tbody>
          <tfoot>
            ${this.renderSum()}
          </tfoot>
        </table>
      `;
		} else {
			return "";
		}
	}

	renderPosition(s: ReportPositionCustodyPosition) {
		this.addSum(s);
		return html`
      <tr>
        <td>${s.security}</td>
        <td>${s.security_name}</td>
        <td class="numeric">${quantity(s.quantity)}</td>
        <td class="numeric">${money(s.marketvalue_pc)}</td>
        <td class="numeric">${money(s.buysell_pc)}</td>
        <td class="numeric">${money(s.pl_pc)}</td>
        <td class="numeric">${money(s.intdiv_pc)}</td>
        <td class="numeric">${money(s.tax_pc)}</td>
        <td class="numeric">${money(s.return_pc)}</td>
        <td class="numeric">${money(s.twr)}</td>
      </tr>
    `;
	}

	resetSum() {
		this.sum_marketvalue_pc = 0;
		this.sum_buysell_pc = 0;
		this.sum_pl_pc = 0;
		this.sum_intdiv_pc = 0;
		this.sum_tax_pc = 0;
		this.sum_return_pc = 0;
	}

	addSum(s: ReportPositionCustodyPosition) {
		this.sum_marketvalue_pc += s.marketvalue_pc;
		this.sum_buysell_pc += s.buysell_pc;
		this.sum_pl_pc += s.pl_pc;
		this.sum_intdiv_pc += s.intdiv_pc;
		this.sum_tax_pc += s.tax_pc;
		this.sum_return_pc += s.return_pc;
	}

	renderSum() {
		return html`
   <tr>
     <td colspan="2">I alt</td>
     <td class="numeric"></td>
     <td class="numeric">${money(this.sum_marketvalue_pc)}</td>
     <td class="numeric">${money(this.sum_buysell_pc)}</td>
     <td class="numeric">${money(this.sum_pl_pc)}</td>
     <td class="numeric">${money(this.sum_intdiv_pc)}</td>
     <td class="numeric">${money(this.sum_tax_pc)}</td>
     <td class="numeric">${money(this.sum_return_pc)}</td>
     <td></td> </td>
     <td class="numeric"></td>
   </tr>
   `;
	}

	async refresh(event: Event) {
		this.loading = true;

		const reportPositionsResponse = await get<{
			data: ReportPositionCustody[];
		}>(
			`/reports/positions?from=${this.fromDate}&to=${this.toDate}&client_id=${this.clientId}`,
		);
		this.loading = false;

		if (!reportPositionsResponse.ok) {
			const error = await reportPositionsResponse.error.json();

			if ("message" in error) {
				toast(error.message);
			} else {
				toast("Der er sket en fejl.");
			}

			return;
		}
		this.reportPositionCustodies = reportPositionsResponse.value.data;
	}

	formValid() {
		return this.fromDate && this.toDate;
	}
}
