import { LitElement, html, css } from "lit";
import { customElement, state } from "lit/decorators.js";

import { styles as sharedStyles } from "../../styles/shared.js";
import { styles as tableStyles } from "../../styles/tables.js";
import { styles as inputStyles } from "../../styles/input.js";

import store from "../../store/index.js";
import { setTitle } from "../../store/actions.js";
import { sort, toast } from "../../utils.js";
import "./create-user-view.js";
import { AMUser } from "../../types/types.js";
import { get } from "../../api/client.js";

declare global {
	interface HTMLElementTagNameMap {
		"users-view": typeof UsersView;
	}
}

@customElement("users-view")
export default class UsersView extends LitElement {
	@state()
	users: AMUser[] = [];

	@state()
	openCreateUserDialog: boolean = false;

	static styles = [
		sharedStyles,
		tableStyles,
		inputStyles,
		css`div.actions {
		display: flex;
		flex-grow: 0;
		flex-direction: row;
		margin-left: auto;
		align-items: flex-start;
	  }`,
	];

	render() {
		return html`
      <div class="card">
        <div class="card-header">
          <div class="actions">
            <fm-button-v2
              class="button-small"
              type="button"
              @click="${this.createClick}"
              >Opret ny</fm-button-v2
            >
          </div>
        </div>
        <table>
          <thead>
            <tr>
              <th data-key="username" @click="${this.sort}">Brugernavn</th>
              <th data-key="name" @click="${this.sort}">Navn</th>
              <th data-key="name" @click="${this.sort}">E-mail</th>
            </tr>
          </thead>
          <tbody>
            ${
							this.users.length === 0
								? html`<tr>
                  <td colspan="3">Indlæser brugere...</td>
                </tr>`
								: this.users.map(
										(user) => html`
                    <tr>
                      <td>
                        <router-link to="/users/${user.id}">
                          <a>${user.username || "(none)"}</a>
                        </router-link>
                      </td>
                      <td>${user.name}</td>
                      <td>${user.email}</td>
                    </tr>
                  `,
								  )
						}
          </tbody>
        </table>
      </div>
      <fm-dialog
        .opened="${this.openCreateUserDialog}"
        @close="${() => {
					this.openCreateUserDialog = false;
				}}"
      >
        ${
					this.openCreateUserDialog
						? html`<create-user-view
              @created="${this.userCreated}"
            ></create-user-view>`
						: null
				}
      </fm-dialog>
    `;
	}

	async connectedCallback() {
		super.connectedCallback();
		store.dispatch(setTitle("Brugere"));

		await this.getUsers();
	}

	sort(event: Event) {
		const key = (event.target as HTMLTableCellElement).getAttribute("data-key");

		if (!key) {
			return;
		}

		this.users = sort(this.users, key) as AMUser[];
	}

	createClick() {
		this.openCreateUserDialog = true;
	}

	async userCreated(event: Event) {
		this.openCreateUserDialog = false;

		await this.getUsers();
	}

	async getUsers() {
		const amUsersResponse = await get<{ data: AMUser[] }>("/amusers");

		if (!amUsersResponse.ok) {
			const error = await amUsersResponse.error.json();

			if ("message" in error) {
				toast(error.message);
			} else {
				toast("Der er sket en fejl.");
			}
			return;
		}
		this.users = amUsersResponse.value.data;
	}
}
