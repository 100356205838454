import { LitElement, html, css } from "lit";
import { customElement, state } from "lit/decorators.js";

import { styles as sharedStyles } from "../../styles/shared.js";
import { styles as inputStyles } from "../../styles/input.js";
import { ModelItem } from "../../types/types.js";
import { toast } from "../../utils.js";
import { post } from "../../api/client.js";

declare global {
	interface HTMLElementTagNameMap {
		"create-model-view": typeof CreateModelView;
	}
}

@customElement("create-model-view")
export default class CreateModelView extends LitElement {
	@state()
	newModel: {
		code: string;
		name: string;
	} = {
		code: "",
		name: "",
	};

	static styles = [
		sharedStyles,
		inputStyles,
		css` #form {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 16px;
  }

  #form label {
    margin-bottom: 4px;
  }

  #form div.row {
    display: flex;
    flex-direction: row;
    gap: 16px;
  }

  #form div.row > div {
    width: 100%;
  }

  input[type="text"] {
    padding: 8px;
    width: 100%;
    height: 32px;
    border: 1px solid #ddd;
  }

  div.overlay {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }`,
	];

	render() {
		return html`
      <div class="overlay">
        <h1>Opret ny modelportefølje</h1>
        <fm-form class="form-grid" id="modelform">
          <div class="form-field">
            <label for="code">
              Kode
              <input type="text" name="code" id="code" required @input="${(
								e: InputEvent,
							) => {
								this.newModel.code = (e.target as HTMLInputElement).value;
							}}" />
            </label>
          </div>
          <div class="form-field">
            <label for="name">
              Navn
              <input type="text" name="name" id="name" required @input="${(
								e: Event,
							) => {
								this.newModel.name = (e.target as HTMLInputElement).value;
							}}" />
            </label>
          </div>
          <div class="form-field">
            <fm-button-v2
              type="submit"
              class="btn btn--light"
              @click="${this.save}"
              >Gem</fm-button-v2
            >
          </div>
        </fm-form>
      </div>
    `;
	}

	async save(event: Event) {
		const modelsResponse = await post<
			typeof this.newModel,
			{ data: ModelItem; status?: string }
		>("/models", this.newModel);

		if (!modelsResponse.ok) {
			const error = await modelsResponse.error.json();

			if ("message" in error) {
				toast(error.message);
			} else {
				toast("Der er sket en fejl.");
			}
			return;
		} else if (modelsResponse.value.status === "ERROR") {
			toast("Der er sket en fejl.");
			return;
		}

		const model = modelsResponse.value.data;

		toast("Modelportefølje oprettet");
		this.dispatchEvent(
			new CustomEvent("created", {
				detail: { modelId: model.id },
			}),
		);
	}
}
