import { LitElement, html, css } from "lit";

import { styles as sharedStyles } from "../../../styles/shared.js";
import { styles as tableStyles } from "../../../styles/tables.js";
import { styles as inputStyles } from "../../../styles/input.js";

import { quantity, money } from "../../../formatting/numberformats.js";
import "./custody-rebalance-view.js";
import "./custody-sell-view.js";
import { customElement, property, state } from "lit/decorators.js";
import { get } from "../../../api/client.js";
import type {
	Custody,
	ClientCustodyPositions,
	ClientCustodyPosition,
} from "../../../types/types.js";
import { toast } from "../../../utils.js";

declare global {
	interface HTMLElementTagNameMap {
		"custody-positions-view": typeof CustodyPositionsView;
	}
}

@customElement("custody-positions-view")
export default class CustodyPositionsView extends LitElement {
	@property({ type: Number })
	accessor custodyId: number | undefined;

	@property({ type: Boolean })
	accessor disabled = false;

	@state()
	loading = false;

	@state()
	custody?: Custody;

	@state()
	custodyPositions?: ClientCustodyPositions;

	@state()
	openRebalanceDialog = false;

	@state()
	openSellDialog = false;

	async connectedCallback() {
		super.connectedCallback();

		if (this.custodyId) {
			this.loading = true;

			const custodyResponse = await get<{ data: Custody }>(
				`/custodies/${this.custodyId}`,
			);

			if (!custodyResponse.ok) {
				const error = await custodyResponse.error.json();

				if ("message" in error) {
					toast(error.message);
				} else {
					toast("Der er sket en fejl.");
				}

				this.loading = false;
				return;
			}

			// Get client id from custody for next request
			const clientId = custodyResponse.value.data.client_id;

			const positionsResponse = await get<{ data: ClientCustodyPositions[] }>(
				`/clients/${clientId}/position`,
			);

			if (!positionsResponse.ok) {
				const error = await positionsResponse.error.json();

				if ("message" in error) {
					toast(error.message);
				} else {
					toast("Der er sket en fejl.");
				}

				this.loading = false;
				return;
			}

			// No errors so set the state
			this.custody = custodyResponse.value.data;
			const positions = positionsResponse.value.data;

			this.custodyPositions = positions.find(
				(position) => position.custody_id === this.custodyId,
			);

			this.loading = false;
		}
	}

	static styles = [
		sharedStyles,
		inputStyles,
		tableStyles,
		css`.card-block {
    display: flex;
    flex-direction: column;
  }

  .card-block > div.info {
    display: flex;
    flex-direction: row;
    padding: 12px 24px;
  }

  .card-block > p {
    padding: 12px 24px;
  }

  table {
    table-layout: fixed;
  }

  div.actions {
    display: flex;
    flex-grow: 0;
    flex-direction: row;
    margin-left: auto;
    align-items: flex-start;
  }

  .form-field {
    padding: 0rem;
  }`,
	];

	render() {
		return html`
      <div class="card">
        <div class="card-header">
          <h2>Beholdning</h2>
          ${
						this.custody
							? html`<div class="actions">
                <fm-button-v2
                  class="button-small"
                  type="button"
                  ?disabled="${this.disabled || !this.custodyPositions}"
                  @click="${this.rebalanceClick}"
                  >Rebalancer</fm-button-v2
                >
                <fm-button-v2
                  class="button-small"
                  type="button"
                  ?disabled="${this.disabled || !this.custodyPositions}"
                  @click="${this.sellClick}"
                  >Sælg</fm-button-v2
                >
              </div>`
							: null
					}
        </div>
        <div class="card-block">
          ${
						this.loading
							? html`<p>Indlæser investeringer...</p>`
							: this.custody
								? html` <table>
                <thead>
                  <tr>
                    <th>Investeringsbevis</th>
                    <th class="numeric">Købt/Solgt</th>
                    <th class="numeric">Kursavance</th>
                    <th class="numeric">Udbytte</th>
                    <th class="numeric">Udbytteskat</th>
                    <th class="numeric">Antal styk</th>
                    <th class="numeric">Værdi</th>
                  </tr>
                </thead>
                <tbody>
                  ${
										this.custodyPositions
											? html`${(this.custodyPositions?.positions || []).map(
													(p) => html` <tr>
                            <td>${p.security_name}</td>
                            <td class="numeric">${money(p.buysell_pc)}</td>
                            <td class="numeric">${money(p.pl_pc)}</td>
                            <td class="numeric">${money(p.intdiv_pc)}</td>
                            <td class="numeric">${money(p.tax_pc)}</td>
                            <td class="numeric">${quantity(p.quantity)}</td>
                            <td class="numeric">${money(p.marketvalue_pc)}</td>
                          </tr>`,
												)}
                        <tr>
                          ${this.renderSum(this.custodyPositions)}
                        </tr>`
											: html`<tr>
                        <td colspan="7">Ingen investeringer</td>
                      </tr>`
									}
                </tbody>
              </table>`
								: null
					}
        </div>
        <fm-dialog
          .opened="${this.openRebalanceDialog}"
          @close="${() => {
						this.openRebalanceDialog = false;
					}}"
        >
          ${
						this.openRebalanceDialog
							? html`<custody-rebalance-view
                .custodyId="${this.custodyId}"
                @cancel="${() => {
									this.openRebalanceDialog = false;
								}}"
                @done="${() => {
									this.openRebalanceDialog = false;
								}}"
              ></custody-rebalance-view>`
							: null
					}
        </fm-dialog>
        <fm-dialog
          .opened="${this.openSellDialog}"
          @close="${() => {
						this.openSellDialog = false;
					}}"
        >
          ${
						this.openSellDialog
							? html`<custody-sell-view
                .custodyId="${this.custodyId}"
                @done="${() => {
									this.openSellDialog = false;
								}}"
              ></custody-sell-view>`
							: null
					}
        </fm-dialog>
      </div>
    `;
	}

	renderSum(custody: ClientCustodyPositions) {
		const rBuysell = (
			accumulator: number,
			currentValue: ClientCustodyPosition,
		) => accumulator + (currentValue.buysell_pc ? currentValue.buysell_pc : 0);
		const rPL = (accumulator: number, currentValue: ClientCustodyPosition) =>
			accumulator + (currentValue.pl_pc ? currentValue.pl_pc : 0);
		const rIntDiv = (
			accumulator: number,
			currentValue: ClientCustodyPosition,
		) => accumulator + (currentValue.intdiv_pc ? currentValue.intdiv_pc : 0);
		const rTax = (accumulator: number, currentValue: ClientCustodyPosition) =>
			accumulator + (currentValue.tax_pc ? currentValue.tax_pc : 0);
		const rMV = (accumulator: number, currentValue: ClientCustodyPosition) =>
			accumulator +
			(currentValue.marketvalue_pc ? currentValue.marketvalue_pc : 0);

		if (!custody) {
			return;
		}

		const buysell_pc = custody.positions.reduce(rBuysell, 0);
		const pl_pc = custody.positions.reduce(rPL, 0);
		const intdiv_pc = custody.positions.reduce(rIntDiv, 0);
		const tax_pc = custody.positions.reduce(rTax, 0);
		const mv_pc = custody.positions.reduce(rMV, 0);

		return html`
      <td><strong>I alt</strong></td>
      <td class="numeric"><b>${money(buysell_pc)}</b></td>
      <td class="numeric"><b>${money(pl_pc)}</b></td>
      <td class="numeric"><b>${money(intdiv_pc)}</b></td>
      <td class="numeric"><b>${money(tax_pc)}</b></td>
      <td></td>
      <td class="numeric"><b>${money(mv_pc)}</b></td>
    `;
	}

	rebalanceClick() {
		this.openRebalanceDialog = true;
	}

	sellClick() {
		this.openSellDialog = true;
	}
}
