import { LitElement, html, css } from "lit";
import { state, property, customElement } from "lit/decorators.js";

import { styles as sharedStyles } from "../../../styles/shared.js";
import { styles as tableStyles } from "../../../styles/tables.js";
import { styles as inputStyles } from "../../../styles/input.js";

import { sort, toast } from "../../../utils.js";
import { date } from "../../../formatting/dateformats.js";
import { get, post } from "../../../api/client.js";
import type {
	AMLQuestion,
	AMLQuestionAnswer,
	AMLQuestionEmptyAnswer,
} from "../../../types/types.js";

declare global {
	interface HTMLElementTagNameMap {
		"customer-questions-report-view": typeof CustomerQuestionsReportView;
	}
}

@customElement("customer-questions-report-view")
export default class CustomerQuestionsReportView extends LitElement {
	@property({ type: Number })
	accessor clientId: number | undefined;

	@state()
	fromDate: string = date(new Date().addDays(-100));

	@state()
	query = "";

	@state()
	unansweredOnly = false;

	@state()
	loading = false;

	@state()
	questions: AMLQuestion[] = [];

	static styles = [
		sharedStyles,
		tableStyles,
		inputStyles,
		css`div.actions {
    display: flex;
    flex-grow: 0;
    flex-direction: row;
    margin-left: auto;
    align-items: flex-start;
  }

  div.card-header {
    align-items: flex-start;
  }

  fm-form {
    display: flex;
    align-items: flex-end;
    gap: 8px;
  }

  div.form-space {
    margin: 0px;
    min-width: initial;
  }

  div.form-space input[type="date"],
  div.form-space input[type="search"] {
    font-size: 12px;
    height: 28px;
  }`,
	];

	render() {
		return html`
      <div class="card">
        <div class="card-header">
          <div class="info">
            <h1>AML spørgsmål</h1>
          </div>
          <div class="actions">
            <fm-form
              id="parms"
              class="reportform no-print"
              method="get"
              @submit="${this.search}"
            >
              <div class="form-space">
                <label
                  >Spurgt fra
                  <input
                    type="date"
                    name="fromdate"
                    class="date-input"
                    id="fromdate"
                    value="${this.fromDate}"
                    @input="${(e: InputEvent) => {
											this.fromDate = (e.target as HTMLInputElement).value;
										}}"
                  />
                </label>
              </div>
              <div class="form-space">
                <label>Søg</label>
                <input
                  type="search"
                  class="search-input"
                  name="query"
                  placeholder="Søg"
                  .value="${this.query}"
                  @input="${(e: InputEvent) => {
										this.query = (e.target as HTMLInputElement).value;
									}}"
                />
              </div>
              <div class="form-space">
                <label style="white-space: nowrap;">
                  <input type="checkbox" name="unanswered" style="display: initial;" ?checked="${
										this.unansweredOnly
									}"  @input="${(e: Event) => {
										this.unansweredOnly = (
											e.target as HTMLInputElement
										).checked;
									}}" />
                  Kun ubesvarede
                </label>
              </div>
              <div class="form-space">
                <fm-button-v2
                  id="submit_button"
                  class="button submit"
                  type="submit"
                  ?disabled="${!this.fromDate}"
                  .loading="${this.loading}"
                  >Vis</fm-button-v2
                >
              </div>
            </fm-form>
          </div>
        </div>
        <table>
          <thead>
            <tr>
              <th data-key="ask_date" @click="${this.sort}">Dato</th>
              <th data-key="user_name" @click="${this.sort}">Engagement</th>
              <th data-key="question" @click="${this.sort}">Spørgsmål</th>
              <th data-key="asked_by" @click="${this.sort}">
                Modtager/Besvarer
              </th>
            </tr>
          </thead>
          <tbody>
            ${this.questions.map(
							(q) => html`
                <tr>
                  <td>${q.ask_date}</td>
                  <td>${q.user_name}</td>
                  <td>${q.question}</td>
                  <td>${q.asked_by}</td>
                </tr>
                ${this.renderAnswers(q.ask_id, q.answers, q.empty_answer)}
              `,
						)}
          </tbody>
        </table>
      </div>
    `;
	}

	renderAnswers(
		askId: number,
		answers: AMLQuestionAnswer[] | null,
		emptyAnswer: AMLQuestionEmptyAnswer[] | null,
	) {
		if (answers) {
			return html`${(answers || []).map((a) => this.renderAnswer(a))}`;
		}

		return html` <tr>
        <td></td>
        <td></td>
        <td>
          <fm-form
            url="/questions/${askId}"
            method="POST"
            @submit="${this.saveAnswer}"
          >
            <div style="display:table;width:100%;">
              <div style="display:table-cell;margin:auto;">
                ${(emptyAnswer || []).map((a) => this.renderEmptyAnswer(a))}
              </div>
              <div
                style="display:flex;flex-direction: column;justify-content: center;"
              >
                <fm-button-v2 type="submit" style="max-width:100px;margin-left:auto;" class="button">Gem svaret</fm-button-v2>
              </div>
            </div>
          </fm-form>
        </td>
        <td></td>
      </tr>`;
	}

	renderEmptyAnswer(a: AMLQuestionEmptyAnswer) {
		return html`
       <input type="radio" name="answer_id" value="${a.answer_id}">${
					a.answer
				} ${this.renderEmptyOther(a)}<br>
     </tr>
     `;
	}

	renderEmptyOther(a: AMLQuestionEmptyAnswer) {
		if (a.other_yn === "Y") {
			return html`<input type="text" name="answer_other" />`;
		}

		return "";
	}

	renderAnswer(a: AMLQuestionAnswer) {
		return html`
      <tr>
        <td>${a.answer_date}</td>
        <td></td>
        <td>${a.answer} ${a.answer_other}</td>
        <td>${a.answered_by}</td>
      </tr>
    `;
	}

	async search() {
		this.loading = true;
		const questionsResponse = await get<{ data: AMLQuestion[] }>(
			`/questions?from=${this.fromDate}&query=${this.query}&unanswered=${this.unansweredOnly}&client_id=${this.clientId}`,
		);
		this.loading = false;

		if (!questionsResponse.ok) {
			const error = await questionsResponse.error.json();

			if ("message" in error) {
				toast(error.message);
			} else {
				toast("Der er sket en fejl.");
			}

			return;
		}
		this.questions = questionsResponse.value.data;
	}

	sort(event: Event) {
		const key = (event.target as HTMLTableCellElement).getAttribute("data-key");

		if (!key || this.questions.length === 0) {
			return;
		}

		this.questions = sort(this.questions, key) as AMLQuestion[];
		this.requestUpdate();
	}

	async saveAnswer(event: Event) {
		const target = event.target as HTMLFormElement;
		const formValue = target["value"];
		event.preventDefault();

		if (formValue.answer_id) {
			const answer = {
				answer_id: formValue.answer_id,
				answer_other: formValue.answer_other,
			};
			const saveAnswerResponse = await post<
				typeof answer,
				{ data: { ask_id: number; answer_id: number; answer_other: string } }
			>(target["url"], formValue);

			if (!saveAnswerResponse.ok) {
				const error = await saveAnswerResponse.error.json();

				if ("message" in error) {
					toast(error.message);
				} else {
					toast("Der er sket en fejl.");
				}

				return;
			}

			toast("Svar gemt");
			await this.search();
		} else {
			toast("Vælg et svar");
		}
	}
}
