import { Router, RouterLink } from "runway";
import store from "../store/index.js";
import { BASE_PATH } from "../env.js";

import PM_PageChrome from "../views/pm-page-chrome.js";

import NotFound from "../views/not-found/index.js";
import Login from "../views/login/login-view.js";

import Customers from "../views/customers/index.js";
import CustomersModule from "../views/customers/routes.js";

import Groups from "../views/groups/index.js";
import GroupsModule from "../views/groups/routes.js";

import Agreements from "../views/agreements/index.js";
import AgreementsModule from "../views/agreements/routes.js";

import Custodies from "../views/custodies/index.js";
import CustodiesModule from "../views/custodies/routes.js";

import Orders from "../views/orders/index.js";
import OrdersModule from "../views/orders/routes.js";

import ModelsView from "../views/models/index.js";
import ModelsModule from "../views/models/routes.js";

import UsersView from "../views/users/index.js";
import UsersModule from "../views/users/routes.js";

const router = new Router({
	root: BASE_PATH,
	routes: [
		{ path: "", redirect: "login" },
		{
			path: "login",
			component: Login,
			properties: (route) => ({ redirect: route.query.get("redirect") }),
		},
		{
			path: "**",
			guard: () => !store.getState().status.authenticated,
			redirect: "/login",
		},
		{
			path: "",
			component: PM_PageChrome,
			children: [
				{ path: "customers", component: Customers, children: CustomersModule },
				{
					path: "agreements",
					component: Agreements,
					children: AgreementsModule,
				},
				{ path: "groups", component: Groups, children: GroupsModule },

				{ path: "custodies", component: Custodies, children: CustodiesModule },
				{ path: "orders", component: Orders, children: OrdersModule },

				{ path: "models", component: ModelsView, children: ModelsModule },

				{ path: "users", component: UsersView, children: UsersModule },
			],
		},
		{ path: "404", component: NotFound },
		{ path: "**", redirect: "/404" },
	],
});

RouterLink.use(router);
customElements.define("router-link", RouterLink);

export default router;
