import { LitElement, html, css } from "lit";
import { get, post, put } from "../../../api/client.js";
import store, { connect } from "../../../store/index.js";
import { setUserData } from "../../../store/actions.js";
import { styles as sharedStyles } from "../../../styles/shared.js";
import { styles as tableStyles } from "../../../styles/tables.js";
import { styles as inputStyles } from "../../../styles/input.js";

import "./person-custodies-view.js";
import "./upload-file-view.js";
import { API_URL } from "../../../env.js";
import { property, state } from "lit/decorators.js";
import { User, UserFile } from "../../../types/types.js";
import { toast } from "../../../utils.js";
import { getToken } from "../../../api/client.js";
import { Result } from "../../../result.js";

declare global {
	interface HTMLElementTagNameMap {
		"person-view": typeof ConnectedElement;
	}
}

export class PersonView extends LitElement {
	@property({ type: Number })
	accessor clientId: number | undefined;

	@property({ type: Number })
	accessor userId: number | undefined;

	@state()
	user?: User;

	@state()
	openUploadFileDialog: boolean = false;

	@state()
	files: UserFile[] = [];

	async connectedCallback() {
		super.connectedCallback();

		if (this.userId) {
			const userResponse = await get<{ data: User }>(`/users/${this.userId}`);

			if (!userResponse.ok) {
				return;
			}

			this.user = userResponse.value.data;

			await this.getFiles();
		}
	}

	async getFiles() {
		const filesResponse = await get<{ data: UserFile[] }>(
			`/users/${this.userId}/files`,
		);

		if (!filesResponse.ok) {
			return;
		}

		this.files = filesResponse.value.data;

		this.requestUpdate();
	}

	static styles = [
		sharedStyles,
		inputStyles,
		tableStyles,
		css`div.card-header {
    align-items: flex-start;
  }

  div.card-header .info p {
    margin: 4px 0px;
    color: #666;
  }

  div.actions {
    display: flex;
    flex-grow: 0;
    flex-direction: row;
    margin-left: auto;
    align-items: flex-start;
  }`,
	];
	render() {
		return html`
      <div class="card">
        <div class="card-header">
          <h1>Oplysninger</h1>
        </div>
        ${this.renderEditForm()}
      </div>
      <div class="card">
        <div class="card-header">
          <div class="info">
            <h2>Legitimation</h2>
            <p>
              Der kræves billed-ID for voksne kontoejere, og sundhedskort el.
              lign. for børn, der ejer en investeringskonto.
            </p>
          </div>
          <div class="actions">
            <fm-button-V2
              class="button-small"
              type="button"
              @click="${this.uploadClick}"
              >Upload legitimation</fm-button-v2
            >
          </div>
        </div>
        <table>
          <tbody>
            ${
							this.files.length === 0
								? html`<tr>
                  <td>Ingen filer</td>
                </tr>`
								: this.files.map((f) => html` ${this.renderFile(f)} `)
						}
          </tbody>
        </table>
      </div>
      <person-custodies-view
        .clientId="${this.clientId}"
        .userId="${this.userId}"
      ></person-custodies-view>
      <fm-dialog
        .opened="${this.openUploadFileDialog}"
        @close="${() => {
					this.openUploadFileDialog = false;
				}}"
      >
        ${
					this.openUploadFileDialog
						? html`<upload-file-view
              .userId="${this.userId}"
              @uploaded="${this.fileUploaded}"
            ></upload-file-view>`
						: null
				}
      </fm-dialog>
    `;
	}

	renderAccount() {
		if (!this.user) {
			return;
		}

		if (this.user.account_id) {
			return html`<input
        type="hidden"
        name="account_id"
        value="${this.user.account_id}"
      />`;
		} else {
			return html` <label class="form-field">
        Engagement
        <fm-autocomplete
          url="/lov/clientaccounts"
          name="account_id"
          required
          .rawValue="${{
						id: this.user?.account_id || "",
						name: this.user?.account_name || "",
					}}"
          select="id"
          display="name"
        >
        </fm-autocomplete>
      </label>`;
		}
	}

	renderEditForm() {
		if (!this.user) {
			return;
		}

		const _user = this.user;
		return html` <fm-form
      class="form-grid"
      id="editform"
      method="put"
      @submit="${this.saveUser}"
    >
      ${this.renderAccount()}
      <label class="form-field">
        Navn
        <input
          type="text"
          name="name"
          required
          value="${this.user?.name || ""}"
          @input="${(e: InputEvent) => {
						_user.name = (e.target as HTMLInputElement).value;
					}}"
        />
      </label>
      <label class="form-field">
        CPR
        <input
          type="text"
          required
          name="cpr"
          value="${this.user?.cpr || ""}"
          @input="${(e: InputEvent) => {
						_user.cpr = (e.target as HTMLInputElement).value;
					}}"
        />
      </label>
      <label class="form-field">
        CVR
        <input type="text" name="cvr" value="${
					this.user?.cvr || ""
				}" @input="${(e: InputEvent) => {
			_user.cvr = (e.target as HTMLInputElement).value;
		}}" />
      </label>
      <label class="form-field">
        Adresse
        <input
          type="text"
          required
          name="address1"
          value="${this.user?.address1 || ""}"
          @input="${(e: InputEvent) => {
						_user.address1 = (e.target as HTMLInputElement).value;
					}}"
        />
        <input
          type="text"
          required
          name="address2"
          value="${this.user?.address2 || ""}"
          @input="${(e: InputEvent) => {
						_user.address2 = (e.target as HTMLInputElement).value;
					}}"
        />
      </label>
      <label class="form-field">
        Postnr. og by
        <input
          type="text"
          required
          name="zipcity"
          value="${this.user?.zipcity || ""}"
          @input="${(e: InputEvent) => {
						_user.zipcity = (e.target as HTMLInputElement).value;
					}}"
        />
      </label>

      <label class="form-field">
        Bopælsland
        <fm-autocomplete
          name="country_id"
          url="/lov/countries"
          .rawValue="${{
						id: this.user?.country_id || "",
						name: this.user?.country_name || "",
					}}"
          select="id"
          display="name"
          @select="${(e) => {
						_user.country_id = e.target.value;
					}}"
        >
        </fm-autocomplete>
      </label>
      <label class="form-field">
        TIN (hvis skattepligtig udenfor Danmark)
        <input type="text" name="tin" value="${
					this.user?.tin || ""
				}" @input="${(e: InputEvent) => {
			_user.tin = (e.target as HTMLInputElement).value;
		}}" />
      </label>

      <label class="form-field">
        Statsborgerskab 1
        <fm-autocomplete
          name="citizen1_id"
          url="/lov/countries"
          .rawValue="${{
						id: this.user?.citizen1_id || "",
						name: this.user?.citizen1_name || "",
					}}"
          select="id"
          display="name"
          @select="${(e) => {
						_user.citizen1_id = e.target.value;
					}}"
        >
        </fm-autocomplete>
      </label>
      <label class="form-field">
        Statsborgerskab 2
        <fm-autocomplete
          name="citizen2_id"
          url="/lov/countries"
          .rawValue="${{
						id: this.user?.citizen2_id || "",
						name: this.user?.citizen2_name || "",
					}}"
          select="id"
          display="name"
          @select="${(e) => {
						_user.citizen2_id = e.target.value;
					}}"
        >
        </fm-autocomplete>
      </label>

      <label class="form-field">
        Email
        <input
          type="email"
          name="email"
          required
          value="${this.user?.email || ""}"
          @input="${(e: InputEvent) => {
						_user.email = (e.target as HTMLInputElement).value;
					}}"
        />
      </label>
      <label class="form-field">
        Telefon
        <input type="tel" name="phone" value="${
					this.user?.phone || ""
				}" @input="${(e: InputEvent) => {
			_user.phone = (e.target as HTMLInputElement).value;
		}}" />
      </label>
      <fm-button-v2 type="submit" class="btn btn--light">Gem</fm-button-v2>
    </fm-form>`;
	}

	renderFile(f: UserFile) {
		return html` <tr>
      <td>
        <object
          width="240"
          height="240"
          type="${f.mimetype}"
          data="${API_URL}/files/${f.id}?t=${getToken()}"
        ></object>
      </td>
      <td>
        <a target="_blank" href="${API_URL}/files/${f.id}?t=${getToken()}"
          >Vis i ny fane</a
        >
      </td>
    </tr>`;
	}

	async saveUser(event: Event) {
		event.preventDefault();

		if (!this.user) {
			return;
		}

		const userData = {
			name: this.user.name,
			address1: this.user.address1,
			address2: this.user.address2,
			zipcity: this.user.zipcity,
			phone: this.user.phone,
			email: this.user.email,
			cpr: this.user.cpr,
			cvr: this.user.cvr,
			country_id: this.user.country_id,
			citizen1_id: this.user.citizen1_id,
			citizen2_id: this.user.citizen2_id,
			tin: this.user.tin,
		};

		let userDataResponse: Result<{ data: User }, Response>;

		if (this.user.id) {
			userDataResponse = await put<typeof userData, { data: User }>(
				`/users/${this.user.id}`,
				userData,
			);
		} else {
			userDataResponse = await post<typeof userData, { data: User }>(
				"/users",
				userData,
			);
		}

		if (!userDataResponse.ok) {
			const error = await userDataResponse.error.json();

			if ("message" in error) {
				toast(error.message);
			} else {
				toast("Der er sket en fejl.");
			}

			return;
		}

		store.dispatch(setUserData(userDataResponse.value.data));
		toast("Gemt");
	}

	uploadClick() {
		this.openUploadFileDialog = true;
	}

	async fileUploaded() {
		this.openUploadFileDialog = false;

		await this.getFiles();
	}
}

const ConnectedElement = connect(PersonView, {
	selectors: (state) => ({
		user: state.userData,
	}),
}) as typeof PersonView;

export default ConnectedElement;

customElements.define("person-view", ConnectedElement);
