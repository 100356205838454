import "@fundmarket/polyfills";
import "@webcomponents/webcomponentsjs/webcomponents-loader.js";
import "./components/index.js";
import "./elements.js";
import "./utils.js";
import router from "./router/index.js";
import { APIClient } from "./api/index.js";
import * as Sentry from "@sentry/browser";
import { BrowserTracing } from "@sentry/tracing";
import { API_URL, ENVIRONMENT, SENTRY_DSN, VERSION } from "./env.js";

console.log("Version:", VERSION);
console.log("Environment:", ENVIRONMENT);

if (ENVIRONMENT !== "development") {
	Sentry.init({
		dsn: SENTRY_DSN,
		integrations: [new BrowserTracing()],
		environment: ENVIRONMENT,
		release: VERSION,
		tracesSampleRate: 0.6,
	});
}

router.connect(document.body);

// Used by fm-form
const API = new APIClient(API_URL);
window["API"] = API;
export default API;
