import { LitElement, html, css } from "lit";
import { state, customElement } from "lit/decorators.js";

import { styles as sharedStyles } from "../../styles/shared.js";
import { styles as tableStyles } from "../../styles/tables.js";
import { styles as inputStyles } from "../../styles/input.js";

import store from "../../store/index.js";
import router from "../../router/index.js";
import { setTitle } from "../../store/actions.js";

import { sort } from "../../utils.js";
import { DateFormatter } from "../../formatting/dateformats.js";
import "./create-model-view.js";
import { get } from "../../api/client.js";
import type { ModelItem } from "../../types/types.js";

declare global {
	interface HTMLElementTagNameMap {
		"models-view": typeof ModelsView;
	}
}

@customElement("models-view")
export default class ModelsView extends LitElement {
	@state()
	models: ModelItem[] = [];

	@state()
	openCreateModelDialog = false;

	static styles = [
		sharedStyles,
		tableStyles,
		inputStyles,
		css`div.actions {
    display: flex;
    flex-grow: 0;
    flex-direction: row;
    margin-left: auto;
    align-items: flex-start;
  }`,
	];
	render() {
		return html`
      <div class="card">
        <div class="card-header">
          <div class="actions">
            <fm-button-v2
              class="button-small"
              type="button"
              @click="${this.createClick}"
              >Opret ny</fm-button-v2
            >
          </div>
        </div>
        <table>
          <thead>
            <tr>
              <th data-key="code" @click="${this.sort}">Kode</th>
              <th data-key="name" @click="${this.sort}">Navn</th>
              <th class="numeric" data-key="datecount" @click="${this.sort}">
                Antal datoer
              </th>
              <th class="numeric" data-key="seccount" @click="${this.sort}">
                Bruges af
              </th>
              <th data-key="code" @click="${this.sort}">Oprettet</th>
              <th data-key="code" @click="${this.sort}">Ændret</th>
            </tr>
          </thead>
          <tbody>
            ${this.models.map(
							(model) => html`
                <tr>
                  <td>
                    <router-link to="/models/${model.id}">
                      <a>${model.code}</a>
                    </router-link>
                  </td>
                  <td>${model.name}</td>
                  <td class="numeric">${model.datecount}</td>
                  <td class="numeric">${model.custody_count}</td>
                  <td>
                    ${DateFormatter.format(
											new Date(model.reg_date),
											"dd-mm-yyyy",
										)}
                  </td>
                  <td>
                    ${
											model.mod_date
												? DateFormatter.format(
														new Date(model.mod_date),
														"dd-mm-yyyy",
													)
												: ""
										}
                  </td>
                </tr>
              `,
						)}
          </tbody>
        </table>
      </div>
      <fm-dialog
        .opened="${this.openCreateModelDialog}"
        @close="${() => {
					this.openCreateModelDialog = false;
				}}"
      >
        ${
					this.openCreateModelDialog
						? html`<create-model-view
              @created="${this.modelCreated}"
            ></create-model-view>`
						: null
				}
      </fm-dialog>
    `;
	}

	async connectedCallback() {
		super.connectedCallback();

		store.dispatch(setTitle("Modelporteføljer"));

		const modelsResponse = await get<{ data: ModelItem[] }>("/models");

		if (!modelsResponse.ok) {
			return;
		}

		this.models = modelsResponse.value.data;
	}

	sort(event: Event) {
		const key = (event.target as HTMLTableCellElement).getAttribute("data-key");

		if (!key) {
			return;
		}

		this.models = sort(this.models, key) as ModelItem[];

		this.requestUpdate();
	}

	createClick() {
		this.openCreateModelDialog = true;
	}

	async modelCreated(event: CustomEvent) {
		this.openCreateModelDialog = false;
		router.push(`/models/${event.detail.modelId}`);
	}
}
