import * as Sentry from "@sentry/browser";
import { toast } from "../../utils.js";
import { LitElement, html, css } from "lit";
import { customElement, state } from "lit/decorators.js";
import { get, post, clearToken, saveToken } from "../../api/client.js";
import router from "../../router/index.js";
import store from "../../store/index.js";
import { receiveStatus, invalidateStatus } from "../../store/actions.js";
import { styles as sharedStyles } from "../../styles/shared.js";
import { styles as inputStyles } from "../../styles/input.js";
import { BASE_PATH as _BASE_PATH } from "../../env.js";

const BASE_PATH = _BASE_PATH === "/" ? "" : _BASE_PATH;

declare global {
	interface HTMLElementTagNameMap {
		"login-view": LoginView;
	}
}

@customElement("login-view")
export default class LoginView extends LitElement {
	private redirect = "";

	@state()
	private loading = false;

	@state()
	private phase = 1;

	static styles = [
		sharedStyles,
		inputStyles,
		css`* {
			box-sizing: border-box;
		  }
  
		  :host {
			margin: 0 auto;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			min-height: 80vh;
		  }
  
		  .login-card {
			display: flex;
			flex-direction: column;
			justify-content: center;
			padding: 32px;
			background: white;
		  }
  
		  .logo-container {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
		  }
  
		  .logo {
			width: 32px;
		  }
  
		  .logo-text {
			margin: 16px 0;
		  }
  
		  .error {
			color: #f44336;
			text-align: center;
		  }
  
		  .btn {
			margin-top: 24px;
		  }`,
	];

	render() {
		return html`
      <div class="card login-card">
        <div class="logo-container">
          <img
            class="logo"
            src="${BASE_PATH}/static/logo.svg"
            alt="Fundmarket logo"
          />
          <h1 class="logo-text">Fundmarket</h1>
        </div>
        ${this.renderLoginBox()}
      </div>
    `;
	}

	renderLoginBox1() {
		return html`
      <fm-form id="login-form" @submit="${this.onSubmit}">
        <label class="form-field">
          Brugernavn
          <input type="text" name="username" id="username" required autofocus />
        </label>
        <label class="form-field">
          Password
          <input type="password" name="password" id="password" required />
        </label>
        <fm-button-v2 type="submit" class="btn" .loading="${this.loading}">Login</fm-button-v2>
      </fm-form>
    `;
	}

	renderLoginBox2() {
		return html`
      <fm-form id="login-form" @submit="${this.onSubmit2}">
        <label class="form-field">
          SMS kode
          <input type="text" name="challenge" id="challenge" required autofocus />
        </label>
        <fm-button-v2 type="submit" class="btn" .loading="${this.loading}">Login</fm-button-v2>
      </fm-form>
    `;
	}

	renderLoginBox() {
		if (this.phase === 1) {
			return this.renderLoginBox1();
		}

		return this.renderLoginBox2();
	}

	connectedCallback() {
		super.connectedCallback();
		clearToken();
		store.dispatch(invalidateStatus());
	}

	async submit1() {
		const username = this.shadowRoot?.getElementById(
			"username",
		) as HTMLInputElement;
		const password = this.shadowRoot?.getElementById(
			"password",
		) as HTMLInputElement;

		if (!username || !username.value || !password || !password.value) {
			toast("Brugernavn og password skal være udfyldt.");
			return;
		}

		try {
			this.loading = true;
			const response = await post<
				{ username: string; password: string },
				{
					status: "OK" | "SMS";
					"fundmarket-token": string;
					message?: string;
				}
			>("/login", {
				username: username.value,
				password: password.value,
			});
			this.loading = false;

			if (!response.ok) {
				const error = await response.error.json();

				if ("message" in error) {
					toast(error.message);
				} else {
					toast("Der er sket en fejl.");
				}
				return;
			}

			if (!("fundmarket-token" in response.value)) {
				toast("Der er sket en fejl.");
				return;
			}

			const token = response.value["fundmarket-token"];

			switch (response.value.status) {
				case "OK":
					saveToken(token);
					store.dispatch(
						receiveStatus({
							authenticated: true,
						}),
					);
					router.push(this.redirect || "/customers");
					break;
				case "SMS":
					saveToken(token);
					this.phase = 2;
					break;
				default:
					toast(response.value.message || "Der er sket en fejl.");
			}
		} catch (err) {
			Sentry.captureException(err);
			console.error(err);
			this.loading = false;
			if (err instanceof Response) {
				try {
					const response = await err.json();
					toast(response.message || "Der er sket en fejl.");
				} catch (_) {}
			}
		}
	}

	async onSubmit(event: Event) {
		event.preventDefault();
		await this.submit1();
	}

	async submit2() {
		const challenge = this.shadowRoot?.getElementById(
			"challenge",
		) as HTMLInputElement;

		if (!challenge || !challenge.value) {
			toast("Brugernavn og password skal være udfyldt.");
			return;
		}

		try {
			this.loading = true;
			const response = await get<{ authenticated: boolean }>(
				`/login/${challenge.value}`,
			);
			this.loading = false;

			if (!response.ok) {
				const error = await response.error.json();

				if ("message" in error) {
					toast(error.message);
				} else {
					toast("Der er sket en fejl.");
				}
				return;
			}

			if (response.value.authenticated) {
				store.dispatch(
					receiveStatus({
						authenticated: true,
					}),
				);
				router.push(this.redirect || "/customers");
			} else {
				toast("Forkert SMS kode.");
				this.phase = 1;
			}
		} catch (err) {
			Sentry.captureException(err);
			console.error(err);
			this.loading = false;
			if (err instanceof Response) {
				try {
					const response = await err.json();
					toast(response.message || "Der er sket en fejl.");
				} catch (_) {}
			}
		}
	}

	async onSubmit2(event: Event) {
		event.preventDefault();
		await this.submit2();
	}
}
