import { LitElement, html, css, PropertyValues } from "lit";
import { customElement, state, property } from "lit/decorators.js";

import { styles as sharedStyles } from "../../../styles/shared.js";
import { styles as tableStyles } from "../../../styles/tables.js";
import { styles as inputStyles } from "../../../styles/input.js";

import { get, put } from "../../../api/client.js";

import store from "../../../store/index.js";
import { setClientData } from "../../../store/actions.js";
import "./customer-custodies-view.js";
import { Client } from "../../../types/types.js";
import { toast } from "../../../utils.js";

declare global {
	interface HTMLElementTagNameMap {
		"customer-view": typeof CustomerView;
	}
}

@customElement("customer-view")
export default class CustomerView extends LitElement {
	@property({ type: Number })
	accessor clientId: number | undefined;

	@state()
	client?: Client;

	static styles = [sharedStyles, tableStyles, inputStyles, css``];

	async updated(changedProperties: PropertyValues<this>) {
		if (changedProperties.has("clientId")) {
			const clientResponse = await get<Client>(`/clients/${this.clientId}`);
			if (!clientResponse.ok) {
				const error = await clientResponse.error.json();

				if ("message" in error) {
					toast(error.message);
				} else {
					toast("Der er sket en fejl.");
				}

				return;
			}

			this.client = clientResponse.value;
		}
	}

	render() {
		if (!this.client) {
			return;
		}

		// Local reference to this.client used in @input event handlers
		const _client = this.client;

		return html`
      <div class="card">
        <div class="card-header">
          <h1>Stamoplysninger</h1>
        </div>
        <fm-form
          class="form-grid"
          id="editform"
          method="put"
          @submit="${this.saveClient}"
        >
          <label class="form-field">
            ID-kode
            <input
              type="text"
              name="code"
              required
              value="${this.client.code}"
              @input="${(e: InputEvent) => {
								_client.code = (e.target as HTMLInputElement).value;
							}}"
            />
          </label>
          <label class="form-field">
            Navn
            <input
              type="text"
              name="name"
              required
              .value="${this.client.name}"
              @input="${(e: InputEvent) => {
								_client.name = (e.target as HTMLInputElement).value;
							}}"
            />
          </label>
          <label class="form-field">
            E-mail
            <input
              type="email"
              name="email"
              required
              value="${this.client.email}"
              @input="${(e: InputEvent) => {
								_client.email = (e.target as HTMLInputElement).value;
							}}"
            />
          </label>
          <label class="form-field">
            Telefonnr.
            <input type="tel" name="phone" value="${
							this.client.phone
						}"  @input="${(e: InputEvent) => {
			_client.phone = (e.target as HTMLInputElement).value;
		}}" />
          </label>
          <fm-button-v2 type="submit" class="btn btn--light">Gem</fm-button-v2>
        </fm-form>
      </div>
      <customer-custodies-view
        clientId="${this.client.id}"
      ></customer-custodies-view>
    `;
	}

	async saveClient(event: Event) {
		event.preventDefault();

		if (!this.clientId || !this.client) {
			return;
		}

		const clientData: Client = this.client;

		const updateClientResponse = await put<Client, { data: Client }>(
			`/clients/${this.clientId}`,
			clientData,
		);

		if (!updateClientResponse.ok) {
			const error = await updateClientResponse.error.json();

			if ("message" in error) {
				toast(error.message);
			} else {
				toast("Der er sket en fejl.");
			}

			return;
		}

		const newClientData = updateClientResponse.value.data;

		store.dispatch(setClientData(newClientData));
		toast("Gemt");
	}
}
