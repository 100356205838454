import { LitElement, html, css } from "lit";
import router from "../../router/index.js";
import { styles as sharedStyles } from "../../styles/shared.js";
import { styles as tableStyles } from "../../styles/tables.js";
import { styles as inputStyles } from "../../styles/input.js";
import { customElement, property, state } from "lit/decorators.js";
import type { ClientGroup } from "../../types/types.js";
import type { AutocompleteElement } from "../../components/fm-autocomplete.js";
import { toast } from "../../utils.js";
import { get, post, del } from "../../api/client.js";
import type { Result } from "../../result.js";

declare global {
	interface HTMLElementTagNameMap {
		"group-view": GroupView;
	}
}

@customElement("group-view")
export default class GroupView extends LitElement {
	@property({ type: Number })
	accessor groupId: number | undefined;

	@state()
	private group?: ClientGroup;

	static styles = [
		sharedStyles,
		inputStyles,
		tableStyles,
		css`:host {
    display: block;
    width: 100%;
    max-width: 1000px;
    margin: 24px auto;
  }

  .add-block {
    justify-content: center;
    padding: 16px;
  }`,
	];

	render() {
		return this.group
			? html`
      <div class="card">
        <div class="card-header">
          <h1>Detaljer</h1>
        </div>
        <fm-form
          class="form-grid"
          @response="${this.onResponse}"
          @error="${this.onError}"
          url="${
						this.group.id ? `/client-groups/${this.group.id}` : "/client-groups"
					}"
          method="${this.group.id ? "put" : "post"}"
        >
          <label class="form-field">
            Kode
            <input type="text" name="code" value="${this.group.code || ""}" />
          </label>
          <label class="form-field">
            Navn
            <input type="text" name="name" value="${this.group.name || ""}" />
          </label>
          <fm-button-v2 class="button btn--light" type="submit">Gem</fm-button-v2>
          <fm-button-v2 class="button btn--light" @click="${this.onGroupDelete}">
            Slet
          </fm-button-v2>
        </fm-form>
      </div>

      <div class="card">
        <div class="card-header">
          <h1>Kunder</h1>
        </div>

        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Navn</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            ${this.renderAddMember()}
            ${(this.group.members || []).map(
							(member) => html`
                <tr>
                  <td>${member.id}</td>
                  <td>${member.name}</td>
                  <td class="right-align">
                    <fm-button-v2
                      class="button btn--light"
                      data-member-id="${member.id}"
                      @click="${this.onDelete}"
                    >
                      Fjern
                    </fm-button-v2>
                  </td>
                </tr>
              `,
						)}
          </tbody>
        </table>
      </div>
    `
			: null;
	}

	renderAddMember() {
		return html`
      <tr>
        <td></td>
        <td>
          <fm-autocomplete
            id="newmember"
            url="/clients"
            display="name"
            select="id"
            .exclude="${(this.group?.members || []).map((c) => c.client_id)}"
          ></fm-autocomplete>
        </td>
        <td class="right-align">
          <fm-button-v2 class="btn" @click="${this.addMember}">Tilføj</fm-button-v2>
        </td>
      </tr>
    `;
	}

	async connectedCallback() {
		super.connectedCallback();

		if (this.groupId) {
			this.group = await this.fetchGroup(this.groupId);
		}
	}

	async fetchGroup(groupId: number): Promise<ClientGroup | undefined> {
		if (groupId) {
			const getGroupResponse = await get<{
				data: ClientGroup;
			}>(`/client-groups/${this.groupId}`);

			if (!getGroupResponse.ok) {
				const error = await getGroupResponse.error.json();

				if ("message" in error) {
					toast(error.message);
				} else {
					toast("Der er sket en fejl.");
				}
				return;
			}

			return getGroupResponse.value.data;
		}

		return undefined;
	}

	async addMember() {
		const clientIdString = (
			this.shadowRoot?.getElementById("newmember") as AutocompleteElement
		).value;

		const clientId = Number.parseInt(clientIdString) || undefined;

		if (this.groupId && clientId) {
			const addMemberResponse = await post<
				{ client_id: number },
				{
					message: string;
				}
			>(`/client-groups/${this.groupId}/members`, {
				client_id: clientId,
			});

			if (!addMemberResponse.ok) {
				const error = await addMemberResponse.error.json();

				if ("message" in error) {
					toast(error.message);
				} else {
					toast("Der er sket en fejl.");
				}
				return;
			}

			this.group = await this.fetchGroup(this.groupId);
			toast("Kunde tilføjet til gruppen.");
		}
	}

	async onError(event: CustomEvent) {
		const error = event.detail;
		if (error?.json) {
			const response = await error.json();
			toast(response.message);
		} else {
			toast("Der er opstået en fejl.");
		}
	}

	async onResponse(event: CustomEvent) {
		const response = event.detail;
		toast(response.message);
		if (response.id) {
			this.groupId = response.id;

			if (this.groupId) {
				this.group = await this.fetchGroup(this.groupId);
			}
		}
	}

	/* remove member */
	async onDelete(event: Event) {
		const target = event.target as HTMLButtonElement;
		const memberId = target.getAttribute("data-member-id");

		if (memberId && this.groupId) {
			const deleteGroupMemberResponse: Result<
				{
					message: "OK";
				},
				Response
			> = await del(`/client-groups/${this.groupId}/${memberId}`, {});

			if (!deleteGroupMemberResponse.ok) {
				const error = await deleteGroupMemberResponse.error.json();

				if ("message" in error) {
					toast(error.message);
				} else {
					toast("Der er sket en fejl.");
				}
				return;
			}

			this.group = await this.fetchGroup(this.groupId);
			toast("Kunde fjernet fra gruppen.");
		}
	}

	/* delete group */
	async onGroupDelete() {
		const deleteGroup: Result<
			{
				message: "OK";
			},
			Response
		> = await del(`/client-groups/${this.groupId}`, {});

		if (!deleteGroup.ok) {
			const error = await deleteGroup.error.json();

			if ("message" in error) {
				toast(error.message);
			} else {
				toast("Der er sket en fejl.");
			}
			return;
		}

		toast("Kundegruppen er slettet.");
		router.push("/groups");
	}
}
