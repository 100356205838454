import { html, css } from "lit";

export const styles = css`
    * {
      box-sizing: border-box;
    }

    :host {
      display: block;
    }

    router-link a {
      text-decoration: none;
      color: inherit;
    }

    router-link[active] a {
      color: var(--primary-color);
    }

    h1 {
      font-weight: 500;
      font-size: 20px;
      margin: 0;
    }

    h2 {
      font-weight: 500;
      font-size: 18px;
      margin: 0;
    }

    .card {
      background: white;
      border: 1px solid #ddd;
      border-radius: 2px;
      margin: 16px;
    }

    .card-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 24px;
      min-height: 56px;
      border-bottom: 1px solid #ddd;
    }

    .card-block {
      display: flex;
      min-height: 64px;
      border-bottom: 1px solid #ddd;
    }

    .btn {
      cursor: pointer;
      margin: 0;
      padding: 12px 16px;
      border: none;
      border-radius: 2px;
      background: var(--primary-color);
      color: #fff;
      text-align: center;
      font-family: "Roboto";
      font-size: 14px;
      font-weight: 500;
      text-transform: uppercase;
      white-space: nowrap;
      transition-duration: 0.3s;
      transition-property: background-color, box-shadow;
      transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
        0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
    }

    .btn:not([disabled]):hover,
    .btn:not([disabled]):focus {
      color: black;
      box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14),
        0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.4);
    }

    .btn--light:not([disabled]):hover,
    .btn--light:not([disabled]):focus {
      background: #f2f2f2;
      box-shadow: none;
    }
    .btn--light {
      /* background: none; 
    color: #111; */
      box-shadow: none;
    }

    .btn--light:not([disabled]):hover,
    .btn--light:not([disabled]):focus {
      background: #f2f2f2;
      box-shadow: none;
    }

    .unselectable {
      -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
      -khtml-user-select: none; /* Konqueror HTML */
      -moz-user-select: none; /* Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
      user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
    }

    .custody-type-code {
      color: #fff;
      display: flex;
      overflow: hidden;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background: var(--primary-color);
      padding: 3px;
      margin: 3px;
    }

    .horizontal-menu-header {
      display: flex;
      flex-direction: column;
      width: 100%;
      position: sticky;
      top: 0px;
      z-index: 1;
    }

    .horizontal-menu-header div.primary,
    .horizontal-menu-header div.secondary {
      display: flex;
      align-items: center;
      height: 48px;
      width: 100%;
      padding: 0 16px;
      background: white;
      border-bottom: 1px solid #ddd;
    }

    .horizontal-menu-header a {
      color: #666;
      font-size: 15px;
      font-weight: 500;
      margin: 0 8px;
      padding: 6px;
    }

    .horizontal-menu-header a[active],
    div.secondary router-link a.active {
      color: var(--primary-color);
    }

    .horizontal-menu-header div.secondary a {
      font-weight: normal;
      font-size: 14px;
    }

    div.secondary router-link[active] a,
    div.secondary router-link a.active {
      font-weight: 500;
    }

    .horizontal-menu-header ul {
      display: flex;
      flex-direction: row;
      list-style-type: none;
      margin: 0px;
      padding: 0px;
    }

    .horizontal-menu-header ul li {
      display: flex;
      flex-direction: column;
      margin: 0 8px;
      padding: 6px;
    }

    .horizontal-menu-header ul li div.tiny {
      font-size: 12px;
      font-weight: 500;
      margin-bottom: 4px;
    }

    .horizontal-menu-header ul li a {
      margin: initial;
      padding: initial;
    }

    .horizontal-menu-header .actions {
      display: flex;
      flex-direction: row;
      margin-left: auto;
      align-items: flex-start;
    }

    .actions .button-small {
      margin-left: 12px;
      text-align: center;
      font-weight: bold;
    }

    @media print {
      .no-print {
        display: none;
      }

      .horizontal-menu-header {
        display: none;
      }

      .search-input {
        display: none;
      }
    }
`;

export default html`<style>${styles}</style>`;
