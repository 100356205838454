declare const __ENVIRONMENT__: string;
declare const __API_URL__: string;
declare const __BASE_PATH__: string;
declare const __SENTRY_DSN__: string;
declare const __VERSION__: string;

export const ENVIRONMENT: string = __ENVIRONMENT__;
export const API_URL: string = __API_URL__;
export const BASE_PATH: string = __BASE_PATH__;
export const SENTRY_DSN: string = __SENTRY_DSN__;
export const VERSION: string = __VERSION__;
