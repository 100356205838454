const dateFormatter = new Intl.DateTimeFormat("da-DK", {
	year: "numeric",
	month: "2-digit",
	day: "2-digit",
});

export const date = (ms: Date) => {
	try {
		return dateFormatter
			.format(ms)
			.replace(/\./g, "/")
			.replace(/-/g, "/")
			.split("/")
			.reverse()
			.join("-");
	} catch (err) {
		return "";
	}
};

const standardDateFormat = new Intl.DateTimeFormat("da-dk", {
	year: "numeric",
	month: "2-digit",
	day: "2-digit",
});

const humanDateFormat = new Intl.DateTimeFormat("da-dk", {
	day: "numeric",
	month: "short",
});

function reverse(str: string, char: string) {
	return str.split(char).reverse().join(char);
}

export class DateFormatter {
	static format(value: Date, format: string) {
		switch (format) {
			case "dd/mm/yyyy":
				return standardDateFormat.format(value).replace(/\//g, "-");
			case "yyyy/mm/dd":
				return reverse(standardDateFormat.format(value), "/").replace(
					/\//g,
					"-",
				);
			case "dd-mm-yyyy":
				return standardDateFormat.format(value);
			case "yyyy-mm-dd":
				return reverse(standardDateFormat.format(value), ".").replace(
					/\./g,
					"-",
				);
			case "d. mon.":
				return humanDateFormat.format(value);
			case "mon. d.":
				return reverse(humanDateFormat.format(value), " ");
		}
	}
}
