import { LitElement, html, css } from "lit";
import { customElement, state, property } from "lit/decorators.js";

import { styles as sharedStyles } from "../../../styles/shared.js";
import { styles as tableStyles } from "../../../styles/tables.js";
import { styles as inputStyles } from "../../../styles/input.js";

import { get } from "../../../api/client.js";

import { date, DateFormatter } from "../../../formatting/dateformats.js";
import { quantity, money } from "../../../formatting/numberformats.js";
import { toast } from "../../../utils.js";
import {
	CustodyTaxReport,
	CustodyTaxReportContent,
	CustodyTaxReportContentRow,
} from "../../../types/types.js";

declare global {
	interface HTMLElementTagNameMap {
		"custody-tax-report-view": typeof CustodyTaxReportView;
	}
}

@customElement("custody-tax-report-view")
export default class CustodyTaxReportView extends LitElement {
	@property({ type: Number })
	accessor custodyId: number | undefined;

	@state()
	report?: CustodyTaxReport;

	@state()
	fromDate: string = date(new Date().addDays(-10));

	@state()
	toDate: string = date(new Date());

	@state()
	loading: boolean = false;

	static styles = [
		sharedStyles,
		tableStyles,
		inputStyles,
		css`div.actions {
    display: flex;
    flex-grow: 0;
    flex-direction: row;
    margin-left: auto;
    align-items: flex-start;
  }

  div.card-header {
    align-items: flex-start;
  }

  div.card-header .info p {
    margin: 0px;
    color: #666;
  }

  fm-form {
    display: flex;
    align-items: flex-end;
    gap: 8px;
  }

  div.form-space {
    margin: 0px;
    min-width: initial;
  }

  div.form-space input[type="date"] {
    font-size: 12px;
    height: 28px;
  }
  div.form-space fm-button-v2.button-small {
    margin: 0px;
  }`,
	];

	render() {
		return html`
      <div class="card">
        <div class="card-header">
          <div class="info">
            <h1>Skatterapport</h1>
            ${
							this.report
								? html`<p>
                  Periode:
                  ${DateFormatter.format(new Date(this.fromDate), "dd/mm/yyyy")}
                  til
                  ${DateFormatter.format(new Date(this.toDate), "dd/mm/yyyy")}
                </p>`
								: null
						}
          </div>

          <div class="actions">
            <fm-form method="get" id="parms" class="reportform no-print">
              <div class="form-space">
                <label>Fra</label>
                <input type="date" name="fromdate" value="${
									this.fromDate
								}" @input="${(e: Event) => {
			this.fromDate = (e.target as HTMLInputElement).value;
		}}" />
              </div>
              <div class="form-space">
                <label>Til</label>
                <input type="date" name="todate" value="${
									this.toDate
								}" @input="${(e: Event) => {
			this.toDate = (e.target as HTMLInputElement).value;
		}}" />
              </div>
              <div class="form-space">
                <fm-button-v2
                  id="submit_button"
                  type="submit"
                  class="button-small"
                  ?disabled="${!this.formValid()}"
                  .loading="${this.loading}"
                  @click="${this.refresh}"
                  >Vis</fm-button-v2
                >
              </div>
            </fm-form>
          </div>
        </div>

        ${
					this.report
						? html` <div>
              <table>
                <thead>
                  <tr>
                    <th colspan="9" style="text-align:center">
                      ${this.report.title} ${this.report.fromdate} -
                      ${this.report.todate}
                    </th>
                  </tr>
                  <tr>
                    <th colspan="5"></th>
                    <th colspan="2" style="text-align:center">Aktieindkomst</th>
                    <th colspan="2" style="text-align:center">
                      Kapitalindkomst
                    </th>
                  </tr>
                  <tr>
                    <th>Dato</th>
                    <th>Transaktion</th>
                    <th class="numeric">Antal beviser</th>
                    <th class="numeric">Beholdning efter</th>
                    <th class="numeric">Beløb</th>
                    <th class="numeric">Avance</th>
                    <th class="numeric">Udbytte</th>
                    <th class="numeric">Avance</th>
                    <th class="numeric">Udbytte</th>
                  </tr>
                </thead>
                <tbody>
                  ${(this.report.content || []).map((c) => this.renderSec(c))}
                  <tr>
                    <td colspan="9"><hr style="width:100%" /></td>
                  </tr>
                  <tr>
                    <th></th>
                    <th colspan="4">Ialt ${this.report.title}</th>
                    <th class="numeric">
                      ${money(this.report.total.profit_loss_eq)}
                    </th>
                    <th class="numeric">
                      ${money(this.report.total.dividend_eq)}
                    </th>
                    <th class="numeric">
                      ${money(this.report.total.profit_loss_cap)}
                    </th>
                    <th class="numeric">
                      ${money(this.report.total.dividend_cap)}
                    </th>
                  </tr>
                </tbody>
              </table>
            </div>`
						: null
				}
      </div>`;
	}

	renderSec(c: CustodyTaxReportContent) {
		return html`
      <tr>
        <td colspan="10">${c.subtitle} ${c.subsubtitle}</td>
      </tr>
      ${c.rows.map((r) => this.renderTran(r))}
      <tr>
        <th colspan="5">I alt ${c.subtitle}</th>
        <th class="numeric">${money(c.total.profit_loss_eq)}</th>
        <th class="numeric">${money(c.total.dividend_eq)}</th>
        <th class="numeric">${money(c.total.profit_loss_cap)}</th>
        <th class="numeric">${money(c.total.dividend_cap)}</th>
      </tr>
    `;
	}

	renderTran(t: CustodyTaxReportContentRow) {
		return html` <tr>
      <td>${t.transaction_date}</td>
      <td>${t.transaction_type}</td>
      <td class="numeric">
        ${t.transaction_type === "Ultimo" ? "" : quantity(t.quantity)}
      </td>
      <td class="numeric">${quantity(t.balance)}</td>
      <td class="numeric">${money(t.buysell)}</td>
      <td class="numeric">${money(t.profit_loss_eq)}</td>
      <td class="numeric">${money(t.dividend_eq)}</td>
      <td class="numeric">${money(t.profit_loss_cap)}</td>
      <td class="numeric">${money(t.dividend_cap)}</td>
    </tr>`;
	}

	async fetch() {
		const taxReportResponse = await get<CustodyTaxReport>(
			`/custodies/${this.custodyId}/tax?from=${this.fromDate}&to=${this.toDate}`,
		);

		if (!taxReportResponse.ok) {
			const error = await taxReportResponse.error.json();

			if ("message" in error) {
				toast(error.message);
			} else {
				toast("Der er sket en fejl.");
			}

			return;
		}

		this.report = taxReportResponse.value;
	}

	async refresh(event: Event) {
		this.loading = true;
		await this.fetch();
		this.loading = false;
	}

	formValid() {
		return this.fromDate && this.toDate;
	}
}
