import { LitElement, html, css, type PropertyValues } from "lit";
import { classMap } from "lit/directives/class-map.js";
import { state, property } from "lit/decorators.js";

import { styles as sharedStyles } from "../../../styles/shared.js";
import { styles as tableStyles } from "../../../styles/tables.js";
import { styles as inputStyles } from "../../../styles/input.js";

import { connect } from "../../../store/index.js";

import { money } from "../../../formatting/numberformats.js";
import { cprNumber } from "../../../formatting/piiformats.js";
import "./create-person-view.js";
import router from "../../../router/index.js";
import { get } from "../../../api/client.js";
import type { CustodiesCustody, User } from "../../../types/types.js";
import type { Result } from "../../../result.js";

declare global {
	interface HTMLElementTagNameMap {
		"customer-custodies-view": typeof ConnectedElement;
	}
}

// User with Custodies
type Person = Partial<User> & { custodies: CustodiesCustody[] };

export class CustomerCustodiesView extends LitElement {
	@property({ type: Number })
	accessor clientId: number | undefined;

	@state()
	persons: Person[] = [];

	@state()
	userId?: number;

	@state()
	totalValue: number = 0;

	@state()
	showPII?: boolean = undefined;

	@state()
	openCreatePersonDialog: boolean = false;

	@state()
	showDisabledCustodies: boolean = false;

	toggleShowDisabledCustodies() {
		this.showDisabledCustodies = !this.showDisabledCustodies;
	}

	visibleCustodies(custodies: CustodiesCustody[]) {
		return custodies.filter(
			(custody) =>
				this.showDisabledCustodies || custody.custody_active_yn === "Y",
		);
	}

	static styles = [
		sharedStyles,
		tableStyles,
		inputStyles,
		css`#parms {
		display: flex;
		flex-direction: row;
		align-items: flex-end;
	  }

	  #persons tr.first td {
		border-top: 2px solid #ddd;
	  }

	  #persons tr td.disabled {
		color: #f00;
	  }

	  div.show-disabled-custodies
	  {
		display: flex;
		flex-direction: row;
		gap: 4px;
		margin: 4px 0px;
		align-items: center;
	  }

	  div.actions {
		display: flex;
		flex-grow: 0;
		flex-direction: row;
		margin-left: auto;
		align-items: flex-start;
	  }

	  div.card-header {
		align-items: flex-start;
	  }`,
	];

	render() {
		return html`
      <div class="card">
        <div class="card-header">
			<div>
				<h1>Oversigt</h1>
				<div class="show-disabled-custodies">
                <input
                  type="checkbox"
                  id="show-disabled-custodies"
                  name="show-disabled-custodies"
                  .checked="${this.showDisabledCustodies}"
                  @click="${this.toggleShowDisabledCustodies}"
                />
                <label for="show-disabled-custodies">Vis deaktiverede investeringskonti</label>
              </div>
		 </div>
          <div class="actions">
            <fm-button-v2
              class="button-small"
              type="button"
              @click="${this.createClick}"
              >Opret ny person</fm-button-v2
            >
          </div>
        </div>
        <table id="persons">
          <thead class="sticky">
            <tr>
              <th>Navn</th>
              <th>CPR/CVR/SE</th>
              <th>Kontonummer</th>
			  ${this.showDisabledCustodies ? html`<th>Aktiv</th>` : null}
              <th>Type</th>
              <th>Kontonavn</th>
              <th>Modelportefølje</th>
              <th>Oprettet</th>
              <th class="numeric">Værdi</th>
            </tr>
          </thead>
          <tbody>
            ${this.persons.map(
							(person) => html` <tr class="first">
                  <td
                    valign="top"
                    rowspan="${this.visibleCustodies(person.custodies).length + 1}"
                  >
                    <router-link
                      to="/customers/${this.clientId}/persons/${person.id}"
                      ><a>${person.name || "(Unavngivet person)"}</a></router-link
                    >
                  </td>
                  <td
                    valign="top"
                    colspan="${this.visibleCustodies(person.custodies).length === 0 ? 7 : 1}"
                    rowspan="${this.visibleCustodies(person.custodies).length + 1}"
                  >
                    ${person.cpr ? cprNumber(person.cpr) : person.cvr}
                  </td>
                </tr>
                ${this.visibleCustodies(person.custodies).map(
									(custody, index) =>
										html`
									
                    <tr class="${index === 0 ? "first" : ""}">
                      <td style="white-space: nowrap;">
                        <router-link
                          to="/customers/${custody.client_id}/persons/${
														custody.owner_id
													}/custodies/${custody.custody_id}"
                        >
                          <a>${custody.custody_code}</a>
                        </router-link>
						${custody.client_id === this.clientId ? html`` : html`<br />(andet engagement)`}
                      </td>
					  ${
							this.showDisabledCustodies
								? html`<td class="${classMap({
										disabled: custody.custody_active_yn !== "Y",
									})}">${custody.custody_active_yn === "Y" ? "Ja" : "Nej"}</td>`
								: null
						}
                      <td>${custody.custodytype_code}</td>
                      <td>${custody.name}</td>
                      <td>${custody.model_name} ${custody.model_status}</td>
                      <td style="white-space: nowrap;">${custody.reg_date ? custody.reg_date.replaceAll("-", ".") : ""}</td>
                      <td class="numeric">${money(custody.currentvalue)}</td>
                    </tr>
                  `,
								)}`,
						)}
            <tr>
              <td colspan="${this.showDisabledCustodies ? 8 : 7}"><strong>I alt</strong></td>
              <td class="numeric">
                <strong>${money(this.totalValue)}</strong>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <fm-dialog
        .opened="${this.openCreatePersonDialog}"
        @close="${() => {
					this.openCreatePersonDialog = false;
				}}"
      >
        ${
					this.openCreatePersonDialog
						? html`<create-person-view
              @created="${this.personCreated}"
              .clientId="${this.clientId}"
            ></create-person-view>`
						: null
				}
      </fm-dialog>
    `;
	}

	async willUpdate(changedProperties: PropertyValues<this>) {
		if (changedProperties.has("clientId")) {
			// Reset list of persons and total value when clientId changes
			this.persons = [];
			this.totalValue = 0;

			const usersResponse = await get<{ data: User[] }>(
				`/users?client_id=${this.clientId}`,
			);
			if (!usersResponse.ok) {
				return;
			}
			const users = usersResponse.value.data;

			const persons: Person[] = [];
			for (const user of users) {
				// Skip if user_id is set and does not match
				if (this.userId && user.id !== this.userId) continue;

				const person: Person = {
					...user,
					custodies: [],
				};

				persons.push(person);
			}

			const personsCustodiesPromises: Promise<
				Result<{ data: CustodiesCustody[] }, Response>
			>[] = [];

			// We need to get custodies from both the client level and user level to get all custodies.
			// This is because a user can have shared custodies with other users in a separate client (see FUN-792).

			// Get custodies in an account
			const customerCustodiesPromise = get<{ data: CustodiesCustody[] }>(
				`/custodies?query=&client_id=${this.clientId}&all=true`,
			);

			personsCustodiesPromises.push(customerCustodiesPromise);

			// Get custodies for each user in the account
			for (const person of persons) {
				const personCustodiesPromise = get<{ data: CustodiesCustody[] }>(
					`/custodies?query=&user_id=${person.id}&all=true`,
				);

				personsCustodiesPromises.push(personCustodiesPromise);
			}

			// Flatten custodies to one list
			const custodiesGross = (
				await Promise.all(personsCustodiesPromises)
			).flat();

			// Get valid custodies
			const validCustodies: CustodiesCustody[] = [];
			for (const custodyResult of custodiesGross) {
				if (!custodyResult.ok) {
					continue;
				}

				for (const custody of custodyResult.value.data) {
					validCustodies.push(custody);
				}
			}

			// Remove duplicates (happens when a user has shared custodies)
			const custodies = validCustodies.filter((custody, index, array) => {
				return (
					array.findIndex((c) => c.custody_id === custody.custody_id) === index
				);
			});

			// TODO: Not optimal, but it works
			for (const custody of custodies) {
				for (const person of persons) {
					if (
						person.id === custody?.owner_id ||
						person.id === custody?.owner2_id
					) {
						person.custodies.push(custody);
						this.totalValue += custody.currentvalue ? custody.currentvalue : 0;
					}
				}
			}

			this.persons = [...persons];
		}
	}

	createClick() {
		this.openCreatePersonDialog = true;
	}

	async personCreated(event: CustomEvent) {
		this.openCreatePersonDialog = false;

		router.push(
			`/customers/${event.detail.clientId}/persons/${event.detail.userId}`,
		);
	}
}

const ConnectedElement = connect(CustomerCustodiesView, {
	selectors: (state) => ({
		showPII: state.showPII,
	}),
}) as typeof CustomerCustodiesView;

customElements.define("customer-custodies-view", ConnectedElement);

export default ConnectedElement;
