import { LitElement, html, css } from "lit";
import { customElement, property, state } from "lit/decorators.js";

import "./custody-model-portfolio-view.js";
import "./custody-positions-view.js";
import "./custody-orders-view.js";
import "./custody-redemptions-view.js";

import { styles as sharedStyles } from "../../../styles/shared.js";
import { styles as tableStyles } from "../../../styles/tables.js";
import { styles as inputStyles } from "../../../styles/input.js";
import type { Custody } from "../../../types/types.js";
import { get, put } from "../../../api/client.js";
import { toast } from "../../../utils.js";
import { live } from "lit/directives/live.js";

declare global {
	interface HTMLElementTagNameMap {
		"custody-view": typeof CustodyView;
	}
}

@customElement("custody-view")
export default class CustodyView extends LitElement {
	@property({ type: Number })
	accessor custodyId: number | undefined;

	@state()
	custody?: Custody;

	@state()
	custodyName = "";

	@state()
	openChangeModelPortfolioDialog = false;

	@state()
	disabled = false;

	static styles = [
		sharedStyles,
		inputStyles,
		tableStyles,
		css`#custody-form {
    display: flex;
    flex-direction: row;
    padding: 8px 16px;
    gap: 4rem;
  }

  #custody-name,
  #model-portfolio {
    display: flex;
    flex-direction: row;
    gap: 0.8rem;
  }
  #custody-name .form-field {
    width: initial;
  }

  #custody-name fm-button-v2,
  #model-portfolio fm-button-v2 {
    text-align: center;
    align-self: flex-end;
    margin-bottom: 22px;
  }
  
  .card-header
  {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    h2 {
    color: #f00;
  }}
  `,
	];

	async connectedCallback() {
		super.connectedCallback();

		if (!this.custodyId) {
			return;
		}
		const custody = await this.getCustody(this.custodyId);

		if (!custody) {
			return;
		}

		this.custody = custody;
		this.disabled = this.custody?.custody_active_yn !== "Y";
		this.custodyName = this.custody.name;
	}

	render() {
		return this.custodyId && this.custody
			? html`<div class="card">
        <div class="card-header">
          <h1>Detaljer</h1>
          ${this.disabled ? html`<h2>Deaktiveret konto</h2>` : null}
        </div>
        <fm-form
          id="custody-form"
          @submit="${this.save}"
        >
          <div id="custody-code">
            <label class="form-field">
              Kontonummer
              <input
                type="text"
                name="code"
                readonly
                disabled
                value="${this.custody.custody_code}"
              />
            </label>
          </div>
          <div id="custody-name">
            <label class="form-field">
              Kontonavn
              <input
                type="text"
                name="custody_name"
                required
                .value="${live(this.custodyName)}"
			  @input="${(e: Event) => {
					this.custodyName = (e.target as HTMLInputElement).value;
				}}"
              />
            </label>
            <fm-button-v2 type="submit" class="button">Gem</fm-button-v2>
          </div>
          <div id="model-portfolio">
            <label class="form-field">
              Modelportefølje
              <input
                type="text"
                readonly
                disabled
                .value="${
									this.custody?.model_name ?? "Tilpasset modelportefølje"
								}"
              />
            </label>
            <fm-button-v2
              type="button"
              class="button"
              ?disabled="${this.custody?.custody_active_yn !== "Y"}"
              @click="${this.changeModelPortfolioClick}"
              >Skift</fm-button-v2
            >
          </div>
        </fm-form>
      </div>
      <fm-dialog
        .opened="${this.openChangeModelPortfolioDialog}"
        @close="${() => {
					this.openChangeModelPortfolioDialog = false;
				}}"
      >
        ${
					this.openChangeModelPortfolioDialog
						? html`<custody-model-portfolio-view
              custodyId="${this.custodyId}"
              @cancel="${() => {
								this.openChangeModelPortfolioDialog = false;
							}}"
              @done="${this.modelPortfolioChanged}"
            ></custody-model-portfolio-view>`
						: null
				}
      </fm-dialog>
      <custody-positions-view
        custodyId="${this.custodyId}"
      ></custody-positions-view>
      <custody-orders-view
        custodyId="${this.custodyId}"
        limit="10"
      ></custody-orders-view>
      <custody-redemptions-view
        custodyId="${this.custodyId}"
        ?disabled="${this.disabled}"
      ></custody-redemptions-view>
    `
			: null;
	}

	changeModelPortfolioClick() {
		this.openChangeModelPortfolioDialog = true;
	}

	async modelPortfolioChanged() {
		this.openChangeModelPortfolioDialog = false;

		if (!this.custodyId) {
			return;
		}

		this.custody = await this.getCustody(this.custodyId);
	}

	async getCustody(custodyId: number): Promise<Custody | undefined> {
		const custodyResponse = await get<{ data: Custody }>(
			`/custodies/${custodyId}`,
		);

		if (!custodyResponse.ok) {
			const error = await custodyResponse.error.json();

			if ("message" in error) {
				toast(error.message);
			} else {
				toast("Der er sket en fejl.");
			}
			return;
		}

		return custodyResponse.value.data;
	}

	async save(event: CustomEvent) {
		event.preventDefault();

		const updateCustodyResponse = await put<
			{ custody_name: string },
			{ data: Custody }
		>(`/custodies/${this.custodyId}`, { custody_name: this.custodyName });

		if (!updateCustodyResponse.ok) {
			const error = await updateCustodyResponse.error.json();

			if ("message" in error) {
				toast(error.message);
			} else {
				toast("Der er sket en fejl");
			}
			return;
		}

		this.custody = updateCustodyResponse.value.data;

		toast("Ændringerne er gemt");
	}
}
