import ModelsView from "./models-view.js";
import ModelView from "./model-view.js";
import { Snapshot } from "runway";

export default [
	{
		path: "",
		component: ModelsView,
	},
	{
		path: ":modelId",
		component: ModelView,
		properties: ({ parameters }: Snapshot) => {
			const modelId = parameters.get("modelId");

			return { modelId: modelId ? Number.parseInt(modelId) : undefined };
		},
	},
];
