import CustomersContainerView from "./customers-container-view.js";

import CustomersView from "./customers-view.js";
import CustomersPortfolioTaxReportView from "./customers-portfolio-tax-report-view.js";
import CustomersSendPeriodicsReportView from "./customers-send-periodics-report-view.js";
import CustomersQuestionsReportView from "./customers-questions-report-view.js";

import CustomerView from "./customer/customer-view.js";
import CustomerPositionsReportView from "./customer/customer-positions-report-view.js";
import CustomerPeriodicReportView from "./customer/customer-periodic-report-view.js";
import CustomerQuestionsReportView from "./customer/customer-questions-report-view.js";

import PersonView from "./person/person-view.js";
import PersonTaxFormReportView from "./person/person-tax-form-report-view.js";

import CustodyView from "./custody/custody-view.js";

import CustodyStatementReportView from "./custody/custody-statement-report-view.js";
import CustodyTaxReportView from "./custody/custody-tax-report-view.js";
import { Snapshot } from "runway";

export default [
	{
		path: "",
		exact: true,
		component: CustomersContainerView,
		children: [{ path: "", component: CustomersView }],
		properties: ({ parameters }: Snapshot) => ({
			area: "customers",
		}),
	},
	{
		path: "portfolio-tax",
		exact: true,
		component: CustomersContainerView,
		children: [
			{
				path: "",
				component: CustomersPortfolioTaxReportView,
			},
		],
		properties: ({ parameters }: Snapshot) => ({
			area: "customers",
		}),
	},
	{
		path: "send-periodics",
		exact: true,
		component: CustomersContainerView,
		children: [
			{
				path: "",
				component: CustomersSendPeriodicsReportView,
			},
		],
		properties: ({ parameters }: Snapshot) => ({
			area: "customers",
		}),
	},
	{
		path: "questions",
		exact: true,
		component: CustomersContainerView,
		children: [
			{
				path: "",
				component: CustomersQuestionsReportView,
			},
		],
		properties: ({ parameters }: Snapshot) => ({
			area: "customers",
		}),
	},
	// CUSTOMER
	{
		path: ":clientId",
		exact: true,
		component: CustomersContainerView,
		children: [
			{
				path: "",
				component: CustomerView,
				properties: ({ parameters }: Snapshot) => {
					const clientId = parameters.get("clientId");

					return {
						clientId: clientId ? Number.parseInt(clientId) : undefined,
						area: "customer",
					};
				},
			},
		],
		properties: ({ parameters }: Snapshot) => {
			const clientId = parameters.get("clientId");

			return {
				clientId: clientId ? Number.parseInt(clientId) : undefined,
				area: "customer",
			};
		},
	},
	{
		path: ":clientId/positions",
		exact: true,
		component: CustomersContainerView,
		children: [
			{
				path: "",
				component: CustomerPositionsReportView,
				properties: ({ parameters }: Snapshot) => {
					const clientId = parameters.get("clientId");

					return {
						clientId: clientId ? Number.parseInt(clientId) : undefined,
					};
				},
			},
		],
		properties: ({ parameters }: Snapshot) => {
			const clientId = parameters.get("clientId");

			return {
				clientId: clientId ? Number.parseInt(clientId) : undefined,
				area: "customer",
			};
		},
	},
	{
		path: ":clientId/periodic",
		exact: true,
		component: CustomersContainerView,
		children: [
			{
				path: "",
				component: CustomerPeriodicReportView,
				properties: ({ parameters }: Snapshot) => {
					const clientId = parameters.get("clientId");

					return {
						clientId: clientId ? Number.parseInt(clientId) : undefined,
					};
				},
			},
		],
		properties: ({ parameters }: Snapshot) => {
			const clientId = parameters.get("clientId");

			return {
				clientId: clientId ? Number.parseInt(clientId) : undefined,
				area: "customer",
			};
		},
	},
	{
		path: ":clientId/questions",
		exact: true,
		component: CustomersContainerView,
		children: [
			{
				path: "",
				component: CustomerQuestionsReportView,
				properties: ({ parameters }: Snapshot) => {
					const clientId = parameters.get("clientId");

					return {
						clientId: clientId ? Number.parseInt(clientId) : undefined,
					};
				},
			},
		],
		properties: ({ parameters }: Snapshot) => {
			const clientId = parameters.get("clientId");

			return {
				clientId: clientId ? Number.parseInt(clientId) : undefined,
				area: "customer",
			};
		},
	},
	// PERSON
	{
		path: ":clientId/persons/:userId",
		exact: true,
		component: CustomersContainerView,
		children: [
			{
				path: "",
				component: PersonView,
				properties: ({ parameters }: Snapshot) => {
					const clientId = parameters.get("clientId");
					const userId = parameters.get("userId");

					return {
						clientId: clientId ? Number.parseInt(clientId) : undefined,
						userId: userId ? Number.parseInt(userId) : undefined,
					};
				},
			},
		],
		properties: ({ parameters }: Snapshot) => {
			const clientId = parameters.get("clientId");
			const userId = parameters.get("userId");

			return {
				clientId: clientId ? Number.parseInt(clientId) : undefined,
				userId: userId ? Number.parseInt(userId) : undefined,
				area: "person",
			};
		},
	},
	{
		path: ":clientId/persons/:userId/tax-form",
		exact: true,
		component: CustomersContainerView,
		children: [
			{
				path: "",
				component: PersonTaxFormReportView,
				properties: ({ parameters }: Snapshot) => {
					const clientId = parameters.get("clientId");
					const userId = parameters.get("userId");

					return {
						clientId: clientId ? Number.parseInt(clientId) : undefined,
						userId: userId ? Number.parseInt(userId) : undefined,
					};
				},
			},
		],
		properties: ({ parameters }: Snapshot) => {
			const clientId = parameters.get("clientId");
			const userId = parameters.get("userId");

			return {
				clientId: clientId ? Number.parseInt(clientId) : undefined,
				userId: userId ? Number.parseInt(userId) : undefined,
				area: "person",
			};
		},
	},
	// CUSTODY
	{
		path: ":clientId/persons/:userId/custodies/:custodyId",
		exact: true,
		component: CustomersContainerView,
		children: [
			{
				path: "",
				component: CustodyView,
				properties: ({ parameters }: Snapshot) => {
					const clientId = parameters.get("clientId");
					const userId = parameters.get("userId");
					const custodyId = parameters.get("custodyId");

					return {
						clientId: clientId ? Number.parseInt(clientId) : undefined,
						userId: userId ? Number.parseInt(userId) : undefined,
						custodyId: custodyId ? Number.parseInt(custodyId) : undefined,
					};
				},
			},
		],
		properties: ({ parameters }: Snapshot) => {
			const clientId = parameters.get("clientId");
			const userId = parameters.get("userId");
			const custodyId = parameters.get("custodyId");

			return {
				clientId: clientId ? Number.parseInt(clientId) : undefined,
				userId: userId ? Number.parseInt(userId) : undefined,
				custodyId: custodyId ? Number.parseInt(custodyId) : undefined,
				area: "custody",
			};
		},
	},
	{
		path: ":clientId/persons/:userId/custodies/:custodyId/statement",
		exact: true,
		component: CustomersContainerView,
		children: [
			{
				path: "",
				component: CustodyStatementReportView,
				properties: ({ parameters }: Snapshot) => {
					const clientId = parameters.get("clientId");
					const userId = parameters.get("userId");
					const custodyId = parameters.get("custodyId");

					return {
						clientId: clientId ? Number.parseInt(clientId) : undefined,
						userId: userId ? Number.parseInt(userId) : undefined,
						custodyId: custodyId ? Number.parseInt(custodyId) : undefined,
					};
				},
			},
		],
		properties: ({ parameters }: Snapshot) => {
			const clientId = parameters.get("clientId");
			const userId = parameters.get("userId");
			const custodyId = parameters.get("custodyId");

			return {
				clientId: clientId ? Number.parseInt(clientId) : undefined,
				userId: userId ? Number.parseInt(userId) : undefined,
				custodyId: custodyId ? Number.parseInt(custodyId) : undefined,
				area: "custody",
			};
		},
	},
	{
		path: ":clientId/persons/:userId/custodies/:custodyId/tax",
		exact: true,
		component: CustomersContainerView,
		children: [
			{
				path: "",
				component: CustodyTaxReportView,
				properties: ({ parameters }: Snapshot) => {
					const clientId = parameters.get("clientId");
					const userId = parameters.get("userId");
					const custodyId = parameters.get("custodyId");

					return {
						clientId: clientId ? Number.parseInt(clientId) : undefined,
						userId: userId ? Number.parseInt(userId) : undefined,
						custodyId: custodyId ? Number.parseInt(custodyId) : undefined,
					};
				},
			},
		],
		properties: ({ parameters }: Snapshot) => {
			const clientId = parameters.get("clientId");
			const userId = parameters.get("userId");
			const custodyId = parameters.get("custodyId");

			return {
				clientId: clientId ? Number.parseInt(clientId) : undefined,
				userId: userId ? Number.parseInt(userId) : undefined,
				custodyId: custodyId ? Number.parseInt(custodyId) : undefined,
				area: "custody",
			};
		},
	},
];
