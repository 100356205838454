import { LitElement, html, css } from "lit";
import { state, property, customElement } from "lit/decorators.js";
import type { EmptyObject } from "type-fest";

import { styles as sharedStyles } from "../../../styles/shared.js";
import { styles as tableStyles } from "../../../styles/tables.js";
import { styles as inputStyles } from "../../../styles/input.js";

import { date } from "../../../formatting/dateformats.js";
import { money } from "../../../formatting/numberformats.js";
import { toast } from "../../../utils.js";
import { get, post } from "../../../api/client.js";
import type {
	PeriodicReport,
	PeriodicReportCustody,
	PeriodicReportCustodyPosition,
} from "../../../types/types.js";

const PIE_COLORS = [
	"#a50026",
	"#d73027",
	"#f46d43",
	"#fdae61",
	"#fee08b",
	"#ffffbf",
	"#d9ef8b",
	"#a6d96a",
	"#66bd63",
	"#1a9850",
	"#006837",
];

declare global {
	interface HTMLElementTagNameMap {
		"customer-periodic-report-view": typeof CustomerPeriodicReportView;
	}
}

@customElement("customer-periodic-report-view")
export default class CustomerPeriodicReportView extends LitElement {
	@property({ type: Number })
	accessor clientId: number | undefined;

	@state()
	periodicReport?: PeriodicReport;

	@state()
	fromDate = "";

	@state()
	toDate = "";

	@state()
	loading = false;

	@state()
	downloading = false;

	@state()
	mailing = false;

	static styles = [
		sharedStyles,
		tableStyles,
		inputStyles,
		css`div.actions {
    display: flex;
    flex-grow: 0;
    flex-direction: row;
    margin-left: auto;
    align-items: flex-start;
  }

  div.card-header {
    align-items: flex-start;
  }

  div.card-header .info p {
    margin: 0px;
    color: #666;
  }

  fm-form {
    display: flex;
    align-items: flex-end;
    gap: 8px;
  }

  div.form-space {
    margin: 0px;
    min-width: initial;
  }

  div.form-space input[type="date"] {
    font-size: 12px;
    height: 28px;
  }
  div.form-space fm-button-v2.button-small {
    margin: 0px;
  }`,
	];

	render() {
		return html`
      <div class="card">
        <div class="card-header">
          <div class="info">
            <h1>Perioderapport</h1>
          </div>
          <div class="actions">${this.paramForm()}</div>
        </div>
        ${
					this.periodicReport
						? html`
        <div class="card-block">
          ${this.createOverview(this.periodicReport)}
          ${
						this.periodicReport.market_exposure
							? html`<div>${this.createExposureTable(
									this.periodicReport,
								)}</div>`
							: null
					}
        </div>
        ${this.createCustodies(this.periodicReport)}`
						: null
				}
      </div>
    `;
	}

	constructor() {
		super();

		const now = new Date();
		now.setDate(now.getDate() - 20);
		const quarter = Math.floor(now.getMonth() / 3);
		const fromdate = new Date(now.getFullYear(), quarter * 3, 1);
		this.fromDate = date(fromdate);
		this.toDate = date(
			new Date(fromdate.getFullYear(), fromdate.getMonth() + 3, 0),
		);
	}

	paramForm() {
		return html` <fm-form
      method="get"
      id="parms"
      class="reportform no-print"
    >
      <div class="form-space">
        <label>Fra</label>
        <input
          type="date"
          name="from"
          value="${this.fromDate || ""}"
          @input="${(e: Event) => {
						this.fromDate = (e.target as HTMLInputElement).value;
					}}"
          @change="${this.resetResultObject}"
        />
      </div>
      <div class="form-space">
        <label>Til</label>
        <input
          type="date"
          name="to"
          value="${this.toDate || ""}"
          @input="${(e: InputEvent) => {
						this.toDate = (e.target as HTMLInputElement).value;
					}}"
          @change="${this.resetResultObject}"
        />
      </div>
      <div class="form-space">
        <label><div id="spinner"></div></label>
        <fm-button-v2
          id="submit_button"
          type="submit"
          class="button submit"
          ?disabled="${!this.formValid()}"
          .loading="${this.loading}"
          @click="${this.generate}"
          >Vis</fm-button-v2
        >
  </div>
        <div class="form-space">
                  <fm-button-v2
                    id="download_button"
                    class="button"
                    ?disabled="${!this.periodicReport?.custodies}"
                    .loading="${this.downloading}"
                    @click="${this.download}"
                    >Download</fm-button-v2
                  >
                </div>
                <div class="form-space">
                  <fm-button-v2 class="button" ?disabled="${!this.periodicReport
										?.custodies}" .loading="${this.mailing}" @click="${
										this.mail
									}"
                    >Send til kunde</fm-button-v2
                  >
                </div>
      </div>
    </fm-form>`;
	}

	colorStyle(i: number) {
		return `background:${PIE_COLORS[i % PIE_COLORS.length]}`;
	}

	createOverview(periodicReport: PeriodicReport) {
		if (periodicReport?.overview) {
			return html`
        <div
          style="display:block;width:40%;border-right:1px solid black;padding:4px;"
        >
          <table>
            <caption>
              Nøgletal
            </caption>
            <tr>
              <td>Værdi Primo</td>
              <td style="text-align:right">
                ${money(periodicReport.overview.primovalue)}
              </td>
            </tr>
            <tr>
              <td>Indbetalinger</td>
              <td style="text-align:right">
                ${money(periodicReport.overview.inflow)}
              </td>
            </tr>
            <tr>
              <td>Udbetalinger</td>
              <td style="text-align:right">
                ${money(periodicReport.overview.outflow)}
              </td>
            </tr>
            <tr>
              <td>Udbytte</td>
              <td style="text-align:right">
                ${money(periodicReport.overview.intdiv)}
              </td>
            </tr>
            <tr>
              <td>Udbytteskat</td>
              <td style="text-align:right">
                ${money(periodicReport.overview.tax)}
              </td>
            </tr>
            <tr>
              <td>Afkast</td>
              <td style="text-align:right">
                ${money(periodicReport.overview.result)}
              </td>
            </tr>
            <tr>
              <td>Værdi Ultimo</td>
              <td style="text-align:right">
                ${money(periodicReport.overview.ultimovalue)}
              </td>
            </tr>
            <tr>
              <td>Rådgivningshonorar</td>
              <td style="text-align:right">
                ${money(periodicReport.overview.advisorfee)}
              </td>
            </tr>
            <tr>
              <td>Indirekte omkostninger, køb</td>
              <td style="text-align:right">
                ${money(periodicReport.overview.indirect_in)}
              </td>
            </tr>
            <tr>
              <td>Indirekte omkostninger, salg</td>
              <td style="text-align:right">
                ${money(periodicReport.overview.indirect_out)}
              </td>
            </tr>
          </table>
        </div>
      `;
		}

		return null;
	}

	createExposureTable(resultObject: PeriodicReport) {
		if (resultObject.market_exposure) {
			return html`
        <table>
          <caption>
            Eksponering
          </caption>
          <thead>
            <tr>
              <th></th>
              <th>Marked</th>
              <th style="text-align:right">Pct.</th>
            </tr>
          </thead>
          <tbody>
            ${resultObject.market_exposure.map(
							(rec, i) =>
								html`<tr>
                  <td style="${this.colorStyle(i)}"></td>
                  <td>${rec.name}</td>
                  <td style="text-align:right">${money(rec.share)}</td>
                </tr>`,
						)}
          </tbody>
        </table>
      `;
		}

		return html``;
	}

	createPosition(s: PeriodicReportCustodyPosition) {
		return html`
      <tr>
        <td>${s.name}</td>
        <td style="text-align:right">${money(s.quantity)}</td>
        <td style="text-align:right">${money(s.marketvalue_pc)}</td>
      </tr>
    `;
	}

	createCustody(p: PeriodicReportCustody) {
		return html`
      <div class="card-block" style="justify-content: space-between;">
        <div
          style="display:block;width:40%;padding:4px;border-right:1px solid black"
        >
          <table>
            <caption>
              Investeringskonto ${p.custody_code}
            </caption>
            <tr>
              <td>Type</td>
              <td>${p.custodytype}</td>
            </tr>
            <tr>
              <td>Værdi, primo</td>
              <td style="text-align:right">${money(p.o_primovalue)}</td>
            </tr>
            <tr>
              <td>Indbetalinger</td>
              <td style="text-align:right">${money(p.o_inflow)}</td>
            </tr>
            <tr>
              <td>Udbetalinger</td>
              <td style="text-align:right">${money(p.o_outflow)}</td>
            </tr>
            <tr>
              <td>Udbytte</td>
              <td style="text-align:right">${money(p.o_intdiv)}</td>
            </tr>
            <tr>
              <td>Udbytteskat</td>
              <td style="text-align:right">${money(p.o_tax)}</td>
            </tr>
            <tr>
              <td>Afkast</td>
              <td style="text-align:right">${money(p.o_result)}</td>
            </tr>
            <tr>
              <td>Værdi, ultimo</td>
              <td style="text-align:right">${money(p.o_ultimovalue)}</td>
            </tr>
            <tr>
              <td>Rådgivningshonorar</td>
              <td style="text-align:right">${money(p.o_advisorfee)}</td>
            </tr>
            <tr>
              <td>Indirekte omkostninger, køb</td>
              <td style="text-align:right">${money(p.o_indirect_in)}</td>
            </tr>
            <tr>
              <td>Indirekte omkostninger, salg</td>
              <td style="text-align:right">${money(p.o_indirect_out)}</td>
            </tr>
          </table>
        </div>
        <div style="display:block;width:60%;padding:4px;">
          <table>
            <thead>
              <tr>
                <th>Inv.bevis</th>
                <th style="text-align:right">Antal</th>
                <th style="text-align:right">Værdi ultimo</th>
              </tr>
            </thead>
            <tbody>
              ${(p.positions || []).map((sec) => this.createPosition(sec))}
            </tbody>
          </table>
        </div>
      </div>
    `;
	}

	createCustodies(resultObject: PeriodicReport) {
		if (resultObject.custodies) {
			return html`
        ${(resultObject.custodies || []).map((c) => this.createCustody(c))}
      `;
		}

		return null;
	}

	resetResultObject() {
		this.periodicReport = undefined;
	}

	async generate(event: Event) {
		event.preventDefault();

		if (!this.clientId) {
			return;
		}

		this.loading = true;
		const periodicReportResponse = await get<{ data: PeriodicReport }>(
			`/clients/${this.clientId}/periodic-report?from=${this.fromDate}&to=${this.toDate}`,
		);
		this.loading = false;

		if (!periodicReportResponse.ok) {
			const error = await periodicReportResponse.error.json();

			if ("message" in error) {
				toast(error.message);
			} else {
				toast("Der er sket en fejl.");
			}

			return;
		}

		this.periodicReport = periodicReportResponse.value.data;
	}

	async download() {
		this.downloading = true;
		const periodicReportResponse = await post<EmptyObject, { data: string }>(
			`/clients/${this.clientId}/periodic-report?from=${this.fromDate}&to=${this.toDate}`,
			{},
		);
		this.downloading = false;

		if (!periodicReportResponse.ok) {
			const error = await periodicReportResponse.error.json();

			if ("message" in error) {
				toast(error.message);
			} else {
				toast("Der er sket en fejl.");
			}

			return;
		}

		const periodicReportPdf = periodicReportResponse.value.data;

		const a = document.createElement("a");
		a.href = `data:application/octet-stream;base64,${periodicReportPdf}`;
		a.download = "UserReport.pdf";
		a.click();
	}

	async mail(_event: Event) {
		this.mailing = true;
		const periodicReportResponse = await post<EmptyObject, { status: string }>(
			`/clients/${this.clientId}/periodic-mail?from=${this.fromDate}&to=${this.toDate}`,
			{},
		);
		this.mailing = false;

		if (!periodicReportResponse.ok) {
			const error = await periodicReportResponse.error.json();

			if ("message" in error) {
				toast(`FEJL: ${error.message}`);
			} else {
				toast("Der er sket en fejl.");
			}

			return;
		}

		this.mailing = false;
		toast("Intern mail sendt til kunden");
	}

	formValid() {
		return this.fromDate && this.toDate;
	}
}
