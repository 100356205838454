import { LitElement, html } from "lit";
import { customElement, property, state } from "lit/decorators.js";

import { styles as sharedStyles } from "../../styles/shared.js";
import { styles as tableStyles } from "../../styles/tables.js";
import { styles as inputStyles } from "../../styles/input.js";

import store from "../../store/index.js";
import { setTitle } from "../../store/actions.js";
import { toast } from "../../utils.js";
import type { AMUser } from "../../types/types.js";
import { get, put } from "../../api/client.js";

declare global {
	interface HTMLElementTagNameMap {
		"user-view": typeof UserView;
	}
}

@customElement("user-view")
export default class UserView extends LitElement {
	@property({ type: Number })
	accessor userId: number | undefined;

	@state()
	user?: AMUser;

	static styles = [sharedStyles, tableStyles, inputStyles];

	render() {
		if (!this.user) {
			return;
		}
		const _user = this.user;

		return html`
      <div class="card">
        <div class="card-header">
          <h1>Brugerdetaljer</h1>
        </div>
        ${
					this.user.id
						? html` <fm-form id="info" class="form-grid" @submit="${this.save}">
                <div class="form-field">
                  <label>
                    Navn
                    <input
                      type="text"
                      name="name"
                      value="${this.user.name}"
                      required
					  @input="${(e: InputEvent) => {
							_user.name = (e.target as HTMLInputElement).value;
						}}"
                    />
                  </label>
                </div>
                <div class="form-field">
                  <label>
                    E-mail
                    <input
                      type="text"
                      name="email"
                      value="${this.user.email || ""}"
                      required
					  @input="${(e: InputEvent) => {
							_user.email = (e.target as HTMLInputElement).value;
						}}"
                    />
                  </label>
                </div>
                <div class="form-field">
                  <label>
                    Telefonnr. (til SMS kode)
                    <input
                      type="text"
                      name="phone"
                      value="${this.user.phone || ""}"
                      required
					  @input="${(e: InputEvent) => {
							_user.phone = (e.target as HTMLInputElement).value;
						}}"
                    />
                  </label>
                </div>
                <div class="form-field">
                  <fm-button-v2 type="submit" class="btn btn--light"
                    >Gem</fm-button-v2
                  >
                </div>
              </fm-form>
              <hr />
              <fm-form
                id="credentials"
                class="form-grid"
                @submit="${this.saveCredentials}"
              >
                <div class="form-field">
                  <label>
                    Brugernavn
                    <input
                      type="text"
                      name="username"
                      value="${this.user.username || ""}"
					  @input="${(e: InputEvent) => {
							_user.username = (e.target as HTMLInputElement).value;
						}}"
                    />
                  </label>
                </div>
                <div class="form-field">
                  <label>
                    Password
                    <input type="password" name="password" @input="${(
											e: InputEvent,
										) => {
											_user.password = (e.target as HTMLInputElement).value;
										}}" />
                  </label>
                </div>
                <div class="form-field">
                  <label> Brug SMS kode <input type="checkbox" name="twophase" value="S" ?checked="${
										this.user.twophase === "S"
									}" @input="${(e: Event) => {
										_user.twophase = (e.target as HTMLInputElement).checked
											? "S"
											: null;
									}}" /></label>
                </div>
                <div class="form-field">
                  <fm-button-v2 type="submit" class="btn btn--light"
                    >Gem</fm-button-v2
                  >
                </div>
              </fm-form>`
						: null
				}
      </div>`;
	}

	async connectedCallback() {
		super.connectedCallback();
		store.dispatch(setTitle("Brugere"));

		if (this.userId) {
			this.user = await this.getUser(this.userId);
		}
	}

	async getUser(userId: number) {
		const userResponse = await get<{ data: AMUser }>(`/amusers/${userId}`);

		if (!userResponse.ok) {
			const error = await userResponse.error.json();

			if ("message" in error) {
				toast(error.message);
			} else {
				toast("Der er sket en fejl.");
			}

			return;
		}

		return userResponse.value.data;
	}

	async save(event: Event) {
		event.preventDefault();

		if (!this.user || !this.userId) {
			return;
		}

		const userData = {
			name: this.user.name,
			email: this.user.email,
			phone: this.user.phone,
		};

		const updateUserResponse = await put<typeof userData, { data: AMUser }>(
			`/amusers/${this.userId}`,
			userData,
		);

		if (!updateUserResponse.ok) {
			const error = await updateUserResponse.error.json();

			if ("message" in error) {
				toast(error.message);
			} else {
				toast("Der er sket en fejl.");
			}

			return;
		}

		toast("Gemt");

		this.user = await this.getUser(this.userId);
	}
	async saveCredentials(event: Event) {
		event.preventDefault();

		if (!this.user || !this.userId) {
			return;
		}

		const userData = {
			username: this.user.username,
			password: this.user.password,
			twophase: this.user.twophase,
		};

		const updateUserResponse = await put<typeof userData, { data: AMUser }>(
			`/amusers/${this.userId}/credentials`,
			userData,
		);

		if (!updateUserResponse.ok) {
			const error = await updateUserResponse.error.json();

			if ("message" in error) {
				toast(error.message);
			} else {
				toast("Der er sket en fejl.");
			}

			return;
		}

		toast("Gemt");

		this.user = await this.getUser(this.userId);
	}
}
