import { combineReducers } from "redux";
import {
	SET_TITLE,
	RECEIVE_STATUS,
	INVALIDATE_STATUS,
	SET_SHOW_PII,
	SET_CLIENT_DATA,
	SET_USER_DATA,
	SET_CUSTODY_DATA,
} from "./actions.js";

const initial = {
	authenticated: false,
};

function status(state = initial, action) {
	switch (action.type) {
		case RECEIVE_STATUS:
			return action.status;
		case INVALIDATE_STATUS:
			return initial;
		default:
			return state;
	}
}

function title(state = "", action) {
	switch (action.type) {
		case SET_TITLE:
			return action.title;
		default:
			return state;
	}
}

function showPII(state = false, action) {
	switch (action.type) {
		case SET_SHOW_PII:
			return action.showPII;
		default:
			return state;
	}
}

function clientData(state = null, action) {
	switch (action.type) {
		case SET_CLIENT_DATA:
			return action.clientData;
		default:
			return state;
	}
}

function userData(state = null, action) {
	switch (action.type) {
		case SET_USER_DATA:
			return action.userData;
		default:
			return state;
	}
}

function custodyData(state = null, action) {
	switch (action.type) {
		case SET_CUSTODY_DATA:
			return action.custodyData;
		default:
			return state;
	}
}

export default combineReducers({
	status,
	title,
	showPII,
	clientData,
	userData,
	custodyData,
});
