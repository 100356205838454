import { LitElement, html, css } from "lit";
import { customElement, property, state } from "lit/decorators.js";
import { live } from "lit/directives/live.js";
import { styles as sharedStyles } from "../../styles/shared.js";
import { styles as tableStyles } from "../../styles/tables.js";
import { styles as inputStyles } from "../../styles/input.js";

import { get } from "../../api/client.js";

import { date } from "../../formatting/dateformats.js";
import { quantity, money } from "../../formatting/numberformats.js";
import type { ButtonElementV2 } from "../../components/fm-button-v2.js";
import type {
	ClientGroupTransactions,
	ClientGroupTransactionsCustody,
	ClientGroupTransactionsCustodyTransaction,
} from "../../types/types.js";
import { toast } from "../../utils.js";

declare global {
	interface HTMLElementTagNameMap {
		"group-transactions-report-view": GroupTransactionsReportView;
	}
}

@customElement("group-transactions-report-view")
export default class GroupTransactionsReportView extends LitElement {
	@property({ type: Number })
	accessor groupId: number | undefined;

	@state()
	groupTransactions?: ClientGroupTransactions[];

	@state()
	fromDate = "";

	@state()
	toDate = "";

	static styles = [
		sharedStyles,
		tableStyles,
		inputStyles,
		css` div.actions {
      display: flex;
      flex-grow: 0;
      flex-direction: row;
      margin-left: auto;
      align-items: flex-start;
    }

    div.card-header {
      align-items: flex-start;
    }

    div.card-header .info p {
      margin: 0px;
      color: #666;
    }

    fm-form {
      display: flex;
      align-items: flex-end;
      gap: 8px;
    }

    div.form-space {
      margin: 0px;
      min-width: initial;
    }

    div.form-space input[type="date"] {
      font-size: 12px;
      height: 28px;
    }
    div.form-space fm-button-v2.button-small {
      margin: 0px;
    }`,
	];

	render() {
		return html`
      <div class="card">
        <div class="card-header">
          <div class="info">
            <h1>Transaktioner</h1>
          </div>
          <div class="actions">
            <fm-form
              method="get"
              id="parms"
              class="reportform no-print"
              @submit="${this.generate}"
            >
              <div class="form-space">
                <label>Fra</label>
                <input type="date" id="fromdate" .value="${live(
									this.fromDate,
								)}" @input="${(e: InputEvent) => {
									this.fromDate = (e.target as HTMLInputElement).value;
								}}" />
              </div>
              <div class="form-space">
                <label>Til</label>
                <input type="date" id="todate" .value="${live(
									this.toDate,
								)}" @input="${(e: InputEvent) => {
									this.toDate = (e.target as HTMLInputElement).value;
								}}" />
              </div>
              <div class="form-space">
                <fm-button-v2
                  id="submit_button"
                  type="submit"
                  class="button submit"
                  ?disabled="${!this.formValid()}"
                  >Vis</fm-button-v2
                >
              </div>
            </fm-form>
          </div>
        </div>
        ${this.groupTransactions?.map((c) => this.createClient(c))}
      </div>
    `;
	}

	connectedCallback() {
		super.connectedCallback();

		const now = new Date();
		now.setDate(now.getDate() - 20);
		const quarter = Math.floor(now.getMonth() / 3);

		const fromdate = new Date(now.getFullYear(), quarter * 3, 1);
		this.fromDate = date(fromdate);
		this.toDate = date(
			new Date(fromdate.getFullYear(), fromdate.getMonth() + 3, 0),
		);
	}

	createCustodyRow(s: ClientGroupTransactionsCustodyTransaction) {
		return html`
      <tr>
        <td>${s.security_name}</td>
        <td>${s.trantype_code}</td>
        <td>${s.tradedate}</td>
        <td>${s.valuedate}</td>
        <td>${s.ext_tran_id}</td>
        <td class="numeric">${quantity(s.quantity)}</td>
        <td class="numeric">${quantity(s.price)}</td>
        <td class="numeric">${money(s.amountqc)}</td>
      </tr>
    `;
	}

	createCustody(c: ClientGroupTransactionsCustody) {
		return html`
      <div style="display:flex;padding:5px;">
        <div class="custody-type-code">${c.custodytype_code}</div>
        <div style="display:flex;align-items:center">
          ${c.custody_code} ${c.name}
        </div>
      </div>
      <table>
        <thead>
          <tr>
            <th>Inv.bevis</th>
            <th>Tran.type</th>
            <th>Handelsdato</th>
            <th>Valør</th>
            <th>Reference</th>
            <th class="numeric">Mængde</th>
            <th class="numeric">Kurs</th>
            <th class="numeric">Beløb</th>
          </tr>
        </thead>
        <tbody>
          ${c.transactions.map((s) => this.createCustodyRow(s))}
        </tbody>
      </table>
    `;
	}

	createClient(client: ClientGroupTransactions) {
		return html`
      <div style="flex-direction: column; padding: 24px;">
        <h2>${client.name}</h2>
        <p><strong>ID-kode:</strong> ${client.code}</p>
        ${client.custodies.map((c) => this.createCustody(c))}
      </div>
    `;
	}

	async generate() {
		const button = this.shadowRoot?.getElementById(
			"submit_button",
		) as ButtonElementV2;

		button.loading = true;

		this.groupTransactions = undefined;

		if (this.groupId) {
			const getGroupTransactionsResponse = await get<{
				data: ClientGroupTransactions[];
			}>(
				`/client-groups/${this.groupId}/transactions?fromdate=${this.fromDate}&todate=${this.toDate}`,
			);

			if (!getGroupTransactionsResponse.ok) {
				const error = await getGroupTransactionsResponse.error.json();

				if ("message" in error) {
					toast(error.message);
				} else {
					toast("Der er sket en fejl.");
				}
				return;
			}

			this.groupTransactions = getGroupTransactionsResponse.value.data;
		}

		button.loading = false;
	}

	formValid() {
		return this.fromDate && this.toDate;
	}
}
