import store from "../store/index.js";

export const cprNumber = (v: string) => {
	const regExp = /^([0-9]{6})-?([0-9]{4}$)/;

	if (!v) {
		return v;
	}

	const cprNumberParts = v.match(regExp);

	if (cprNumberParts == null) {
		// throw new Error("Error parsing CPR number parts.");
		return v;
	}

	const dateOfBirth = cprNumberParts[1];
	const sequenceNumber = store.getState().showPII ? cprNumberParts[2] : "xxxx";

	return `${dateOfBirth}-${sequenceNumber}`;
};
